<template>
    <!-- Modal -->
    <div class="modal d-flex align-items-center justify-content-center" style="overflow: scroll; background: #fff; z-index: 1000; height: 100% !important">
        <div style="margin : 0 !important;width: 100%;height: 100%; !important">
            <div class="modal-content" style="margin: 0 !important; width: 100%; max-width: unset; max-height: unset">
                <div class="modal-header">
                    <h5 class="modal-title">{{ L("Clarification.Text.Head") }}</h5>
                    <button type="button" class="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{ L("Clarification.Text.Main") }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal">
                        {{ L("Clarification.Button.Close") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { localize } from "@/common/localize-service";

export default {
    methods: {
        closeModal: function () {
            this.$parent.closeModal();
        },
        L: localize,
    },
};
</script>
