export function dateString(date) {
    var day = new Date(date).getDate().toString();
    day = parseInt(day) < 10 ? "0" + day : day;
    var month = (new Date(date).getMonth() + 1).toString();
    month = parseInt(month) < 10 ? "0" + month : month;
    var year = (new Date(date).getFullYear()).toString();
    year = parseInt(year) < 10 ? "0" + year : year;
    return day + "." + month + "." + year;
}

export function hourString(date){
    var day = new Date(date).getDate().toString();
    day = parseInt(day) < 10? "0" + day : day;
    var month = (new Date(date).getMonth() + 1).toString();
    month = parseInt(month) < 10? "0" + month : month;
    var year = (new Date(date).getFullYear()).toString();
    year = parseInt(year) < 10? "0" + year : year;
    var hour = (new Date(date).getHours()).toString();
    hour = parseInt(hour) < 10? "0" + hour : hour;
    var minute = (new Date(date).getMinutes()).toString();
    minute = parseInt(minute) < 10? "0" + minute : minute;
    return day + "." + month +"."  + year + " " + hour + ":" + minute;
}

export function onlyHour(date){
    var hour = (new Date(date).getHours()).toString();
    hour = parseInt(hour) < 10? "0" + hour : hour;
    var minute = (new Date(date).getMinutes()).toString();
    minute = parseInt(minute) < 10? "0" + minute : minute;
    return hour + ":" + minute;
}

export function inputDate(date){
    var day = new Date(date).getDate().toString();
    day = parseInt(day) < 10 ? "0" + day : day;
    var month = (new Date(date).getMonth() + 1).toString();
    month = parseInt(month) < 10 ? "0" + month : month;
    var year = (new Date(date).getFullYear()).toString();
    year = parseInt(year) < 10 ? "0" + year : year;
    return year + "-" + month + "-" + day;
}