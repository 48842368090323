<template>
    <div class="content d-flex flex-column flex-column-fluid">
        <div class="d-flex flex-column-fluid">
            <div class="container">
                <div class="card card-custom gutter-b">
                    <div class="card-body" v-html="sss"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import service from "@/common/service";
export default {
    data() {
        return {
            sss: "",
        };
    },
    beforeCreate() {
        service
            .getInstance()
            .GetPage("sss")
            .then((response) => {
                this.sss = response.message;
            });
    },
};
</script>
