
import { localize } from "@/common/localize-service";
import { User } from "@/entities/User";
import { ContactModel } from "@/models/ContactModel";
import { isNullOrEmpty } from "@/common/validations";
import service from "@/common/service";
import { ApiResponse } from "@/models/ApiResponse";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";
import { MembershipType } from "@/common/enums";

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            L: localize,
            model: new ContactModel(),
            busy: false,
            isSubjectValid: true,
            isMessageValid: true,
        };
    },
    mounted() {
        
        this.model.FirstName = this.user.firstName;
        this.model.LastName = this.user.lastName;
        this.model.Phone = this.user.phoneNumber;
        this.model.Email = this.user.email;
    },
    methods: {
        sendForm() {
            if (this.busy) return;
            this.busy = true;
            var isValid = true;

            if (isNullOrEmpty(this.model.Subject)) {
                this.isSubjectValid = false;
                isValid = false;
            }
            if (isNullOrEmpty(this.model.Message)) {
                this.isMessageValid = false;
                isValid = false;
            }
            if (!isValid) return;

            service
                .getInstance()
                .Contact(this.model)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.model = new ContactModel();
                        CfAlertSuccess(localize("Contact.Success.Message"));
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
    },
};
