<template>
    <!--begin::Forgot-->
    <transition name="fade-in-up">
        <div v-if="show" class="login-form pt-11">
            <!--begin::Title-->
            <div class="text-center pb-8">
                <h2 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                    {{ L("ForgotPassword.Text.Head") }}
                </h2>
                <p class="text-muted font-weight-bold font-size-h4">
                    {{ L("ForgotPassword.Text.Sub") }}
                </p>
            </div>
            <!--end::Title-->
            <!--begin::Form group-->
            <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark text-left">{{ L("ForgotPassword.Label.Email") }}</label>
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6" v-model="model.Email" type="email" @keypress="isEmailValid = true" />
                <div v-if="!isEmailValid" class="fv-plugins-message-container">
                    <div class="fv-help-block text-danger">
                        {{ L("ForgotPassword.Error.Email") }}
                    </div>
                </div>
            </div>
            <!--end::Form group-->
            <!--begin::Form group-->
            <div class="d-flex flex-wrap flex-center">
                <button @click="goLogin" type="button" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-3">
                    {{ L("ForgotPassword.Button.Back") }}
                </button>
                <button @click="formSubmit" type="button" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-3">
                    {{ L("ForgotPassword.Button.Send") }}
                </button>
            </div>
            <!--end::Form group-->
        </div>
    </transition>
    <!--end::Forgot-->
    <div class="loading" v-if="busy"></div>
</template>

<script>
import service from "../common/service";
import { isEmailValid } from "../common/validations";
import { CfAlertError } from "../common/alerts";
import { localize } from "../common/localize-service";
import { ForgotPasswordModel } from "../models/ForgotPasswordModel";

export default {
    data() {
        return {
            isEmailValid: true,
            busy: false,
            show : false,
        };
    },
    props: {
        model: ForgotPasswordModel,
    },
    mounted() {
        this.show = true;
    },
    methods: {
        formSubmit() {
            if (this.busy) return;
            this.busy = true;

            if (!isEmailValid(this.model.Email)) {
                this.isEmailValid = false;
                this.busy = false;
                return;
            }

            service
                .getInstance()
                .ForgotPassword(this.model)
                .then((response) => {
                    if (response.isSucceed) {
                        this.$parent.page = "resetPassword";
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        L: localize,
        goLogin() {
            this.$parent.page = "login";
        },
    },
};
</script>
