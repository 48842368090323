
import service from "../common/service";
import { HelpSubject, MembershipType } from "../common/enums";
import { dateString } from "../common/date-format";
import { isNullOrEmpty } from "../common/validations";
import { User } from "../entities/User";
import { localize } from "../common/localize-service";
import { Variables } from "@/common/variables";

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            adList: [],
            helpSubject: HelpSubject,
            dateString: dateString,
            isNullOrEmpty: isNullOrEmpty,
            L: localize,
            busy: true,
            imagePath: Variables.ImagePath,
            night : Date
        };
    },
    mounted() {
        this.night = new Date(Date.now() - 1000 * 60 * 60 * 24);
        this.night.setHours(0);
        this.night.setMinutes(0);
        this.night.setSeconds(0);
        this.night.setMilliseconds(0);
        this.GetList();
    },
    methods: {
        GetList() {
            var serviceUrl = "Tevkil/MyTevkils";
            service
                .getInstance()
                .GetAdvertList(serviceUrl)
                .then((response) => {
                    this.adList = response.result;
                })
                .catch((error) => {})
                .finally(() => (this.busy = false));
        },
        advertDetail(item) {
            if (this.busy) return;
            this.busy = true;
            this.$router.push({ path: `/ilan-detay/${item.id}` });
        },
    },
};
