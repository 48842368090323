
import { User } from '@/entities/User';
import { localize } from '@/common/localize-service';
import { ChangePhoneModel } from "@/models/ChangePhoneModel";
import { isEmailValid, isNullOrEmpty } from '@/common/validations';
import { CfAlertError, CfAlertSuccess } from '@/common/alerts';
import service from '@/common/service';
import { ApiResponse } from '@/models/ApiResponse';
import { MembershipType } from '@/common/enums';

export default{
    props:{
        user : Object,
    },
    emits : ["updateUser"],
    data(){
        return{
            L : localize,
            model : new ChangePhoneModel(),
            isEmailValid : true,
            isCodeValid : true,
            busy : false,
            state : 1
        }
    },
    mounted(){
        
    },
    methods: {
        sendCode(){
            if(this.busy) return;
            this.busy = true;

            if(!isEmailValid(this.model.Email)){
                this.isEmailValid = false;
                this.busy = false;
                return;
            }
            
            if(this.model.Email == this.user.email){
                CfAlertError(localize("EmailChange.Error.NoChange"));
                this.busy = false;
                return;
            }
            service.getInstance().EditEmail(this.model)
            .then((response : ApiResponse) => {
                if(response.isSucceed){
                    CfAlertSuccess(response.message);
                    this.state = 2;
                }else{
                    CfAlertError(response.message);
                }
            })
            .catch(error => {
                service.getInstance().ErrorMessage(error)
            })
            .finally(() => this.busy = false);
        },
        changeEmail(){
            if(this.busy) return;
            this.busy = true;

            if(isNullOrEmpty(this.model.Code) || this.model.Code.length != 5){
                this.isCodeValid = false;
                this.busy = false;
                return;
            }

            this.model.Code = this.model.Code.toLowerCase();

            service.getInstance().ChangeEmail(this.model)
            .then((response : ApiResponse) => {
                if(response.isSucceed){
                    CfAlertSuccess(localize("ChangeEmail.Success.Message"));
                    this.$emit("updateUser",response.result);
                    this.model = new ChangePhoneModel();
                    this.state = 1;
                     if(navigator.cookieEnabled){
                            localStorage.setItem("user",JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                }else{
                    CfAlertError(response.message);
                }
            })
            .catch(error => {
                service.getInstance().ErrorMessage(error)
            })
            .finally(() => this.busy = false);
        }
    }
}
