export function localeSort(a, b){
    var atitle = a.name;
    var btitle = b.name;
    var alfabe = "0123456789AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz";
    if(atitle == "Merkez"){
        return atitle > btitle;
    }
    if(btitle == "Merkez"){
        return btitle > atitle;
    }
    if (atitle.length === 0 || btitle.length === 0) {
        return atitle.length - btitle.length;
    }
    for(var i=0;i<atitle.length && i<btitle.length;i++){
        var ai = alfabe.indexOf(atitle[i]);
        var bi = alfabe.indexOf(btitle[i]);
        if (ai !== bi) {
            return ai - bi;
        }
    }
} 