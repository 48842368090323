
import { User } from '@/entities/User';
import { localize } from '@/common/localize-service';
import { isNullOrEmpty } from '@/common/validations';
import service from '@/common/service';
import { CfAlertError, CfAlertSuccess } from '@/common/alerts';
import { ApiResponse } from '@/models/ApiResponse';
import { MembershipType } from '@/common/enums';
export default{
    props:{
        user : Object,
    },
    emits : ["updateUser"],
    data(){
        return{
            L : localize,
            busy : false,
            isPasswordValid : true,
            newPassword : "",
            newPassword2 : ""
        }
    },
    mounted(){
        
    },
    methods:{
        changePassword(){
            if(this.busy) return;
            if(!isNullOrEmpty(this.newPassword) || !isNullOrEmpty(this.newPassword2)){
                this.busy = true;
                if(this.newPassword != this.newPassword2){
                    this.isPasswordValid = false;
                    this.busy = false;
                    return;
                }

                service.getInstance().ChangePassword(this.newPassword)
                .then((response : ApiResponse) =>{
                    if(response.isSucceed){
                    this.$emit("updateUser",response.result);
                    this.newPassword = "";
                    this.newPassword2 = "";
                     if(navigator.cookieEnabled){
                            localStorage.setItem("user",JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                    CfAlertSuccess(response.message)
                }else{
                    CfAlertError(response.message);
                }
                })
                .catch(error =>{
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => this.busy = false);
            }
        }
    }
}
