<template>
    <!--begin: Wizard Step 1-->
    <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
        <!--begin::İlçe-->
        <div class="form-group">
            <label>{{ L("Register3.Label.Area") }}</label>
            <select v-model="model.District" v-bind:class="isDistrictValid ? 'form-select form-control form-control-solid form-control-lg' : 'form-select form-control form-control-solid form-control-lg is-invalid'" v-on:change="isDistrictValid = true">
                <option v-for="area in areas" :key="area.name">{{ area.name }}</option>
            </select>
            <span v-bind:class="isDistrictValid ? 'form-text text-danger d-none' : 'form-text text-danger'">{{ L("Register3.Error.Area") }}</span>
        </div>
        <!--end::İlçe-->
        <!--begin::Phone-->
        <div class="form-group">
            <label>{{ L("Register3.Label.Phone") }}</label>
            <input type="text" v-bind:class="isPhoneValid ? 'form-control form-control-solid form-control-lg text-center' : 'form-control form-control-solid form-control-lg is-invalid text-center'" v-model="model.Phone" v-mask="'(5##) ### ####'" v-on:keypress="isPhoneValid = true" />
            <span v-bind:class="isPhoneValid ? 'form-text text-danger d-none' : 'form-text text-danger'">{{ isRegisteredPhone ? L("Register3.Error.RegisteredPhone") : L("Register3.Error.Phone") }}</span>
        </div>
        <!--end::Phone-->
        <!--begin::Email-->
        <div class="form-group">
            <label>{{ L("Register3.Label.Email") }}</label>
            <input type="text" v-bind:class="isEmailValid ? 'form-control form-control-solid form-control-lg text-center' : 'form-control form-control-solid form-control-lg is-invalid text-center'" v-model="model.Email" v-on:keypress="isEmailValid = true" />
            <span v-bind:class="isEmailValid ? 'form-text text-danger d-none' : 'form-text text-danger'">{{ isRegisteredEmail ? L("Register3.Error.RegisteredEmail") : L("Register3.Error.Email") }}</span>
        </div>
        <!--end::Email-->
        <!--begin::Website-->
        <div class="form-group">
            <label>{{ L("Register3.Label.WebAddress") }}</label>
            <input type="text" class="form-control form-control-solid form-control-lg text-center" v-model="model.Website" />
        </div>
        <!--end::Website-->
    </div>
    <!--end: Wizard Step 1-->
    <!--begin: Wizard Actions-->
    <div class="d-flex justify-content-between border-top mt-5 pt-10">
        <div class="mr-2">
            <button type="button" class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4" v-on:click="previousPage">
                {{ L("Register3.Button.Back") }}
            </button>
        </div>
        <div>
            <button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" v-on:click="nextPage">
                {{ L("Register3.Button.Continue") }}
            </button>
        </div>
    </div>
    <!--end: Wizard Actions-->
</template>

<script>
import { localize } from "@/common/localize-service";
import { RegisterModel } from "@/models/RegisterModel";
import service from "@/common/service";
import { isEmailValid, isNullOrEmpty, isPhoneValid } from "@/common/validations";

export default {
    props: {
        model: RegisterModel,
        areas: Array,
    },
    data() {
        return {
            isDistrictValid: Boolean,
            isPhoneValid: Boolean,
            isEmailValid: Boolean,
            isRegisteredPhone: Boolean,
            isRegisteredEmail: Boolean,
        };
    },
    mounted() {
        this.isRegisteredEmail = false;
        this.isRegisteredPhone = false;
    },
    methods: {
        nextPage: function () {
            let isValid = true;
            if (isNullOrEmpty(this.model.District)) {
                this.isDistrictValid = false;
                isValid = false;
            }
            let phoneCheck = this.model.Phone.replaceAll("(", "");
            phoneCheck = phoneCheck.replaceAll(")", "");
            phoneCheck = phoneCheck.replaceAll(" ", "");
            if (!isPhoneValid(phoneCheck) || isNullOrEmpty(this.model.Phone)) {
                this.isPhoneValid = false;
                this.isRegisteredPhone = false;
                isValid = false;
            }
            if (!isEmailValid(this.model.Email)) {
                this.isEmailValid = false;
                this.isRegisteredEmail = false;
                isValid = false;
            }
            service
                .getInstance()
                .CheckPhone("?phone=" + phoneCheck)
                .then((response) => {
                    if (response) {
                        this.isRegisteredPhone = true;
                        this.isPhoneValid = false;
                        isValid = false;
                        return;
                    } else {
                        service
                            .getInstance()
                            .CheckEmail("?email=" + this.model.Email)
                            .then((response) => {
                                if (response) {
                                    this.isRegisteredEmail = true;
                                    this.isEmailValid = false;
                                    isValid = false;
                                } else {
                                    if (isValid) {
                                        this.model.Phone = phoneCheck;
                                        this.$parent.page++;
                                    }
                                }
                            });
                    }
                });
        },
        previousPage: function () {
            this.$parent.page--;
        },
        L: localize,
    },
};
</script>
