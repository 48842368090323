<template>
    <!--begin: Wizard Step 1-->
    <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
        <!--begin::Tecrübe-->
        <div class="form-group">
            <label>{{ L("Register4.Label.Experience") }}</label>
            <select v-model="model.JobStartedYear" v-on:change="isExperienceValid = true" v-bind:class="isExperienceValid ? 'form-select form-control form-control-solid form-control-lg' : 'form-select form-control form-control-solid form-control-lg is-invalid'">
                <option v-for="year in years" :key="year.value">{{ year.value }}</option>
            </select>
            <span v-bind:class="isExperienceValid ? 'form-text text-danger d-none' : 'form-text text-danger'">{{ L("Register4.Error.Experience") }}</span>
        </div>
        <!--end::Tecrübe-->

        <!--begin::Çalışma Şekli-->
        <div class="row py-2">
            <label>{{ L("Register4.Label.WorkingType") }}</label>
            <div class="col-xl-6 py-2" v-for="workingType in workingTypes" :key="workingType.value">
                <div class="form-check text-left">
                    <input class="form-check-input" type="radio" name="workingType" v-model="model.WorkingType" v-bind:value="workingType.value" />
                    <label class="form-check-label">{{ workingType.display }}</label>
                </div>
            </div>
            <span v-bind:class="isWorkingTypeValid ? 'form-text text-danger d-none' : 'form-text text-danger'">{{ L("Register4.Error.WorkingType") }}</span>
        </div>
        <!--end::Çalışma Şekli-->

        <!--begin::Üyelik Amaç-->
        <div class="row py-2">
            <label>{{ L("Register4.Label.Purpose") }}</label>
            <div class="col-xl-6 py-2" v-for="purpose in purposes" :key="purpose.value">
                <div class="form-check text-left">
                    <input class="form-check-input" type="radio" name="purpose" v-model="model.Purpose" v-bind:value="purpose.value" />
                    <label class="form-check-label">{{ purpose.display }}</label>
                </div>
            </div>
            <span v-bind:class="isPurposeValid ? 'form-text text-danger d-none' : 'form-text text-danger'">{{ L("Register4.Error.Purpose") }}</span>
        </div>
        <!--end::Üyelik Amaç-->

        <!--begin::Adliyeler-->
        <div class="form-group py-2">
            <label>{{ L("Register4.Label.Adliyeler") }}</label>
            <input type="text" class="form-control form-control-solid form-control-lg" v-model="model.Adliyeler" />
        </div>
        <!--end::Adliyeler-->
    </div>
    <!--end: Wizard Step 1-->
    <!--begin: Wizard Actions-->
    <div class="d-flex justify-content-between border-top mt-5 pt-10">
        <div class="mr-2">
            <button type="button" class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4" v-on:click="previousPage">
                {{ L("Register4.Button.Back") }}
            </button>
        </div>
        <div>
            <button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" v-on:click="nextPage">
                {{ L("Register4.Button.Continue") }}
            </button>
        </div>
    </div>
    <!--end: Wizard Actions-->
</template>

<script>
import service from "@/common/service";
import { localize } from "@/common/localize-service";
import { CfAlertError } from "@/common/alerts";
import { isNullOrEmpty } from "@/common/validations";
import { Purpose, WorkingType } from "@/common/enums";
import { RegisterModel } from "@/models/RegisterModel";

export default {
    props: {
        model: RegisterModel,
    },
    data() {
        return {
            workingTypes: [],
            purposes: [],
            years: [],
            isExperienceValid: Boolean,
            isPurposeValid: Boolean,
            isWorkingTypeValid: Boolean,
            busy: Boolean,
        };
    },
    mounted() {
        for (var i in Purpose) {
            if (typeof Purpose[i] === "number") {
                this.purposes.push({ value: Purpose[i], display: i });
            }
        }
        for (var i in WorkingType) {
            if (typeof WorkingType[i] === "number") {
                this.workingTypes.push({ value: WorkingType[i], display: i });
            }
        }
        for (let i = new Date(Date.now()).getFullYear(); i >= 1950; i--) {
            this.years.push({ value: i, display: i });
        }
    },
    methods: {
        nextPage: function () {
            let isValid = true;
            if (this.model.JobStartedYear == null || this.model.JobStartedYear == 0) {
                isValid = false;
                this.isExperienceValid = false;
            }
            if (this.model.WorkingType == null) {
                isValid = false;
                this.isWorkingTypeValid = false;
            }
            if (this.model.Purpose == null) {
                isValid = false;
                this.isPurposeValid = false;
            }
            if (isValid) {
                service
                    .getInstance()
                    .Register(this.model)
                    .then((response) => {
                        if (response.isSucceed) {
                            this.$parent.page = 5;
                        } else {
                            CfAlertError(response.message);
                        }
                    })
                    .catch((error) => {
                        CfAlertError(error.message);
                    })
                    .finally(() => {
                        this.busy = false;
                    });
            } else {
                this.busy = false;
            }
        },
        previousPage: function () {
            this.$parent.page--;
        },
        L: localize,
    },
};
</script>
