import { language } from "./language";

var lang = new language();
var langList = {};

export function localize(key:string, text1?,text2?,text3?) {
    var langItem = lang.getItem(key,text1,text2,text3);
    
    if(langItem != undefined){
        return langItem;
    }

    return "";
};