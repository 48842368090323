<template>
    <div v-if="user" class="header-mobile-fixed aside-enabled aside-fixed page-loading" style="background: #f3f6f9; min-height: 100%">
        <!--begin::Main-->
        <!--begin::Header Mobile-->
        <div id="kt_header_mobile" class="header-mobile h-auto">
            <!--begin::Logo-->
            <router-link class="py-2" to="/">
                <img alt="Logo" src="../assets/images/login.png" class="logo-default max-h-50px" />
            </router-link>
            <!--end::Logo-->
            <!--begin::Toolbar-->
            <div class="d-flex align-items-center">
                <button class="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle" @click="asideOn = !asideOn">
                    <span></span>
                </button>
            </div>
            <!--end::Toolbar-->
        </div>
        <!--end::Header Mobile-->
        <div class="d-flex flex-column flex-root">
            <!--begin::Page-->
            <div class="d-flex flex-row flex-column-fluid page">
                <!--begin::Aside-->
                <div :class="asideOn ? 'aside-on' : ''" class="aside aside-left d-flex aside-fixed w-auto hidden-scrollbar" style="overflow: hidden scroll">
                    <!--begin::Primary-->
                    <div class="aside-primary d-flex flex-column align-items-center flex-row-auto w-auto">
                        <!--begin::Brand-->
                        <div class="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12">
                            <!--begin::Logo-->
                            <router-link to="/">
                                <img alt="Logo" src="../assets/images/login.png" class="logo-default max-h-50px" />
                            </router-link>
                            <!--end::Logo-->
                        </div>
                        <!--end::Brand-->
                        <!--begin::Nav Wrapper-->
                        <div class="aside-nav d-flex flex-column align-items-center flex-column-fluid scroll scroll-pull">
                            <!--begin::Nav-->
                            <ul class="nav flex-column" role="tablist">
                                <!--begin::Ilanlar-->
                                <li class="nav-item mb-3" title="İlanlar">
                                    <router-link to="/ilanlar" active-class="active" @click="asideOn = false" class="nav-link btn btn-icon btn-clean btn-lg w-100 px-5"> İlanlar </router-link>
                                </li>
                                <!--end::Ilanlar-->
                                <!--begin::Ilanlarim-->
                                <li class="nav-item mb-3" title="İlanlarım">
                                    <router-link to="/ilanlarim" active-class="active" @click="asideOn = false" class="nav-link btn btn-icon btn-clean btn-lg w-100 px-5"> İlanlarım </router-link>
                                </li>
                                <!--end::Ilanlarim-->
                                <!--begin::Ilan olustur-->
                                <li class="nav-item mb-3" title="Yeni İlan">
                                    <router-link to="/yeni-ilan" active-class="active" @click="asideOn = false" class="nav-link btn btn-icon btn-clean btn-lg w-100 px-5"> Yeni İlan </router-link>
                                </li>
                                <!--end::Ilan olustur-->
                                <!--begin::Ayarlar-->
                                <li class="nav-item mb-3" title="Avukatlar">
                                    <router-link to="/avukatlar" active-class="active" @click="asideOn = false" class="nav-link btn btn-icon btn-clean btn-lg w-100 px-5"> Avukat Grubu </router-link>
                                </li>
                                <!--end::Ayarlar-->
                                <!--begin::Mesajlar-->
                                <li class="nav-item mb-3 position-relative" title="mesajlar">
                                    <router-link to="/mesajlar" active-class="active" @click="asideOn = false" class="nav-link btn btn-icon btn-clean btn-lg w-100 px-5"> Mesajlar <i v-if="user.unreadMessage" class="fas fa-circle text-primary ml-3" style="font-size: 8px; position: absolute;top: 0px; right: 0px"></i></router-link>
                                </li>
                                <!--end::Mesajlar-->
                                <!--begin::Ayarlar-->
                                <li class="nav-item mb-3" title="Ayarlar">
                                    <router-link to="/ayarlar" active-class="active" @click="asideOn = false" class="nav-link btn btn-icon btn-clean btn-lg w-100 px-5"> Ayarlar </router-link>
                                </li>
                                <!--end::Ayarlar-->
                                <!--begin::Iletisim-->
                                <li class="nav-item mb-3" title="İletişim">
                                    <router-link to="/iletisim" active-class="active" @click="asideOn = false" class="nav-link btn btn-icon btn-clean btn-lg w-100 px-5"> İletişim </router-link>
                                </li>
                                <!--end::Iletisim-->
                                 <!--begin::SSS-->
                                <li class="nav-item mb-3" title="Nasıl Çalışır">
                                    <router-link to="/sss" active-class="active" @click="asideOn = false" class="nav-link btn btn-icon btn-clean btn-lg w-100 px-5"> Nasıl Çalışır </router-link>
                                </li>
                                <!--end::SSS-->
                                <!--begin::Logout-->
                                <li class="nav-item mb-3" title="Cıkış Yap">
                                    <a @click="logOut" class="nav-link btn btn-icon btn-clean btn-lg w-100 px-5"> Çıkış Yap </a>
                                </li>
                                <!--end::Logout-->
                            </ul>
                            <!--end::Nav-->
                        </div>
                        <!--end::Nav Wrapper-->
                    </div>
                    <!--end::Primary-->
                </div>
                <!--end::Aside-->
                <div v-if="asideOn" @click="asideOn = false" class="aside-overlay"></div>

                <!--begin::Wrapper-->
                <div class="d-flex flex-column flex-row-fluid wrapper content aside-wrapper-tevkil">
                    <router-view :user="user" @updateUser="updateUser"></router-view>
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Page-->
        </div>
        <!--end::Main-->
        <!--begin::Scrolltop-->
        <div id="kt_scrolltop" class="scrolltop">
            <span class="svg-icon">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Up-2.svg-->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect fill="#000000" opacity="0.3" x="11" y="10" width="2" height="10" rx="1" />
                        <path d="M6.70710678,12.7071068 C6.31658249,13.0976311 5.68341751,13.0976311 5.29289322,12.7071068 C4.90236893,12.3165825 4.90236893,11.6834175 5.29289322,11.2928932 L11.2928932,5.29289322 C11.6714722,4.91431428 12.2810586,4.90106866 12.6757246,5.26284586 L18.6757246,10.7628459 C19.0828436,11.1360383 19.1103465,11.7686056 18.7371541,12.1757246 C18.3639617,12.5828436 17.7313944,12.6103465 17.3242754,12.2371541 L12.0300757,7.38413782 L6.70710678,12.7071068 Z" fill="#000000" fill-rule="nonzero" />
                    </g>
                </svg>
                <!--end::Svg Icon-->
            </span>
        </div>
        <!--end::Scrolltop-->
    </div>
</template>

<script lang="ts">
import service from "@/common/service";
import { MembershipType } from "@/common/enums";
export default {
    data() {
        return {
            asideOn: false,
            user: navigator.cookieEnabled? JSON.parse(localStorage.getItem("user")) : globalThis.user,
        };
    },
    mounted() {
        if (this.user == null) {
            this.$router.push({ path: "/login" });
        } else {
            service.getInstance().UserCheck();
            setTimeout(() => {
                if(navigator.cookieEnabled){
                    this.user = JSON.parse(localStorage.getItem("user"));
                }else{
                    this.user = globalThis.user;
                }
            }, 500);
        }
    },
    methods: {
        updateUser(user) {
            this.user = user;
        },
        logOut() {
            service.getInstance().Logout();
        },
    },
};
</script>

<style>
router-link a {
    text-decoration: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
</style>
