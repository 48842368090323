export function isEmailValid(mail : string){
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
    {
        return true;
    }
    return false;;
}

export function isNameValid(text : string){
    if(text != null && text.trim() != "" && /^[a-zA-ZğüşöçıİĞÜŞÖÇ\s]*$/.test(text.trim())){
        return true;
    }
    return false;
}

export function isNullOrEmpty(text : string){
    if(text != null && text.trim() != ""){
        return false;
    }
    return true;
}

export function isPhoneValid(text : string){
    if(/(5)[0-9]{9}/g.test(text)){
        return true;
    }
    return false;
}

export function isNumber(text : string){
    if (/^[0-9]*$/.test(text)){
        return true;
    }
    return false;
}

export function isNullOrUndefined(text){
    if(typeof(text) == undefined || text == null){
        return true;
    }
    return false;
}