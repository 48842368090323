
import { localize } from "@/common/localize-service";
import service from "@/common/service";
import { ApiResponse } from "@/models/ApiResponse";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";
import Tagify from "@yaireo/tagify";
import { MembershipType } from "@/common/enums";
import { isNullOrEmpty } from "@/common/validations";

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            L: localize,
            busy: false,
            areaList: [],
            selectedArea: false,
            areas: [],
            arrayLength: 0,
            userCheck: Number,
            price: Number,
            cities: String,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);

        this.areaList = this.areaList.concat(this.user.areas);
        this.areaList = this.areaList.concat(this.user.extraAreas);
        if (this.areaList.find((c) => c.sms) != null) {
            this.selectedArea = this.areaList.find((c) => c.sms).areaId;
        }
        service
            .getInstance()
            .GetCities()
            .then((response) => {
                var input = document.getElementById("cities");
                var user1;
                 if(navigator.cookieEnabled){
                    user1 =  JSON.parse(localStorage.getItem("user"));
                }else{
                    user1 = globalThis.user;
                }
                var areas1 = response.result.filter((c) => user1.areas.find((d) => d.area == c.name) == null && user1.extraAreas.find((e) => e.area == c.name) == null);
                var cities = areas1.map((item) => {
                    return item.name;
                });

                var tagify = new Tagify(input, {
                    whitelist: cities,
                    enforceWhitelist: true,
                });
                tagify.on("change", (e) => {
                    this.areas = e.detail.value;
                    if (this.areas == "") {
                        this.arrayLength = 0;
                        this.price = 0;
                    } else {
                        this.cities = "";
                        this.arrayLength = JSON.parse(e.detail.value).length;
                        JSON.parse(this.areas).forEach((item) =>{
                            this.cities += item.value + ", "
                        });
                        this.price = 50 * this.arrayLength;
                    }
                });
            });
    },
    methods: {
        postForm() {
            if (this.busy) return;
            this.busy = true;

            this.areaList.forEach((item) => {
                item.sms = item.areaId == this.selectedArea ? true : false;
            });

            service
                .getInstance()
                .EditAreas(this.user)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.$emit("updateUser", response.result);
                        if(navigator.cookieEnabled){
                            localStorage.setItem("user", JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                        CfAlertSuccess(response.message);
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        addArea() {
            var selectedAreas = "";
            JSON.parse(this.areas).forEach((item) => {
                selectedAreas += item.value + ",";
            });
            if (!isNullOrEmpty(selectedAreas)) {
                this.$router.push({ path: "/odeme",query : {type : "Şehir ekleme", price : this.price, cities : this.cities} });
            }
        },
    },
};
