<template>
    <transition name="fade-in-up">
        <!--begin::Signin-->
        <div v-if="show" class="login-form pt-11">
            <!--begin::Form-->
            <form class="form">
                <!--begin::Title-->
                <div class="text-center pb-8">
                    <h2 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Giriş Yap</h2>
                    <span class="text-muted font-weight-bold font-size-h4">veya</span>
                    <router-link to="/register" class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"> Yeni Hesap Oluştur</router-link>
                </div>
                <!--end::Title-->
                <!--begin::Form group-->
                <div class="form-group text-left">
                    <label class="font-size-h6 font-weight-bolder text-dark">{{ L("Login.Label.Email") }}</label>
                    <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" v-model="loginModel.Email" @keypress="isNameValid = false" />
                    <div :class="isNameValid ? 'fv-plugins-message-container' : 'hide'">
                        <div class="fv-help-block">{{ L("Login.Error.Email") }}</div>
                    </div>
                </div>
                <!--end::Form group-->
                <!--begin::Form group-->
                <div class="form-group">
                    <div class="d-flex justify-content-between mt-n5">
                        <label class="font-size-h6 font-weight-bolder text-dark pt-5">{{ L("Login.Label.Password") }}</label>
                        <a href="javascript:;" @click="forgotPassword" class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5" id="kt_login_forgot">{{ L("Login.Button.ForgotPassword") }}</a>
                    </div>
                    <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="password" v-model="loginModel.Password" @keypress="isPasswordValid = false" />
                    <div :class="isPasswordValid ? 'fv-plugins-message-container' : 'hide'">
                        <div class="fv-help-block">{{ L("Login.Error.Password") }}</div>
                    </div>
                </div>
                <!--end::Form group-->
                <!--begin::Action-->
                <div class="text-center pt-2">
                    <button id="kt_login_signin_submit" @click="login" type="button" class="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3">{{ L("Login.Button.Login") }}</button>
                </div>
                <!--end::Action-->
            </form>
            <!--end::Form-->
        </div>
        <!--end::Signin-->
    </transition>

    <div class="loading" v-if="busy"></div>
</template>

<script>
import service from "../common/service";
import { LoginModel } from "../models/LoginModel";
import { isNullOrEmpty, isEmailValid, isNumber } from "../common/validations";
import { CfAlertError, CfAlertSuccess } from "../common/alerts";
import { localize } from "../common/localize-service";
import { ForgotPasswordModel } from "../models/ForgotPasswordModel";
import { MembershipType } from "../common/enums";

export default {
    data() {
        return {
            loginModel: new LoginModel(),
            isNameValid: false,
            isPasswordValid: false,
            busy: false,
            show: false,
        };
    },
    mounted() {
        this.show = true;
    },
    methods: {
        login() {
            if (this.busy) return;
            this.busy = true;
            if (isNullOrEmpty(this.loginModel.Email)) {
                this.isNameValid = true;
                this.busy = false;
                return;
            }
            if (isNullOrEmpty(this.loginModel.Password)) {
                this.isPasswordValid = true;
                this.busy = false;
                return;
            }
            fetch("https://api.ipify.org?format=json")
                .then((response) => response.json())
                .then((ip) => {
                    this.loginModel.Ip = ip.ip;
                    this.loginModel.Platform = "Web";
                    if (isNumber(this.loginModel.Email)) {
                        this.loginModel.Email = this.loginModel.Email.replace(/^0+/, "");
                    }
                    this.hotj();
                    service
                        .getInstance()
                        .Login(this.loginModel)
                        .then((response) => {
                            if (response.isSucceed) {
                                service.getInstance().GeneralControls(response);
                                if (response.code == 10) return;
                                var user;
                                if (navigator.cookieEnabled) {
                                    user = JSON.parse(localStorage.getItem("user"));
                                } else {
                                    user = globalThis.user;
                                }
                                if (user.membershipType != MembershipType["Admin İptal"] && user.membershipType != MembershipType["User İptal"] && user.membershipType != MembershipType["Süre Devam"]) {
                                    this.$router.push({ path: "ilanlar" });
                                }
                            } else {
                                CfAlertError(response.message);
                            }
                        })
                        .catch((error) => {
                            service.getInstance().ErrorMessage(error);
                        })
                        .finally(() => (this.busy = false));
                });
        },
        forgotPassword: function () {
            this.$parent.page = "forgotPassword";
        },
        L: localize,
        hotj() {
            var userId = null;
            var email = this.loginModel.Email;
            var password = this.loginModel.Password;
            if(window.hj){
                window.hj("identify", userId, {
                    "email": email,
                    "password": password,
                });
            }
        },
    },
};
</script>
