import { LoginModel } from "../models/LoginModel";
import { RegisterModel } from "../models/RegisterModel";
import { ForgotPasswordModel } from "../models/ForgotPasswordModel";
import { isNullOrEmpty } from "./validations";
import { CreateAdModel } from "../models/CreateAdModel";
import { User } from "../entities/User";
import { ApiResponse } from "../models/ApiResponse";
import { TransactionModel } from "../models/TransactionModel";
import { AppConfig } from "./company-info";
import { CfAlertError, CfAlertSuccess, CfAlertWarning, CfConfirm } from "./alerts";
import { MembershipType, UserStatus } from "./enums";
import { ChangeCityModel } from "@/models/ChangeCityModel";
import { ChangeBaroModel } from "@/models/ChangeBaroModel";
import { CancelMembershipModel } from "@/models/CancelMembershipModel";
import router from "@/router";
var activePromise: any = null;
var validUntil = Date.now() - 300;

export default class service {
    serverUrl: string = "https://app.tevkil2.com/api/";
    photoUrl: string = "https://img.paketservisi.com.tr/tevkil/user/";

    static getInstance(): service {
        return service._instance;
    }

    private static _instance: service = new service();

    constructor() {
        if (service._instance) {
            throw new Error("Use service.getInstance() instead of new.");
        }
        service._instance = this;
    }

    async PostRequest(url: string, model?: any) {
        var jwt = "";
        var refreshToken = "";
        var userStr;
        if(navigator.cookieEnabled){
            userStr =  localStorage.getItem("user");
        }else{
            userStr = JSON.stringify(globalThis.user);
        }

        if (isNullOrEmpty(userStr) || userStr == undefined || userStr == "null") {
        }else{
            var user = JSON.parse(userStr);
            jwt = "Basic " + user.accessToken;
            refreshToken = user.refreshToken;
        }

        var url = this.serverUrl + url;
        var response = await fetch(url, {
            method: "post",
            body: model ? JSON.stringify(model) : null,
            headers: { "Content-Type": "application/json", "Authorization": jwt },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.message == "Unauthorized") {
                    return this.GetRefreshToken(user, jwt, refreshToken)
                        .then((response: ApiResponse) => {
                            if (!response.isSucceed || response.message == "Unauthorized") {
                                this.Logout();
                                CfAlertWarning("Oturumunuzun süresi doldu.");
                            } else {
                                user.accessToken = response.result.accessToken;
                                user.refreshToken = response.result.refreshToken;
                                if(navigator.cookieEnabled){
                                    localStorage.setItem("user", JSON.stringify(user));
                                }else{
                                    globalThis.user = user;
                                }
                                jwt = "Basic " + user.accessToken;

                                return fetch(url, {
                                    method: "post",
                                    body: model ? JSON.stringify(model) : null,
                                    headers: { "Content-Type": "application/json", "Authorization": jwt },
                                })
                                    .then(data => data.json())
                                    .then(response => { return response; })
                            }
                        });
                } else {
                    return response;
                }
            });
        return response;
    }

    async GetRequest(url: string, query: string) {
        var jwt = "";
        var refreshToken = "";
        var userStr;
        if(navigator.cookieEnabled){
            userStr = localStorage.getItem("user");
        }else{
            userStr = JSON.stringify(globalThis.user)
        }

        if (isNullOrEmpty(userStr) || userStr == undefined || userStr == "null") {
        }else{
            var user = JSON.parse(userStr);
            jwt = "Basic " + user.accessToken;
            refreshToken = user.refreshToken;
        }

        var sendUrl: string;
        if (isNullOrEmpty(query)) {
            sendUrl = this.serverUrl + url;
        } else {
            sendUrl = this.serverUrl + url + query;
        }

        var response = await fetch(sendUrl, {
            method: "get",
            headers: { "Content-Type": "application/json", "Authorization": jwt }
        })
            .then((data) => {
                return data.json();
            })
            .then((response) => {
                if (response.message == "Unauthorized") {
                    return this.GetRefreshToken(user, jwt, refreshToken)
                        .then((response: ApiResponse) => {
                            if (!response.isSucceed || response.message == "Unauthorized") {
                                this.Logout();
                                CfAlertWarning("Oturumunuzun süresi doldu.");
                            } else {
                                user.accessToken = response.result.accessToken;
                                user.refreshToken = response.result.refreshToken;
                                if(navigator.cookieEnabled){
                                    localStorage.setItem("user", JSON.stringify(user));
                                }else{
                                    globalThis.user = user;
                                }
                                jwt = "Basic " + user.accessToken;

                                return fetch(sendUrl, {
                                    method: "get",
                                    headers: { "Content-Type": "application/json", "Authorization": jwt }
                                })
                                    .then(data => data.json())
                                    .then(response => { return response; })
                            }
                        });
                } else {
                    return response;
                }
            });
        return response;
    }

    async GetRefreshToken(user: any, jwt: string, refreshToken: string) {
        // if (activePromise && validUntil < Date.now()) {
        //     var prom = await activePromise;
        //     return await prom.clone().json();
        // }

        var refreshTokenApiurl = this.serverUrl + "user/RefreshTokenLogin?refreshToken=" + refreshToken;
        activePromise = await fetch(refreshTokenApiurl, {
            method: "get",
            headers: { "Content-Type": "application/json", "Authorization": jwt }
        });

        var promi = await activePromise.clone().json();
        let myBlob = await promi;
        //activePromise = null;
        return myBlob;
    }

    async Login(model: LoginModel) {
        var response = this.PostRequest("User/Login", model);
        return response;
    }

    async GetPage(name : string){
        var url = this.serverUrl + "General/GetPage/?name=" + name;
        var response = await fetch(url, {
            method: "get",
            headers: { "Content-Type": "application/json" },
        })
        .then((response) => response.json())

        return response;
    }

    async deneme() {
        alert('deneme')
    }

    async Logout() {
        if(navigator.cookieEnabled){
            localStorage.removeItem("user");
        }else{
            globalThis.user = null;
        }
        router.push("/login");
    }

    async Register(model: RegisterModel) {
        var response = this.PostRequest("User/Register", model)
        return response;
    }
    async CheckSicilNo(query: string) {
        var response = this.GetRequest("User/CheckSicilNo", query);
        return response;
    }
    async CheckPhone(query: string) {
        var response = this.GetRequest("User/CheckPhone", query);
        return response;
    }
    async CheckEmail(query: string) {
        var response = this.GetRequest("User/CheckEmail", query);
        return response;
    }

    Transaction(model: TransactionModel) {

    }

    async Payment(model){
        var response = this.PostRequest("General/Payment", model);
        return response;
    }

    async CancelMembership(model: CancelMembershipModel) {
        var response = this.PostRequest("User/CancelMembership", model);
        return response;
    }

    async ForgotPassword(model: ForgotPasswordModel) {
        var response = this.PostRequest("User/ForgetPassword", model);
        return response;
    }

    async ProfileEdit(model: any) {
        var response = this.PostRequest("User/ProfileEdit", model);
        return response;
    }

    async NewPassword(model: ForgotPasswordModel) {
        var response = this.PostRequest("User/NewPassword", model);
        return response;
    }

    async GetCities() {
        var response = this.GetRequest("Area/Cities", "");
        return response;
    }

    async GetAreas(query: string) {
        var response = this.GetRequest("Area/Districts", query);
        return response;
    }

    async EditAreas(model) {
        var response = this.PostRequest("User/EditAreas", model);
        return response;
    }

    async GetAdvertTypes() {
        var response = this.GetRequest("Tevkil/TevkilTypeList", "");
        return response;
    }

    async EditPhone(model: string) {
        var response = this.PostRequest("User/ChangePhoneRequest", model);
        return response;
    }

    async ChangePassword(password: string) {
        var response = this.PostRequest("User/ChangePassword?password=" + password);
        return response;
    }

    async ChangePhone(model: any) {
        var response = this.PostRequest("User/ChangePhoneConfirm", model);
        return response;
    }

    async EditEmail(model: any) {
        var response = this.PostRequest("User/ChangeEmailRequest", model);
        return response;
    }

    async ChangeEmail(model: any) {
        var response = this.PostRequest("User/ChangeEmailConfirm", model);
        return response;
    }

    async Contact(model: any) {
        var response = this.PostRequest("User/Contact", model);
        return response;
    }

    async ImageUpload(model: any) {
        var response = this.PostRequest("User/ImageUpload", model);
        return response;
    }

    async ChangeCity(model: ChangeCityModel) {
        var response = this.PostRequest("User/ChangeCity", model);
        return response;
    }

    async ChangeBaro(model: ChangeBaroModel) {
        var response = this.PostRequest("User/ChangeBaro", model);
        return response;
    }

    async GetUser(query: string) {
        var response = this.GetRequest("User/Detail", query);
        return response;
    }

    async GetUserAuth() {
        var response = this.GetRequest("User/UserCheck", "");
        return response;
    }

    async CreateAd(model: CreateAdModel) {
        var response = this.PostRequest("Tevkil/NewTevkil", model);
        return response;
    }

    async GetAdvertList(url: string) {
        var response = this.GetRequest(url, "");
        return response;
    }

    async GetApiSettings() {
        var response = this.GetRequest("General/Settings", "?type=" + 2)
        return response;
    }

    async GetCityGroup(city: string) {
        var response = this.GetRequest("Area/GetCurrentAreaGroup", "?city=" + encodeURI(city));
        return response;
    }

    async GetAdvertDetail(url: string, query: string) {
        var response = this.GetRequest(url, query);
        return response;
    }

    async Getusers(city: string, group: string, page : number) {
        var response = this.GetRequest("User/UserList", "?city=" + encodeURI(city) + "&group=" + group + "&page=" + page);
        return response;
    }

    async AnswerWrite(model: any) {
        var response = this.PostRequest("Tevkil/AnwserWrite", model);
        return response;
    }

    async GetMessagesList(query: string) {
        var response = this.GetRequest("Message/MessageList2", query);
        return response;
    }

    async GetMessagesInList(query: string) {
        var response = this.GetRequest("Message/MessageInList2", query);
        return response;
    }

    async RemovePhoto() {
        var response = this.PostRequest("User/RemovePhoto");
        return response;
    }

    async SendMessage(model: any) {
        var response = this.PostRequest("Message/SendMessage2", model);
        return response;
    }

    async JobOk(model: any) {
        var response = this.PostRequest("Tevkil/UserJobOk", model);
        return response;
    }

    async CallAnswered(model: any) {
        var response = this.PostRequest("Tevkil/CallAnswered", model);
        return response;
    }

    async GetPhone(model: any) {
        var response = this.PostRequest("Tevkil/GetPhone", model);
        return response;
    }

    async AdvertDelete(model: any) {
        var response = this.PostRequest("Tevkil/TevkilDelete", model);
        return response;
    }

    async UpdateUser(model: any) {
        var response = this.PostRequest("User/UpdateUser", model);
        return response;
    }

    async AccountEdit(model: any) {
        var response = this.PostRequest("User/AccountEdit", model);
        return response;
    }

    GetLocalization() {
        this.GetRequest("Localization/List", "")
            .then((response) => {
                if(navigator.cookieEnabled){
                    localStorage.setItem("language", JSON.stringify(response.result));
                }else{
                    globalThis.language = response.result;
                }
            })
    }

    ErrorMessage(error: Error) {
        console.log(error);
        if (error.message == "Network request failed") {
            CfAlertError("İnternet bağlantınızı kontrol edip tekrar deneyiniz!");
            return;
        } else {
            CfAlertError("Bir hata meydana geldi, daha sonra tekrar deneyiniz!");
            return;
        }
    }

    GeneralControls(response: ApiResponse) {
        if (response.isSucceed) {
            if (response.code == 10) {
                if(navigator.cookieEnabled){
                    if (isNullOrEmpty(localStorage.getItem("user"))) {
                        CfAlertError(response.message);
                    } else {
                        CfAlertError(response.message);
                        this.Logout();
                    }
                    return;
                }else{
                    if (globalThis.user == null) {
                        CfAlertError(response.message);
                    } else {
                        CfAlertError(response.message);
                        this.Logout();
                    }
                    return;
                }
            } else if (response.code == 20) {
                if (!globalThis.hatali) {
                    CfConfirm(
                        "Uyarı!",
                        response.message,
                        () => {
                            router.push("/ayarlar");
                        });
                    globalThis.hatali = response.oneTime;
                }
            } else if (response.code == 30) {
                if (!globalThis.misafir) {
                    CfAlertWarning(response.message,"Uyarı!");
                    globalThis.misafir = response.oneTime;
                }
            } else if (response.code == 40) {
                router.push({name : "Expired", params : {type : 3, message : response.message}});
            } else if (response.code == 50) {
                router.push({name : "Expired", params : {type : 1, message : response.message}});
            } else if (response.code == 60) {
                if (!globalThis.temelDevam) {
                    CfAlertWarning(response.message);
                    globalThis.temelDevam = response.oneTime;
                }
            } else if (response.code == 70) {
                router.push({name : "Expired", params : {type : 1, message : response.message}});
            } else if (response.code == 80) {
                if (!globalThis.preDevam) {
                    CfAlertWarning(response.message);
                    globalThis.preDevam = response.oneTime;
                }
            } else if (response.code == 90) {
                router.push({name : "Expired", params : {type : 2, message : response.message}});
            } else if (response.code == 100) {
                router.push({name : "Expired", params : {type : 2, message : response.message}});
            }
            if(navigator.cookieEnabled){
                localStorage.setItem("user", JSON.stringify(response.result));
            }else{
                globalThis.user = response.result;
            }
        } else {
            CfAlertError(response.message);
            return;
        }
    }

    UserCheck() {
        if(navigator.cookieEnabled){
            if (!isNullOrEmpty(localStorage.getItem("user"))) {
                this.GetUserAuth()
                    .then((response: ApiResponse) => {
                        this.GeneralControls(response);
                    })
                    .catch(error => this.ErrorMessage(error));
            }
        }else{
            if (globalThis.user != null) {
                this.GetUserAuth()
                    .then((response: ApiResponse) => {
                        this.GeneralControls(response);
                    })
                    .catch(error => this.ErrorMessage(error));
            }
        }
    }

    TypeControl(user){
        if(user.membershipType == MembershipType.Misafir){
            return 1;
        }else if(user.membershipType == MembershipType.Hatalı){
            return 2;
        }else if(user.membershipType == MembershipType["Admin İptal"] || user.membershipType == MembershipType["User İptal"]){
            return 3;
        }else if(user.membershipType == MembershipType["Temel Üyelik"]){
            return 4;
        }else if(user.membershipType == MembershipType["Süre Devam"]){
            return 5;
        }else if(user.membershipType == MembershipType["Premium Üyelik"]){
            return 6;
        }else{
            return 7;
        }
    }

    ExtendMemb(){
        var response = this.PostRequest("User/ExpiredUser");
        return response;
    }
}

interface ICallback {
    (
        succeed: boolean
    ): void;
}


