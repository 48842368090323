
import service from "@/common/service";
import { MembershipType } from "@/common/enums";
export default {
    data() {
        return {
            asideOn: false,
            user: navigator.cookieEnabled? JSON.parse(localStorage.getItem("user")) : globalThis.user,
        };
    },
    mounted() {
        if (this.user == null) {
            this.$router.push({ path: "/login" });
        } else {
            service.getInstance().UserCheck();
            setTimeout(() => {
                if(navigator.cookieEnabled){
                    this.user = JSON.parse(localStorage.getItem("user"));
                }else{
                    this.user = globalThis.user;
                }
            }, 500);
        }
    },
    methods: {
        updateUser(user) {
            this.user = user;
        },
        logOut() {
            service.getInstance().Logout();
        },
    },
};
