
import service from "@/common/service";
import { ApiResponse } from "@/models/ApiResponse";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";
import { MembershipType, Purpose, WorkingType } from "@/common/enums";
import { localize } from "@/common/localize-service";
import { isNullOrEmpty } from "@/common/validations";
import { SendMessageModel } from "@/models/SendMessageModel";
import { Variables } from "@/common/variables";
import { localeSort } from "@/common/locale-sort";
import { dateString } from '@/common/date-format';

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            userList: [],
            cityList: [],
            selectedCity: "",
            showModal: false,
            lawyer: Object,
            workingType: WorkingType,
            purpose: Purpose,
            L: localize,
            state: 1,
            message: "",
            busy: false,
            imagePath: Variables.ImagePath,
            userCheck: Number,
            count : 0,
            page : 1,
            max : 1,
            dateString : dateString
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
        
        if (this.userCheck != 1 && this.userCheck != 2 && this.userCheck != 3 && this.userCheck != 5) {
            this.selectedCity = this.user.city;
            service
                .getInstance()
                .Getusers(this.user.city, this.user.group,this.page)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        var item = response.result.userList.find((c) => c.userNo == this.user.userNo);
                        if (item) item.experience = -1;
                        this.count = response.result.count;
                        this.max = Math.ceil(this.count/100);
                        this.userList = response.result.userList.sort((a, b) => {
                            return a.experience - b.experience;
                        });
                    }
                })
                .catch((error) => {})
                .finally(() => {
                    this.busy = false;
                });

            service
                .getInstance()
                .GetCities()
                .then((response) => {
                    this.cityList = response.result.sort(localeSort);
                });

            this.selectedCity = this.user.city;
        }
    },
    methods: {
        cityChange() {
            this.page = 1;
            service
                .getInstance()
                .Getusers(this.selectedCity, this.user.group,this.page)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        var item = response.result.userList.find((c) => c.userNo == this.user.userNo);
                        this.count = response.result.count;
                        this.max = Math.ceil(this.count/100);
                        if (item) item.experience = -1;
                        this.userList = response.result.userList.sort((a, b) => {
                            return a.experience - b.experience;
                        });
                    }
                })
                .catch((error) => {})
                .finally(() => {});
        },
        pagination(page1){
            this.page = page1;
             service
                .getInstance()
                .Getusers(this.selectedCity, this.user.group,page1)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        var item = response.result.userList.find((c) => c.userNo == this.user.userNo);
                        this.count = response.result.count;
                        this.max = Math.ceil(this.count/100);
                        if (item) item.experience = -1;
                        this.userList = response.result.userList.sort((a, b) => {
                            return a.experience - b.experience;
                        });
                    }
                })
                .catch((error) => {})
                .finally(() => {});
        },
        getUser(item) {
            if (this.busy) return;
            this.busy = true;
            this.message = "";

            service
                .getInstance()
                .GetUser("?id=" + item.id)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.lawyer = response.result;
                        this.showModal = true;
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        SendMessage() {
            if (this.busy) return;
            this.busy = true;

            if (this.userCheck == 1) {
                CfAlertError(localize("Message.Error.Misafir"));
                this.busy = false;
                return;
            } else if (this.userCheck == 4 || this.userCheck == 5) {
                CfAlertError(localize("Message.Error.Temel"));
                this.busy = false;
                return;
            } else if (this.userCheck == 2) {
                CfAlertError(localize("Message.Error.Hatali"));
                this.busy = false;
                return;
            } else if (this.userCheck == 3) {
                CfAlertError(localize("Message.Error.Iptal"));
                this.busy = false;
                return;
            }

            var messageModel = new SendMessageModel();
            messageModel.message = this.message;
            messageModel.receiverId = this.lawyer.id;

            if (isNullOrEmpty(messageModel.message)) {
                this.busy = false;
                return;
            }

            service
                .getInstance()
                .SendMessage(messageModel)
                .then((response: ApiResponse) => {
                    if (response.isSucceed == true) {
                        this.message = "";
                        this.busy = false;
                        CfAlertSuccess("Mesajınız iletildi!");
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        closeModal() {
            this.showModal = false;
            this.state = 1;
        },
    },
};
