<template>
    <div class="content d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container">
            <div class="subheader py-3 py-lg-8 subheader-transparent">
                <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <!--begin::Info-->
                    <div class="d-flex align-items-center mr-1">
                        <!--begin::Page Heading-->
                        <div class="d-flex align-items-baseline flex-wrap mr-5">
                            <!--begin::Page Title-->
                            <h2 class="d-flex align-items-center text-dark font-weight-bold my-1 mr-3">
                                {{ L("MessagesTitle") }}
                            </h2>
                            <!--end::Page Title-->
                        </div>
                        <!--end::Page Heading-->
                    </div>
                    <!--end::Info-->
                </div>
            </div>
            <!--begin::Chat-->
            <div class="d-flex flex-row py-5">
                <!--begin::Aside-->
                <div class="flex-row-auto col-12" v-if="chatbox && list.length > 0">
                    <!--begin::Card-->
                    <div class="card card-custom">
                        <!--begin::Body-->
                        <div class="card-body">
                            <!--begin:Search-->
                            <div class="input-group input-group-solid">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <span class="svg-icon svg-icon-lg">
                                            <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                    <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"></path>
                                                </g>
                                            </svg>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </span>
                                </div>
                                <input type="text" class="form-control py-4 h-auto" v-model="searchText" @keyup="search" placeholder="Mesaj ara" />
                            </div>
                            <!--end:Search-->
                            <!--begin:Users-->
                            <div class="mt-7 scroll scroll-pull ps ps--active-y">
                                <!--begin:User-->
                                <div class="d-flex align-items-center justify-content-between mb-5" v-for="item in filteredList" :key="item.id">
                                    <div class="d-flex align-items-center">
                                        <div class="symbol symbol-circle symbol-75 mr-3">
                                            <img alt="Pic" :src="imagePath + item.users.find((c) => c.userNo != user.userNo).photo" />
                                        </div>
                                        <div class="d-flex flex-column">
                                            <a href="javascript:;" @click="messenger(item)" class="text-dark-75 text-hover-primary font-weight-bold font-size-lg">{{ item.users.find((c) => c.userNo != user.userNo).userNo }}</a>
                                            <span class="text-muted font-weight-bold font-size-sm">{{ item.messages[item.messages.length - 1].message }}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <span class="text-muted font-weight-bold font-size-sm" v-if="dateString(today) == dateString(new Date(item.lastMessage))">Bugün</span>
                                        <span class="text-muted font-weight-bold font-size-sm" v-else-if="dateString(yesterday) == dateString(new Date(item.lastMessage))">Dün</span>
                                        <span class="text-muted font-weight-bold font-size-sm" v-else>{{ dateString(new Date(item.lastMessage)) }}</span>
                                    </div>
                                </div>
                                <!--end:User-->
                            </div>
                            <!--end:Users-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Card-->
                </div>
                <div v-if="list.length <= 0" class="card card-custom gutter-b col-12 h-100">
                    <div class="card-body">
                        <div class="row">
                            <label class="col-12 text-center">{{ L("Message.Text.NoMessage") }}</label>
                        </div>
                    </div>
                </div>
                <!--end::Aside-->
                <!--begin::Content-->
                <div class="flex-row-fluid ml-lg-5 col-12" v-if="messages != null && !chatbox">
                    <!--begin::Card-->
                    <div class="card card-custom">
                        <!--begin::Header-->

                        <div class="card-header align-items-center px-4 py-3 justify-content-center">
                            <div class="text-center text-center">
                                <div class="symbol-group symbol-hover justify-content-center">
                                    <div class="symbol symbol-50 symbol-circle">
                                        <img alt="Pic" :src="imagePath + messages.users.find((c) => c.userNo != user.userNo).photo" />
                                    </div>
                                    <span class="ml-4 text-dark-75 font-weight-bold font-size-lg">{{ messages.users.find((c) => c.userNo != user.userNo).userNo }}</span>
                                </div>
                            </div>
                            <div class="ml-auto" @click="chatbox = !chatbox"><i class="fas fa-address-book text-muted" style="font-size: 28px"></i></div>
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body">
                            <!--begin::Scroll-->
                            <div class="scroll scroll-pull" id="mydiv" ref="myid" data-mobile-height="350" style="height: 495px; overflow-y: scroll">
                                <!--begin::Messages-->
                                <div class="messages">
                                    <div v-for="message in messages.messages" :key="message.id">
                                        <div v-if="message.userNo == user.userNo" class="d-flex flex-column mb-5 align-items-end">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <span class="text-muted font-size-sm">{{ hourString(message.createDate) }}</span>
                                                </div>
                                            </div>
                                            <div class="mt-2 rounded p-3 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px">{{ message.message }}</div>
                                        </div>

                                        <div v-else class="d-flex flex-column mb-5 align-items-start">
                                            <div class="d-flex align-items-center">
                                                <div>
                                                    <span class="text-muted font-size-sm">{{ hourString(message.createDate) }}</span>
                                                </div>
                                            </div>
                                            <div class="mt-2 rounded p-3 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px">{{ message.message }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!--end::Messages-->
                            </div>
                            <!--end::Scroll-->
                        </div>
                        <!--end::Body-->
                        <!--begin::Footer-->
                        <div class="card-footer align-items-center">
                            <!--begin::Compose-->
                            <textarea class="form-control border-0 p-0" rows="2" placeholder="Mesajınız" v-model="message"></textarea>
                            <div class="d-flex align-items-center justify-content-between mt-5">
                                <button @click="send" type="button" class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6">Gönder</button>
                            </div>
                            <!--begin::Compose-->
                        </div>
                        <!--end::Footer-->
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Content-->
            </div>
            <!--end::Chat-->
        </div>
        <!--end::Container-->
    </div>

    <div class="loading" v-if="busy"></div>
</template>

<script lang="ts">
import service from "@/common/service";
import { dateString, hourString, onlyHour } from "@/common/date-format";
import { ApiResponse } from "@/models/ApiResponse";
import { SendMessageModel } from "@/models/SendMessageModel";
import { MembershipType } from "@/common/enums";
import { isNullOrEmpty } from "@/common/validations";
import { CfAlertError } from "@/common/alerts";
import { localize } from "@/common/localize-service";
import { Variables } from "@/common/variables";

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            list: [],
            model: Object,
            today: new Date(Date.now()),
            messages: [],
            yesterday: new Date(Date.now() - 1000 * 60 * 60 * 24),
            dateString: dateString,
            busy: true,
            message: "",
            onlyHour: onlyHour,
            hourString: hourString,
            chatbox: true,
            searchText: "",
            filteredList: [],
            imagePath: Variables.ImagePath,
            L: localize,
            userCheck: Number,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
        this.user.unreadMessage = false;
        this.$emit("updateUser", this.user);

        service
            .getInstance()
            .GetMessagesList("")
            .then((response) => {
                this.list = response.result.sort((a, b) => {
                    return a.createDate - b.createDate;
                });
                this.filteredList = [].concat(this.list);
            })
            .catch((error) => {})
            .finally(() => (this.busy = false));
    },
    methods: {
        messenger(item) {
            if (this.busy) return;
            this.busy = true;
            this.message = "";

            service
                .getInstance()
                .GetMessagesInList("?id=" + item.id)
                .then((response: ApiResponse) => {
                    this.messages = response.result;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.chatbox = false;
                    this.busy = false;
                    setTimeout(() => {
                        this.$refs.myid.scrollTop = this.$refs.myid.scrollHeight;
                    }, 200);
                });
        },
        send() {
            if (this.busy) return;
            this.busy = true;

            if (this.userCheck == 1) {
                CfAlertError(localize("Message.Error.Misafir"));
                this.busy = false;
                return;
            } else if (this.userCheck == 4 || this.userCheck == 5) {
                CfAlertError(localize("Message.Error.Temel"));
                this.busy = false;
                return;
            } else if (this.userCheck == 2) {
                CfAlertError(localize("Message.Error.Hatali"));
                this.busy = false;
                return;
            } else if (this.userCheck == 3) {
                CfAlertError(localize("Message.Error.Iptal"));
                this.busy = false;
                return;
            }
            var messageModel = new SendMessageModel();
            messageModel.chatId = this.messages.id;
            messageModel.message = this.message;

            if (isNullOrEmpty(messageModel.message)) {
                this.busy = false;
                return;
            }

            service
                .getInstance()
                .SendMessage(messageModel)
                .then((response) => {
                    if (response.isSucceed == true) {
                        this.message = "";
                        this.busy = false;
                        this.messenger(this.messages);
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        search() {
            this.filteredList = this.list.filter((c) => c.messages.find((d) => d.message.indexOf(this.searchText) != -1));
        },
    },
};
</script>
<style scoped>
.img-slider {
    overflow: hidden;
    position: relative;
    height: 200px;
    width: 400px;
}

.img-slider img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
</style>
