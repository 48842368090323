<template>
    <!--begin::Content-->
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <!--begin::Subheader-->
        <div class="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <!--begin::Details-->
                <div class="d-flex align-items-center flex-wrap mr-2">
                    <!--begin::Title-->
                    <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ L("OfferTitle") }}</h5>
                    <!--end::Title-->
                </div>
                <!--end::Details-->
            </div>
        </div>
        <!--end::Subheader-->
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
                <div class="card gutter-b border-0 card-shadow">
                    <div class="card-body p-4">
                        <!--begin::Top-->
                        <div class="d-flex">
                            <!--begin::Pic-->
                            <div class="mr-lg-5 mr-3 text-center">
                                <div class="text-center">
                                    <div class="card-photo mb-2" :style="{ backgroundImage: 'url(' + (isNullOrEmpty(ad.photo) ? (ad.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + ad.photo) + ')' }"></div>
                                    <span class="font-weight-bold text-dark-50">{{ ad.userCity }}</span>
                                    <span class="font-weight-bold text-dark-50 d-block">{{ ad.userNo }}</span>
                                </div>
                            </div>
                            <!--end::Pic-->
                            <!--begin::User-->
                            <div class="w-100">
                                <!--begin::Name-->
                                <label class="d-flex align-items-center text-dark font-size-h6 font-weight-bold flex-wrap">
                                    <span class="mr-lg-3 mr-0 mobile-w-100">{{ "# " + ad.advertNo }}</span>
                                    <span class="mobile-hide">-</span>
                                    <span class="ml-lg-3 ml-0 mobile-w-100"><i class="far fa-clock mr-1 text-dark"></i>{{ dateString(ad.createDate) }}</span>
                                </label>
                                <!--end::Name-->
                                <!--begin::Contacts-->
                                <div class="d-flex flex-wrap my-2">
                                    <label class="text-muted font-weight-bold mr-lg-5 mr-3 mb-lg-0 mb-2 mobile-w-100"><i class="fas fa-briefcase mr-1"></i>{{ helpSubject[ad.advertType] }}</label>
                                    <label class="text-muted font-weight-bold">
                                        <i class="fas fa-map-marker-alt mr-1"></i>
                                        <span class="mr-1 text-dark" style="font-weight: bold">{{ ad.userCity }}</span>
                                        <i class="fas fa-arrow-right"></i>
                                        <span class="ml-1" style="color: red; font-weight: bold">{{ ad.city }}</span>
                                        <span>{{ " / " + ad.district }}</span>
                                    </label>
                                </div>
                                <!--end::Contacts-->
                                <!--begin::Content-->
                                <div class="mobile-hide">
                                    <div class="font-weight-bold text-dark-50 py-2 py-lg-2 mr-lg-5 text-justify">{{ ad.detail }}</div>
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--begin::User-->
                            <!--begin::Pic-->
                            <div class="ml-lg-5 ml-3 text-center" v-if="ad.status == 3">
                                <div class="text-center">
                                    <div class="card-photo mb-2" :style="{ backgroundImage: 'url(' + (isNullOrEmpty(ad.jobUser.photo) ? (ad.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + ad.jobUser.photo) + ')' }"></div>
                                    <span class="font-weight-bold text-dark-50">{{ ad.jobUser.city }}</span>
                                    <span class="font-weight-bold text-dark-50 d-block">{{ ad.jobUser.userNo }}</span>
                                </div>
                            </div>
                            <!--end::Pic-->
                        </div>
                        <!--begin::Content-->
                        <div class="mobile-show">
                            <div class="font-weight-bold text-dark-50 mt-3 text-justify">{{ ad.detail }}</div>
                        </div>
                        <!--end::Content-->
                        <!--end::Top-->
                        <!--begin::Separator-->
                        <div class="separator separator-solid my-3"></div>
                        <!--end::Separator-->
                         <!--begin::Bottom-->
                        <div class="ad-card-bottom">
                            <!--begin: Item-->
                            <div class="d-flex align-items-center justify-content-start my-1 col-6 col-lg-4">
                                <span class="mr-4">
                                    <i class="fas fa-wallet icon-2x text-muted"></i>
                                </span>
                                <div class="d-flex flex-column text-dark-75">
                                    <span class="font-weight-bolder font-size-sm">{{ L("AdList.Web.Label.Budget") }}</span>
                                    <span class="font-weight-bolder font-size-h5"> <span class="text-dark-50 font-weight-bold">₺ </span>{{ ad.budget }}</span>
                                </div>
                            </div>
                            <!--end: Item-->
                            <!--begin: Item-->
                            <div class="d-flex align-items-center my-1 justify-content-lg-center justify-content-end col-6 col-lg-4">
                                <span class="mr-4">
                                    <i class="far fa-calendar icon-2x text-muted"></i>
                                </span>
                                <div class="d-flex flex-column text-dark-75 text-center">
                                    <span class="font-weight-bolder font-size-sm">{{ L("AdList.Web.Label.LastDate") }}</span>
                                    <span class="font-weight-bolder font-size-h5"> <span class="text-dark-50 font-weight-bold"></span>{{ dateString(ad.endDate) }}</span>
                                </div>
                            </div>
                            <!--end: Item-->
                        </div>
                        <!--end::Bottom-->
                    </div>
                </div>

                <div class="card card-custom gutter-b">
                    <div class="card-body">
                        <div class="d-flex" v-if="user.membershipType == 3">
                            <div class="flex-shrink-0 mr-5">
                                 <div class="text-center">
                                    <div class="card-photo mb-2" :style="{ backgroundImage: 'url(' + (isNullOrEmpty(user.photo) ? (user.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + user.photo) + ')' }"></div>
                                </div>
                            </div>
                            <div class="mr-3 flex-grow-1 w-100">
                                <div>
                                    <div class="font-weight-bold text-dark-50 py-2 py-lg-2 text-left" style="text-align: justify !important">{{ L("Answer.Text.Description", user.startHour, user.endHour, user.phoneNumber) }}</div>
                                    <div class="font-weight-bold text-dark-50 py-2 py-lg-2 mr-5 text-left">{{ "Av. " + user.firstName + " " + user.lastName }}</div>
                                    <div class="form-group row">
                                        <label class="col-sm-2 col-form-label dot">Teklifiniz</label>
                                        <div class="col-sm-10 d-flex align-items-center">
                                            <input style="width: 100px;" type="number" v-model="answer.price" class="form-control" @keypress="isMinPriceValid = true; isBudgetValid = true;" /><span class="ml-3 text-dark"> TL</span>
                                        </div>
                                        <label v-if="!isMinPriceValid" class="text-danger text-left col-12">{{ L("Answer.ErrorMessage.MinumumPrice", minPrice) }}</label>
                                        <label v-if="!isBudgetValid" class="text-danger text-left col-12">{{ L("Answer.ErrorMessage.Budget",ad.budget) }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row justify-content-center text-center" v-if="userCheck == 4">
                            <label class="d-block">{{ L("Answer.GoPremiumMessage") }}</label>
                            <router-link to="/ayarlar/premium" class="btn btn-primary btn-lg col-6 d-block">{{ L("Login.PremiumBuyBtn") }}</router-link>
                        </div>

                        <div class="row justify-content-center text-center" v-if="userCheck == 1">
                            <label class="d-block">{{ L("AnswerAd.Error.Misafir") }}</label>
                        </div>

                        <div class="row justify-content-center text-center" v-if="userCheck == 2">
                            <label class="d-block">{{ L("AnswerAd.Error.Hatali") }}</label>
                            <router-link to="/ayarlar" class="btn btn-primary btn-lg col-6 d-block">{{ L("AnswerAdd.Button.Ayarlar") }}</router-link>
                        </div>

                        <div class="row justify-content-center" v-if="user.membershipType == 3">
                            <button class="btn btn-primary btn-lg col-6 mb-4" @click="answerAd">{{ L("Answer.Button.Send") }}</button>

                            <label v-if="L('AnswerConfirm1')" class="text-left"><i class="fas fa-star-of-life text-danger mr-2"></i>{{ L("AnswerConfirm1") }}</label>
                            <label v-if="L('AnswerConfirm2')" class="text-left"><i class="fas fa-star-of-life text-danger mr-2"></i>{{ L("AnswerConfirm2") }}</label>
                            <label v-if="L('AnswerConfirm3')" class="text-left"><i class="fas fa-star-of-life text-danger mr-2"></i>{{ L("AnswerConfirm3") }}</label>
                            <label v-if="L('AnswerConfirm4')" class="text-left"><i class="fas fa-star-of-life text-danger mr-2"></i>{{ L("AnswerConfirm4") }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="loading" v-if="busy"></div>
</template>

<script lang="ts">
import service from "@/common/service";
import { localize } from "@/common/localize-service";
import { dateString } from "@/common/date-format";
import { isNullOrEmpty } from "@/common/validations";
import { HelpSubject, MembershipType } from "@/common/enums";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";
import { ApiResponse } from "@/models/ApiResponse";
import { Variables } from "@/common/variables";

class WriteAnswerModel {
    advertId: string;
    price: number;
}

export default {
    props: {
        id: String,
        user : Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            ad: Object,
            L: localize,
            dateString: dateString,
            isNullOrEmpty: isNullOrEmpty,
            helpSubject: HelpSubject,
            isMinPriceValid: true,
            busy: false,
            minPrice: Number,
            answer: new WriteAnswerModel(),
            imagePath: Variables.ImagePath,
            userCheck : Number,
            isBudgetValid : true,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
        
        service
            .getInstance()
            .GetAdvertDetail("Tevkil/detail", "?id=" + this.$route.params.id)
            .then((response) => {
                this.ad = response.result;
                service
                    .getInstance()
                    .GetAdvertTypes()
                    .then((response) => {
                        this.minPrice = response.result.find((c) => c.typeId == this.ad.advertType).minimumPrice;
                    });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => (this.busy = false));
    },
    methods: {
        answerAd() {
            if (this.busy) return;
            this.busy = true;

            if(this.answer.price < this.ad.budget){
                this.isBudgetValid = false;
                this.busy = false;
                return;
            }

            if (this.minPrice == null || this.answer.price == null || this.answer.price < this.minPrice) {
                this.isMinPriceValid = false;
                this.busy = false;
                return;
            }

            if (this.user.membershipType == MembershipType.Hatalı) {
                CfAlertError(localize("AnswerAd.Error.Hatali"));
                this.busy = false;
                return;
            } else if (this.user.membershipType == MembershipType["Temel Üyelik"] || this.user.membershipType == MembershipType["Süre Devam"]) {
                CfAlertError(localize("AnswerAd.Error.Temel"));
                this.busy = false;
                return;
            } else if (this.user.membershipType == MembershipType.Misafir) {
                CfAlertError(localize("AnswerAd.Error.Misafir"));
                this.busy = false;
                return;
            } else if (this.user.membershipType == MembershipType["User İptal"] || this.user.membershipType == MembershipType["Admin İptal"]) {
                CfAlertError(localize("AnswerAd.Error.Iptal"));
                this.busy = false;
                return;
            }

            this.answer.advertId = this.ad.id;
            service
                .getInstance()
                .AnswerWrite(this.answer)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.$router.push({ path: "/ilanlar" });
                        CfAlertSuccess(response.message);
                    } else {
                        CfAlertError(response.message);
                        return;
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));

            this.busy = false;
        },
    },
};
</script>
