<template>
    <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">{{ L("ProfileEdit.Text.Title") }}</h3>
            </div>
            <div class="card-toolbar">
                <button type="button" class="btn btn-success" @click="editProfile">{{ L("ProfileEdit.Button.Save") }}</button>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
            <!--begin::Body-->
            <div class="card-body">
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left"></label>
                    <div class="col-lg-9 col-xl-6 text-center">
                        <div class="image-input image-input-outline" id="kt_profile_avatar" style="background-image: url(assets/media/users/blank.png)">
                            <div class="image-input-wrapper" style="background-position: center; background-size: cover; border: none" v-bind:style="{ backgroundImage: imageChanged ? 'url(' + changedPhoto + ')' : 'url(' + imagePath + user.photo + ')' }"></div>
                            <label v-if="userCheck != 1 && userCheck != 2" class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                                <i class="fa fa-pen icon-sm text-muted"></i>
                                <input type="file" @change="imageUpload($event)" name="profile_avatar" accept=".png, .jpg, .jpeg" />
                                <input type="hidden" name="profile_avatar_remove" />
                            </label>
                            <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
                                <i class="ki ki-bold-close icon-xs text-muted"></i>
                            </span>
                            <span v-if="userCheck != 1 && userCheck != 2" class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" @click="imageDelete" data-action="remove" data-toggle="tooltip" title="Remove avatar">
                                <i class="ki ki-bold-close icon-xs text-muted"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("ProfileEdit.Label.Baro") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <span class="form-control form-control-lg text-left">{{ user.baro }}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("ProfileEdit.Label.SicilNo") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <span class="form-control form-control-lg text-left">{{ user.sicilNo }}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("ProfileEdit.Label.City") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <span class="form-control form-control-lg text-left">{{ user.city }}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("ProfileEdit.Label.District") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <span class="form-control form-control-lg text-left">{{ user.district }}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("ProfileEdit.Label.Phone") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <div class="input-group input-group-lg">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-white">
                                    <i class="la la-phone"></i>
                                </span>
                            </div>
                            <span class="form-control form-control-lg text-left">{{ user.phoneNumber }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("ProfileEdit.Label.Email") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <div class="input-group input-group-lg">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-white">
                                    <i class="la la-at"></i>
                                </span>
                            </div>
                            <span class="form-control form-control-lg text-left">{{ user.email }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("ProfileEdit.Label.WorkingType") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <select class="form-control form-select form-control-lg form-control-solid" v-model="user.workingType">
                            <option v-for="workingType in workingTypes" :key="workingType.value" :value="workingType.value">{{ workingType.display }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("ProfileEdit.Label.Purpose") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <select class="form-control form-select form-control-lg form-control-solid" v-model="user.purpose">
                            <option v-for="purpose in purposes" :key="purpose.value" :value="purpose.value">{{ purpose.display }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("ProfileEdit.Label.Adliye") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="user.adliyeler" />
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Body-->
        </form>
        <!--end::Form-->
    </div>
</template>

<script lang="ts">
import { User } from "@/entities/User";
import { MembershipType, Purpose, WorkingType } from "@/common/enums";
import { ProfileEditModel } from "@/models/ProfileEditModel";
import service from "@/common/service";
import { ApiResponse } from "@/models/ApiResponse";
import { CfAlertError, CfAlertSuccess, CfConfirm } from "@/common/alerts";
import { localize } from "@/common/localize-service";
import { Variables } from '@/common/variables';

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            workingTypes: [],
            purposes: [],
            model: new ProfileEditModel(),
            imageChanged: false,
            changedPhoto: "",
            L: localize,
            imagePath : Variables.ImagePath,
            userCheck : Number,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
        
        for (var i in WorkingType) {
            if (typeof WorkingType[i] === "number") {
                this.workingTypes.push({ value: WorkingType[i], display: i });
            }
        }

        for (var i in Purpose) {
            if (typeof Purpose[i] === "number") {
                this.purposes.push({ value: Purpose[i], display: i });
            }
        }
    },
    methods: {
        editProfile() {
            if (this.busy) return;
            this.busy = true;

            this.model.Purpose = this.user.purpose;
            this.model.WorkingType = this.user.workingType;
            this.model.Adliyeler = this.user.adliyeler;

            service
                .getInstance()
                .ProfileEdit(this.model)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.$emit("updateUser", response.result);
                        CfAlertSuccess(response.message);
                         if(navigator.cookieEnabled){
                            localStorage.setItem("user",JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        imageUpload(event) {
            this.busy = true;

            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                this.changedPhoto = reader.result;
                this.model.photo = reader.result.toString().split(",")[1];
                this.imageChanged = true;
            };
            this.busy = false;
        },
        imageDelete() {
            CfConfirm("Fotoğrafı sil", "Fotoğrafı silmek istediğinize emin misiniz?", () => {
                this.busy = true;
                service
                    .getInstance()
                    .RemovePhoto()
                    .then((response: ApiResponse) => {
                        if (response.isSucceed) {
                            switch (this.user.gender) {
                                case "Erkek":
                                    this.user.photo = "erkek.jpg";
                                    break;
                                case "Kadın":
                                    this.user.photo = "kadin.jpg";
                                    break;
                                default:
                                    this.user.photo = "default.jpg";
                                    break;
                            }
                            this.imageChanged = false;
                            this.$parent.user = response.result;
                            globalThis.user = response.result;
                            CfAlertSuccess(response.message);
                        } else {
                            CfAlertError(response.message);
                        }
                    })
                    .catch((error) => {})
                    .finally(() => (this.busy = false));
            });
        },
    },
};
</script>
