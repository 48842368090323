export enum AdvertStatus
{
    Pendding = 0,
    Approved = 1,
    Reject = 2,
    Completed = 3,
}

export enum HelpSubject{
    "İcra" = 1,
    "Hukuk Dava" = 2,
    "Ceza Dava" = 3,
    "İdare Vergi Dava" = 4,
    "Kalem İşleri" = 5,
    "Adliye Dışı İşlem" = 6
}

export enum MembershipType{
    "Misafir" = 0,
    "Hatalı" = 1,
    "Temel Üyelik" = 2,
    "Premium Üyelik" = 3,
    "Admin İptal" = 4,
    "User İptal" = 5,
    "Süre Devam" = 6,
    "Kara Liste" = 7
}

export enum UserStatus{
    "Default" = 0,
    "Takibe Al" = 1,
    "Üyelik Yenileyemez" = 2,
    "Sisteme Giremez" = 3,
    "Yasak" = 4 //premium üye yenileme yapamaz.
}

export enum Purpose{
    "Yardım Almak" = 0,
    "Yardımcı Olmak" = 1,
    "Yardım almak ve Yardımcı olmak" = 2
}

export enum WorkingType{
    "Ofisim var" = 0,
    "Avukata Bağlı" = 1,
    "Serbest" = 2,
    "Kuruma Bağlı" = 3
}

export enum Prices{
    "50 TL" = 50,
    "100 TL" = 100,
    "150 TL" = 150,
    "200 TL" = 200,
    "250 TL" = 250,
    "300 TL" = 300,
    "350 TL" = 350,
    "400 TL" = 400,
    "450 TL" = 450,
    "500 TL" = 500,
    "550 TL" = 550,
    "600 TL" = 600,
    "650 TL" = 650,
    "700 TL" = 700,
    "750 TL" = 750,
    "800 TL" = 800,
    "850 TL" = 850,
    "900 TL" = 900,
    "950 TL" = 950,
    "1000 TL" = 1000,
}