<template>
    <!--begin::Content-->
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <!--begin::Subheader-->
        <div class="subheader py-3 py-lg-8 subheader-transparent" id="kt_subheader">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <!--begin::Info-->
                <div class="d-flex align-items-center mr-1">
                    <!--begin::Mobile Toggle-->
                    <button class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none" @click="asideOn = !asideOn">
                        <span></span>
                    </button>
                    <!--end::Mobile Toggle-->
                    <!--begin::Page Heading-->
                    <div class="d-flex align-items-baseline flex-wrap mr-5">
                        <!--begin::Page Title-->
                        <h2 class="d-flex align-items-center text-dark font-weight-bold my-1 mr-3">Ayarlar</h2>
                        <!--end::Page Title-->
                    </div>
                    <!--end::Page Heading-->
                </div>
                <!--end::Info-->
            </div>
        </div>
        <!--end::Subheader-->
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
                <!--begin::Profile Personal Information-->
                <div class="d-flex flex-row">
                    <!--begin::Aside-->
                    <div class="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" :style="{ left: asideOn ? 0 : '' }">
                        <!--begin::Profile Card-->
                        <div class="card card-custom card-stretch">
                            <!--begin::Body-->
                            <div class="card-body pt-4">
                                <!--begin::User-->
                                <div class="d-flex align-items-center">
                                    <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                        <div class="symbol-label" v-bind:style="{ backgroundImage: 'url(' + imagePath + user.photo + ')' }"></div>
                                    </div>
                                    <div>
                                        <a href="javascript:;" class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ user.firstName + " " + user.lastName }}</a>
                                    </div>
                                </div>
                                <!--end::User-->
                                <!--begin::Contact-->
                                <div class="py-9">
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <span class="mr-2 font-weight-bold dot">Email</span>
                                        <span class="text-muted">{{ user.email }}</span>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <span class="mr-2 font-weight-bold dot">Telefon</span>
                                        <span class="text-muted">{{ user.phoneNumber }}</span>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <span class="mr-2 font-weight-bold dot">Baronuz</span>
                                        <span class="text-muted">{{ user.baro }}</span>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <span class="mr-2 font-weight-bold dot">Baro Sicil Numaranız</span>
                                        <span class="text-muted">{{ user.sicilNo }}</span>
                                    </div>
                                </div>
                                <!--end::Contact-->
                                <!--begin::Nav-->
                                <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                                    <!--begin::Profil-->
                                    <div class="navi-item mb-2">
                                        <router-link to="/ayarlar" exact-active-class="active" @click="asideOn = false" class="navi-link py-4">
                                            <span class="navi-icon mr-2">
                                                <i class="fas fa-user-alt"></i>
                                            </span>
                                            <span class="navi-text font-size-lg text-left">{{ L("ProfileEdit.Text.Title") }}</span>
                                        </router-link>
                                    </div>
                                    <!--begin::Profil-->
                                    <!--begin::Hesap-->
                                    <div class="navi-item mb-2">
                                        <router-link to="/ayarlar/hesap-ayarlari" exact-active-class="active" @click="asideOn = false" class="navi-link py-4">
                                            <span class="navi-icon mr-2">
                                                <span class="svg-icon">
                                                    <i class="fas fa-cog"></i>
                                                </span>
                                            </span>
                                            <span class="navi-text font-size-lg text-left">{{ L("AccountSettings.Text.Title") }}</span>
                                        </router-link>
                                    </div>
                                    <!--begin::Hesap-->
                                    <!--begin::Bölge-->
                                    <div class="navi-item mb-2">
                                        <router-link to="/ayarlar/bolge-ayarlari" exact-active-class="active" @click="asideOn = false" class="navi-link py-4">
                                            <span class="navi-icon mr-2">
                                                <i class="fas fa-map-marker-alt"></i>
                                            </span>
                                            <span class="navi-text font-size-lg text-left">{{ L("AreaSettings.Text.Title") }}</span>
                                        </router-link>
                                    </div>
                                    <!--begin::Bölge-->
                                    <!--begin::Şifre-->
                                    <div class="navi-item mb-2">
                                        <router-link to="/ayarlar/sifre-ayarlari" exact-active-class="active" @click="asideOn = false" class="navi-link py-4">
                                            <span class="navi-icon mr-2">
                                                <i class="fas fa-key"></i>
                                            </span>
                                            <span class="navi-text font-size-lg text-left">{{ L("AccountSettings.Label.ChangePassword") }}</span>
                                        </router-link>
                                    </div>
                                    <!--begin::Şifre-->
                                    <!--begin::Telefon-->
                                    <div class="navi-item mb-2">
                                        <router-link to="/ayarlar/telefon-ayarlari" exact-active-class="active" @click="asideOn = false" class="navi-link py-4">
                                            <span class="navi-icon mr-2">
                                                <i class="fas fa-phone"></i>
                                            </span>
                                            <span class="navi-text font-size-lg text-left">{{ L("ChangePhone.Text.Title") }}</span>
                                        </router-link>
                                    </div>
                                    <!--begin::Telefon-->
                                    <!--begin::Email-->
                                    <div class="navi-item mb-2">
                                        <router-link to="/ayarlar/eposta-ayarlari" exact-active-class="active" @click="asideOn = false" class="navi-link py-4">
                                            <span class="navi-icon mr-2">
                                                <i class="fas fa-at"></i>
                                            </span>
                                            <span class="navi-text font-size-lg text-left">{{ L("ChangeEmail.Text.Title") }}</span>
                                        </router-link>
                                    </div>
                                    <!--begin::Email-->
                                    <!--begin::Email-->
                                    <div class="navi-item mb-2">
                                        <router-link to="/ayarlar/baro-ayarlari" exact-active-class="active" @click="asideOn = false" class="navi-link py-4">
                                            <span class="navi-icon mr-2">
                                                <i class="fas fa-briefcase"></i>
                                            </span>
                                            <span class="navi-text font-size-lg text-left">{{ L("BaroChange.Text.Title") }}</span>
                                        </router-link>
                                    </div>
                                    <!--begin::Email-->
                                    <!--begin::Sehir-->
                                    <div class="navi-item mb-2">
                                        <router-link to="/ayarlar/sehir-ayarlari" exact-active-class="active" @click="asideOn = false" class="navi-link py-4">
                                            <span class="navi-icon mr-2">
                                                <i class="fas fa-building"></i>
                                            </span>
                                            <span class="navi-text font-size-lg text-left">{{ L("CityChange.Text.Title") }}</span>
                                        </router-link>
                                    </div>
                                    <!--begin::Sehir-->
                                    <!--begin::Premium-->
                                    <div class="navi-item mb-2" v-if="userCheck != 1 && userCheck != 2">
                                        <router-link to="/ayarlar/premium" v-if="user.membershipType != 3" exact-active-class="active" @click="asideOn = false" class="navi-link py-4">
                                            <span class="navi-icon mr-2">
                                                <i class="fas fa-coins"></i>
                                            </span>
                                            <span class="navi-text font-size-lg text-left">Premium</span>
                                        </router-link>
                                    </div>
                                    <!--begin::Premium-->
                                    <!--begin::Iptal-->
                                    <div class="navi-item mb-2">
                                        <router-link to="/ayarlar/uyelik-iptal" v-if="user.membershipType != 3" exact-active-class="active" @click="asideOn = false" class="navi-link py-4">
                                            <span class="navi-icon mr-2">
                                                <i class="fas fa-building"></i>
                                            </span>
                                            <span class="navi-text font-size-lg text-left">{{ L("CancelMembership.Text.Title") }}</span>
                                        </router-link>
                                    </div>
                                    <!--begin::Iptal-->
                                </div>
                                <!--end::Nav-->
                            </div>
                            <!--end::Body-->
                        </div>
                        <!--end::Profile Card-->
                    </div>
                    <!--end::Aside-->
                    <!--begin::Content-->
                    <div class="flex-row-fluid ml-lg-8">
                        <router-view :user="user" @updateUser="updateUser"></router-view>
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Profile Personal Information-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
    <!--end::Content-->
</template>

<script lang="ts">
import { localize } from "@/common/localize-service";
import { Variables } from '@/common/variables';
import service from '@/common/service';

export default {
    props:{
        user : Object,
    },
    emits : ["updateUser"],
    data() {
        return {
            asideOn: false,
            L: localize,
            imagePath : Variables.ImagePath,
            userCheck : Number,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
    },
    methods:{
        updateUser(user){
            this.$emit("updateUser",user)
        }
    }
};
</script>

<style>
* {
    text-decoration: none !important;
}
.form-control{
    display: flex !important;
    align-items: center !important;
}
.col-form-label{
    display: flex;
    align-items: center;
}
</style>
