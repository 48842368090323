<template>
    <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">{{L('AccountSettings.Label.ChangePassword')}}</h3>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
            <div class="card-body">
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('AccountSettings.Label.NewPassword')}}</label>
                    <div class="col-lg-9 col-xl-6">
                        <input type="password" class="form-control form-control-lg form-control-solid" v-model="newPassword"/>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('AccountSettings.Label.NewPassword2')}}</label>
                    <div class="col-lg-9 col-xl-6">
                        <input type="password" class="form-control form-control-lg form-control-solid" v-model="newPassword2" @keypress="isPasswordValid = true"/>
                        <label v-if="!isPasswordValid" class="text-danger float-left">{{L('AccountSettings.Error.Password')}}</label>
                    </div>
                </div>
                <div class="form-group row">
                     <label class="col-xl-3 col-lg-3 col-form-label"></label>
                    <div class="col-lg-9 col-xl-6">
                        <button type="button" class="btn btn-success float-left" @click="changePassword">{{L('AccountSettings.Button.Save')}}</button>
                    </div>
                </div>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>

<script lang="ts">
import { User } from '@/entities/User';
import { localize } from '@/common/localize-service';
import { isNullOrEmpty } from '@/common/validations';
import service from '@/common/service';
import { CfAlertError, CfAlertSuccess } from '@/common/alerts';
import { ApiResponse } from '@/models/ApiResponse';
import { MembershipType } from '@/common/enums';
export default{
    props:{
        user : Object,
    },
    emits : ["updateUser"],
    data(){
        return{
            L : localize,
            busy : false,
            isPasswordValid : true,
            newPassword : "",
            newPassword2 : ""
        }
    },
    mounted(){
        
    },
    methods:{
        changePassword(){
            if(this.busy) return;
            if(!isNullOrEmpty(this.newPassword) || !isNullOrEmpty(this.newPassword2)){
                this.busy = true;
                if(this.newPassword != this.newPassword2){
                    this.isPasswordValid = false;
                    this.busy = false;
                    return;
                }

                service.getInstance().ChangePassword(this.newPassword)
                .then((response : ApiResponse) =>{
                    if(response.isSucceed){
                    this.$emit("updateUser",response.result);
                    this.newPassword = "";
                    this.newPassword2 = "";
                     if(navigator.cookieEnabled){
                            localStorage.setItem("user",JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                    CfAlertSuccess(response.message)
                }else{
                    CfAlertError(response.message);
                }
                })
                .catch(error =>{
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => this.busy = false);
            }
        }
    }
}
</script>