<template>
    <div class="card card-custom">
        <div class="card-header">
            <div class="card-title">
                <h4 class="card-label">Premium</h4>
            </div>
        </div>
        <div class="card-body">
            <div class="row justify-content-center text-center my-0 my-md-25">
                <div class="col-12 col-md-4 bg-white rounded shadow">
                    <div class="py-20 px-4">
                        <h4 class="text-dark mb-15">{{ L("PremiumPageTemelLbl") }}</h4>
                        <br />
                        <div class="text-dark mb-10 d-flex flex-column">
                            <span class="d-block">{{ L("PremiumPageIlanVermeLbl") }}</span>
                            <span class="d-block">{{ L("PremiumPageIlanVermeTemel") }}</span>
                            <div class="separator separator-solid my-3"></div>
                            <span class="d-block">{{ L("PremiumPageIlanCevaplamaLbl") }}</span>
                            <span class="d-block">{{ L("PremiumPageIlanCevapTemel") }}</span>
                            <div class="separator separator-solid my-3"></div>
                            <span class="d-block">{{ L("PremiumPageOzelMesajLbl") }}</span>
                            <span class="d-block">{{ L("PremiumPageOzelMesajTemel") }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 bg-primary my-md-n3 rounded shadow-lg">
                    <div class="py-20 px-4">
                        <h4 class="text-white mb-15">{{ L("PremiumPagePremiumLbl") }}</h4>
                        <br />
                        <div class="text-dark mb-10 d-flex flex-column">
                            <span class="d-block text-white">{{ L("PremiumPageIlanVermeLbl") }}</span>
                            <span class="d-block text-white">{{ L("PremiumPageIlanVermePre") }}</span>
                            <div class="separator separator-solid my-3"></div>
                            <span class="d-block text-white">{{ L("PremiumPageIlanCevaplamaLbl") }}</span>
                            <span class="d-block text-white">{{ L("PremiumPageIlanCevapPre") }}</span>
                            <div class="separator separator-solid my-3"></div>
                            <span class="d-block text-white">{{ L("PremiumPageOzelMesajLbl") }}</span>
                            <span class="d-block text-white">{{ L("PremiumPageOzelMesajPre") }}</span>
                        </div>
                        <a v-if="price" class="px-7 py-3 bg-white d-inline-flex flex-center rounded-lg bg-white">
                            <span class="pr-2 text-primary opacity-70">₺</span>
                            <span class="pr-2 font-size-h1 font-weight-bold text-primary">{{ price }}</span>
                            <span class="text-primary opacity-70">/&nbsp;&nbsp;yıllık</span>
                        </a>
                    </div>
                </div>
                <router-link :to="{ path: '/odeme',query : {type : 'Premium Üyeliğe geçiş', price : price,priceGroup:priceGroup} }" v-if="price" class="btn btn-primary btn-lg mt-5 col-12 col-md-6">
                    {{ L("AreaAdd.Premium.Btn") }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from "@/common/localize-service";
import service from "@/common/service";
import { MembershipType } from "@/common/enums";
export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            L: localize,
            price: null,
            priceGroup : "",
            userCheck: Number,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
        if (this.userCheck == 1 || this.userCheck == 2) {
            this.$router.push("/ayarlar");
        }
        if (this.user.membershipType == MembershipType["Premium Üyelik"]) {
            this.$router.push("/ayarlar");
        }
        service
            .getInstance()
            .GetCityGroup(this.user.city)
            .then((response) => {
                this.price = response.result.price;
                this.priceGroup = response.result.name;
            });
    },
};
</script>

<style></style>
