import Swal from "sweetalert2"

export function CfConfirm(title : string, message : string, success? : any, failed? : any){
    Swal.fire({
        title : title,
        text : message,
        confirmButtonText : 'Tamam',
        denyButtonText : 'Kapat',
        showDenyButton : true,
        reverseButtons : true,
    })
    .then((result) =>{
        if(result.isConfirmed){
            success();
        }
    })
}

export function CfAlertError(message : string,title? : string,btnTitle? : string){
    Swal.fire({
        icon: 'error',
        title: title? title : "Hata!",
        text: message,
        confirmButtonText : btnTitle? btnTitle : "Tamam",
    })
}

export function CfAlertWarning(message : string,title? : string, successBtn? : string){
    Swal.fire({
        icon: 'warning',
        title: title? title : "",
        text: message,
        confirmButtonText : successBtn? successBtn : 'Tamam',
    })
}

export function CfAlertSuccess(message : string,title? : string,btnTitle? : string){
    Swal.fire({
        icon: 'success',
        title: title? title : "",
        text: message,
        confirmButtonText : btnTitle? btnTitle : "Tamam"
    })
}