<template>
    <div v-if="userCheck == 1" class="content mt-5">
        <label class="col-12 text-center">{{ L("UserList.Text.Misafir") }}</label>
    </div>
    <div v-else-if="userCheck == 2" class="content mt-5 container">
        <label>{{ L("CreateAd.Error.Hatali") }}</label>
        <router-link to="/ayarlar" class="btn btn-primary font-weight-bold mr-2 d-block w-100 mt-3">{{ L("CreateAd.Hatali.AyarlarBtn") }}</router-link>
    </div>
    <!--begin::Content-->
    <div v-else class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <!--begin::Subheader-->
        <div class="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <!--begin::Details-->
                <div class="d-flex align-items-center flex-wrap mr-2">
                    <!--begin::Title-->
                    <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">Avukatlar</h5>
                    <!--end::Title-->
                    <!--begin::Separator-->
                    <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                    <!--end::Separator-->
                    <!--begin::Search Form-->
                    <div class="d-flex align-items-center" id="kt_subheader_search">
                        <span class="text-dark-50 font-weight-bold" id="kt_subheader_total">{{ count }}</span>
                        <div class="ml-5">
                            <select class="form-select form-group m-0" v-model="selectedCity" @change="cityChange">
                                <option v-for="city in cityList" :key="city.name">{{ city.name }}</option>
                            </select>
                        </div>
                    </div>
                    <!--end::Search Form-->
                </div>
            <div>
                <button class="btn btn-light-primary text-center" @click="pagination(parseInt(page)-1)" :disabled="page == 1"><i class="fas fa-chevron-left"></i></button>
                <span class="mx-4">{{page}}</span>
                <button class="btn btn-light-primary text-center" @click="pagination(parseInt(page)+1)" :disabled="page >= max"><i class="fas fa-chevron-right"></i></button>
            </div>
                <!--end::Details-->
            </div>
        </div>
        <!--end::Subheader-->
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
                <!--begin::Row-->
                <div class="row">
                    <!--begin::Col-->
                    <div v-for="lawyer in userList" :key="lawyer.userNo" class="col-6 col-md-4 col-lg-3 col-xl-2">
                        <!--begin::Card-->
                        <a href="javascript:;" @click="getUser(lawyer)" class="card card-custom gutter-b card-stretch">
                            <!--begin::Body-->
                            <div class="card-body text-center pt-4">
                                <!--begin::User-->
                                <div class="mt-7">
                                    <div class="text-center">
                                        <div class="card-photo mb-2" :style="{ backgroundImage: 'url(' + imagePath + lawyer.photo + ')' }"></div>
                                    </div>
                                </div>
                                <!--end::User-->
                                <!--begin::Name-->
                                <div class="my-2">
                                    <label v-if="lawyer.userNo == user.userNo" class="text-dark font-weight-bold font-size-h4">{{ lawyer.district }} <span class="text-danger">(Siz)</span></label>
                                    <label v-else class="text-dark font-weight-bold font-size-h4">{{ lawyer.district }}</label>
                                </div>
                                <!--end::Name-->
                            </div>
                            <!--end::Body-->
                        </a>
                        <!--end::Card-->
                    </div>
                    <!--end::Col-->
                </div>
                <!--end::Row-->
                <!--begin::Row-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
    <!--end::Content-->
    <transition name="modal">
        <div v-if="showModal" class="modal-mask">
            <div class="modal-wrapper d-flex justify-content-center align-items-center h-100">
                <div class="card card-custom col-11 col-lg-6">
                    <!--begin::Body-->
                    <div class="card-body pt-4 d-flex flex-column justify-content-between">
                        <!--begin::Toolbar-->
                        <div class="d-flex justify-content-end">
                            <div class="dropdown dropdown-inline">
                                <button class="btn btn-clean btn-hover-light-primary btn-sm btn-icon" @click="closeModal">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                        <!--end::Toolbar-->
                        <!--begin::User-->
                        <div class="d-flex align-items-center mb-7">
                            <!--begin::Pic-->
                            <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                <div class="symbol symbol-lg-75">
                                    <img alt="Pic" :src="imagePath + lawyer.photo" style="max-height: 100px; object-fit: cover" />
                                </div>
                            </div>
                            <!--end::Pic-->
                            <!--begin::Title-->
                            <div class="d-flex flex-column">
                                <span class="text-dark font-weight-bold font-size-h4 text-left">{{ lawyer.userNo }}</span>
                                <span class="text-dark font-weight-bold font-size-h4 text-left">{{ lawyer.city }} / {{ lawyer.district }}</span>
                                <span class="text-muted font-weight-bold text-left">Kayıt Tarihi : {{ dateString(lawyer.creationDate) }}</span>
                            </div>
                            <!--end::Title-->
                        </div>
                        <!--end::User-->
                        <div class="row mb-4" v-if="lawyer.receiveMessage && user.canPrivateMessage">
                            <div class="col-6">
                                <button type="button" @click="state = 1" class="btn w-100" :class="state == 1 ? 'btn-success' : 'btn-secondary'">{{ L("UserDetailProfileBtn") }}</button>
                            </div>
                            <div class="col-6">
                                <button type="button" @click="state = 2" class="btn w-100" :class="state == 2 ? 'btn-success' : 'btn-secondary'">{{ L("UserDetailMessageBtn") }}</button>
                            </div>
                        </div>

                        <!--begin::Info-->
                        <div class="mb-6" v-if="state == 1">
                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                                <span class="text-dark-75 font-weight-bolder mr-2 dot text-left mobile-w-100">{{ L("UserDetailExperienceLbl") }}</span>
                                <span href="#" class="text-muted text-lg-right text-left mobile-w-100">{{ new Date(Date.now()).getFullYear() - lawyer.experience }} yıl</span>
                            </div>
                            <div class="d-flex justify-content-between align-items-cente my-1 flex-wrap">
                                <span class="text-dark-75 font-weight-bolder mr-2 dot text-left mobile-w-100">{{ L("UserDetailWorkingTypeLbl") }}</span>
                                <span href="#" class="text-muted text-lg-right text-left mobile-w-100">{{ workingType[lawyer.workingType] }}</span>
                            </div>
                            <div class="d-flex justify-content-between align-items-cente my-1 flex-wrap">
                                <span class="text-dark-75 font-weight-bolder mr-2 dot text-left mobile-w-100">{{ L("UserDetailPurposeLbl") }}</span>
                                <span href="#" class="text-muted text-lg-right text-left mobile-w-100">{{ purpose[lawyer.purpose] }}</span>
                            </div>
                            <div class="d-flex justify-content-between align-items-cente my-1 flex-wrap">
                                <span class="text-dark-75 font-weight-bolder mr-2 dot text-left mobile-w-100">{{ L("UserDetailAdliyelerLbl") }}</span>
                                <span href="#" class="text-muted text-lg-right text-left mobile-w-100">{{ lawyer.adliyeler }}</span>
                            </div>

                            <p class="text-left mt-4 mb-0 p-0"><i class="fas fa-star-of-life text-danger mr-2"></i>{{ L("UserDetailBottomText") }}</p>
                        </div>

                        <div v-if="state == 2">
                            <span class="text-left d-block">Mesajınız:</span>
                            <textarea class="form-control mb-4" style="resize: none; height: 100px" v-model="message"></textarea>
                            <button @click="SendMessage" class="btn btn-block btn-sm btn-light-danger font-weight-bolder text-uppercase py-4">{{ L("UserDetailSendBtn") }}</button>
                        </div>
                        <!--end::Info-->
                    </div>
                    <!--end::Body-->
                </div>
            </div>
        </div>
    </transition>

    <div class="loading" v-if="busy"></div>
</template>

<script lang="ts">
import service from "@/common/service";
import { ApiResponse } from "@/models/ApiResponse";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";
import { MembershipType, Purpose, WorkingType } from "@/common/enums";
import { localize } from "@/common/localize-service";
import { isNullOrEmpty } from "@/common/validations";
import { SendMessageModel } from "@/models/SendMessageModel";
import { Variables } from "@/common/variables";
import { localeSort } from "@/common/locale-sort";
import { dateString } from '@/common/date-format';

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            userList: [],
            cityList: [],
            selectedCity: "",
            showModal: false,
            lawyer: Object,
            workingType: WorkingType,
            purpose: Purpose,
            L: localize,
            state: 1,
            message: "",
            busy: false,
            imagePath: Variables.ImagePath,
            userCheck: Number,
            count : 0,
            page : 1,
            max : 1,
            dateString : dateString
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
        
        if (this.userCheck != 1 && this.userCheck != 2 && this.userCheck != 3 && this.userCheck != 5) {
            this.selectedCity = this.user.city;
            service
                .getInstance()
                .Getusers(this.user.city, this.user.group,this.page)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        var item = response.result.userList.find((c) => c.userNo == this.user.userNo);
                        if (item) item.experience = -1;
                        this.count = response.result.count;
                        this.max = Math.ceil(this.count/100);
                        this.userList = response.result.userList.sort((a, b) => {
                            return a.experience - b.experience;
                        });
                    }
                })
                .catch((error) => {})
                .finally(() => {
                    this.busy = false;
                });

            service
                .getInstance()
                .GetCities()
                .then((response) => {
                    this.cityList = response.result.sort(localeSort);
                });

            this.selectedCity = this.user.city;
        }
    },
    methods: {
        cityChange() {
            this.page = 1;
            service
                .getInstance()
                .Getusers(this.selectedCity, this.user.group,this.page)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        var item = response.result.userList.find((c) => c.userNo == this.user.userNo);
                        this.count = response.result.count;
                        this.max = Math.ceil(this.count/100);
                        if (item) item.experience = -1;
                        this.userList = response.result.userList.sort((a, b) => {
                            return a.experience - b.experience;
                        });
                    }
                })
                .catch((error) => {})
                .finally(() => {});
        },
        pagination(page1){
            this.page = page1;
             service
                .getInstance()
                .Getusers(this.selectedCity, this.user.group,page1)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        var item = response.result.userList.find((c) => c.userNo == this.user.userNo);
                        this.count = response.result.count;
                        this.max = Math.ceil(this.count/100);
                        if (item) item.experience = -1;
                        this.userList = response.result.userList.sort((a, b) => {
                            return a.experience - b.experience;
                        });
                    }
                })
                .catch((error) => {})
                .finally(() => {});
        },
        getUser(item) {
            if (this.busy) return;
            this.busy = true;
            this.message = "";

            service
                .getInstance()
                .GetUser("?id=" + item.id)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.lawyer = response.result;
                        this.showModal = true;
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        SendMessage() {
            if (this.busy) return;
            this.busy = true;

            if (this.userCheck == 1) {
                CfAlertError(localize("Message.Error.Misafir"));
                this.busy = false;
                return;
            } else if (this.userCheck == 4 || this.userCheck == 5) {
                CfAlertError(localize("Message.Error.Temel"));
                this.busy = false;
                return;
            } else if (this.userCheck == 2) {
                CfAlertError(localize("Message.Error.Hatali"));
                this.busy = false;
                return;
            } else if (this.userCheck == 3) {
                CfAlertError(localize("Message.Error.Iptal"));
                this.busy = false;
                return;
            }

            var messageModel = new SendMessageModel();
            messageModel.message = this.message;
            messageModel.receiverId = this.lawyer.id;

            if (isNullOrEmpty(messageModel.message)) {
                this.busy = false;
                return;
            }

            service
                .getInstance()
                .SendMessage(messageModel)
                .then((response: ApiResponse) => {
                    if (response.isSucceed == true) {
                        this.message = "";
                        this.busy = false;
                        CfAlertSuccess("Mesajınız iletildi!");
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        closeModal() {
            this.showModal = false;
            this.state = 1;
        },
    },
};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.card.card-custom > .card-body {
    padding: 1rem 2.25rem;
}
</style>
