<template>
    <!--begin::Content-->
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <!--begin::Subheader-->
        <div class="subheader py-3 py-lg-8 subheader-transparent" id="kt_subheader">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <!--begin::Info-->
                <div class="d-flex align-items-center mr-1">
                    <!--begin::Page Heading-->
                    <div class="d-flex align-items-baseline flex-wrap mr-5">
                        <!--begin::Page Title-->
                        <h2 class="d-flex align-items-center text-dark font-weight-bold my-1 mr-3">{{ L("Contact.Text.Title") }}</h2>
                        <!--end::Page Title-->
                    </div>
                    <!--end::Page Heading-->
                </div>
                <!--end::Info-->
            </div>
        </div>
        <!--end::Subheader-->
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
                <div class="card card-custom gutter-b py-5">
                    <!--begin::Form-->
                    <form class="form">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-3"></div>
                                <div class="col-xl-6">
                                    <div class="row">
                                        <!--begin::Input-->
                                        <div class="form-group col-6">
                                            <label>{{ L("Contact.Label.Name") }}</label>
                                            <input type="text" class="form-control form-control-solid form-control-lg text-center" v-model="model.FirstName" />
                                        </div>
                                        <!--end::Input-->
                                        <!--begin::Input-->
                                        <div class="form-group col-6">
                                            <label>{{ L("Contact.Label.Surname") }}</label>
                                            <input type="text" class="form-control form-control-solid form-control-lg text-center" v-model="model.LastName" />
                                        </div>
                                        <!--end::Input-->
                                    </div>
                                    <!--begin::Input-->
                                    <div class="form-group">
                                        <label>{{ L("Contact.Label.Phone") }}</label>
                                        <input type="text" v-mask="'(###) ### ####'" class="form-control form-control-solid form-control-lg text-center" v-model="model.Phone" />
                                    </div>
                                    <!--end::Input-->
                                    <!--begin::Input-->
                                    <div class="form-group">
                                        <label>{{ L("Contact.Label.Email") }}</label>
                                        <input type="email" class="form-control form-control-solid form-control-lg text-center" v-model="model.Email" />
                                    </div>
                                    <!--end::Input-->
                                    <!--begin::Input-->
                                    <div class="form-group">
                                        <label>{{ L("Contact.Label.Subject") }}</label>
                                        <input type="text" class="form-control form-control-solid form-control-lg" v-model="model.Subject" @keypress="isSubjectValid = true" />
                                        <label v-if="!isSubjectValid" class="text-danger">{{ L("Contact.Error.Subject") }}</label>
                                    </div>
                                    <!--end::Input-->
                                    <!--begin::Input-->
                                    <div class="form-group">
                                        <label for="exampleTextarea">{{ L("Contact.Label.Message") }}</label>
                                        <textarea class="form-control form-control-solid form-control-lg" v-model="model.Message" @keypress="isMessageValid = true"></textarea>
                                        <label v-if="!isMessageValid" class="text-danger">{{ L("Contact.Error.Message") }}</label>
                                    </div>
                                    <!--end::Input-->
                                    <button type="button" @click="sendForm" class="btn btn-primary font-weight-bold mr-2 d-block w-100 mt-5">{{ L("Contact.Button.Send") }}</button>
                                </div>
                                <div class="col-xl-3"></div>
                            </div>
                        </div>
                    </form>
                    <!--end::Form-->
                </div>
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
    <!--end::Content-->
    <div class="loading" v-if="busy"></div>
</template>

<script lang="ts">
import { localize } from "@/common/localize-service";
import { User } from "@/entities/User";
import { ContactModel } from "@/models/ContactModel";
import { isNullOrEmpty } from "@/common/validations";
import service from "@/common/service";
import { ApiResponse } from "@/models/ApiResponse";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";
import { MembershipType } from "@/common/enums";

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            L: localize,
            model: new ContactModel(),
            busy: false,
            isSubjectValid: true,
            isMessageValid: true,
        };
    },
    mounted() {
        
        this.model.FirstName = this.user.firstName;
        this.model.LastName = this.user.lastName;
        this.model.Phone = this.user.phoneNumber;
        this.model.Email = this.user.email;
    },
    methods: {
        sendForm() {
            if (this.busy) return;
            this.busy = true;
            var isValid = true;

            if (isNullOrEmpty(this.model.Subject)) {
                this.isSubjectValid = false;
                isValid = false;
            }
            if (isNullOrEmpty(this.model.Message)) {
                this.isMessageValid = false;
                isValid = false;
            }
            if (!isValid) return;

            service
                .getInstance()
                .Contact(this.model)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.model = new ContactModel();
                        CfAlertSuccess(localize("Contact.Success.Message"));
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
    },
};
</script>
