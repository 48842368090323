
import { User } from '@/entities/User';
import service from '@/common/service';
import { localize } from '@/common/localize-service';
import { ChangeCityModel } from '@/models/ChangeCityModel';
import { ApiResponse } from '@/models/ApiResponse';
import { CfAlertError, CfAlertSuccess } from '@/common/alerts';
import { isNullOrEmpty } from '@/common/validations';
import { MembershipType } from '@/common/enums';
import { localeSort} from "@/common/locale-sort";

export default{
    props:{
        user : Object,
    },
    emits : ["updateUser"],
    data(){
        return{
            cities : [],
            areas : [],
            test : String,
            L : localize,
            busy : false,
            model : new ChangeCityModel(),
        }
    },
    mounted(){
        
        service.getInstance().GetCities()
        .then((response) =>{
            this.cities = response.result.sort(localeSort);
        })
    },
    methods:{
        getAreas(){
            this.model.District = "";
            service.getInstance().GetAreas("?cityName=" + this.model.City)
            .then((response)=>{
                this.areas = response.result;
            })
        },
        changeCity(){
            if(this.busy) return;
            this.busy = true;

            if(isNullOrEmpty(this.model.City) || isNullOrEmpty(this.model.District)){
                this.busy = false;
                return;
            }

            if(!isNullOrEmpty(this.user.changedCity) && this.model.City != this.user.city){
                CfAlertError(localize("CityChange.Error.ChangedBefore"),localize("CityChange.Error.ChangedBefore.Title"),localize("CityChange.Error.ChangedBefore.Button"));
                this.busy = false;
                return;
            }

            if(this.model.City == this.user.city && this.model.District == this.user.district){
                CfAlertError(localize("CityChange.Error.NoChange"),localize("CityChange.Error.NoChange.Title"),localize("CityChange.Error.NoChange.Button"))
                this.busy = false;
                return;
            }

            service.getInstance().ChangeCity(this.model)
            .then((response : ApiResponse) =>{
                if(response.isSucceed){
                    this.$emit("updateUser",response.result);
                    this.model = new ChangeCityModel();
                     if(navigator.cookieEnabled){
                            localStorage.setItem("user",JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                    CfAlertSuccess(response.message);
                }else{
                    CfAlertError(response.message);
                }
            })
            .catch(error => {
                service.getInstance().ErrorMessage(error);
            })
            .finally(()=> this.busy = false);
        }
    }
}
