<template>
    <!--begin: Wizard Step 1-->
    <div class="pb-5">
        <!--begin::Ad-->
        <div class="form-group">
            <label>{{ L("Register1.Label.Name") }}</label>
            <input type="text" v-bind:class="isNameValid ? 'form-control form-control-solid form-control-lg text-center' : 'form-control form-control-solid form-control-lg is-invalid text-center'" v-model="model.Name" v-on:keypress="isNameValid = true" />
            <span v-bind:class="isNameValid ? 'form-text text-danger d-none' : 'form-text text-danger'">{{ L("Register1.Error.Name") }}</span>
        </div>
        <!--end::Ad-->
        <!--begin::Soyad-->
        <div class="form-group">
            <label>{{ L("Register1.Label.Surname") }}</label>
            <input type="text" v-bind:class="isLastNameValid ? 'form-control form-control-solid form-control-lg text-center' : 'form-control form-control-solid form-control-lg is-invalid text-center'" v-model="model.Surname" v-on:keypress="isLastNameValid = true" />
            <span v-bind:class="isLastNameValid ? 'form-text text-danger d-none' : 'form-text text-danger'"> {{ L("Register1.Error.Surname") }}</span>
        </div>
        <!--end::Soyad-->
        <!--begin::Şehir-->
        <div class="form-group fv-plugins-icon-container">
            <label>{{ L("Register1.Label.City") }}</label>
            <select v-model="model.City" v-bind:class="isCityValid ? 'form-select form-control form-control-solid form-control-lg' : 'form-select form-control form-control-solid form-control-lg is-invalid'" v-on:change="cityChange">
                <option v-for="city in cities" :key="city.name">{{ city.name }}</option>
            </select>
            <span v-bind:class="isCityValid ? 'form-text text-danger d-none' : 'form-text text-danger'"> {{ L("Register1.Error.City") }}</span>
        </div>
        <!--end::Şehir-->
        <!--begin::Baro-->
        <div class="form-group">
            <label>{{ L("Register1.Label.Baro") }}</label>
            <select v-model="model.Baro" v-bind:class="isBaroValid ? 'form-select form-control form-control-solid form-control-lg' : 'form-select form-control form-control-solid form-control-lg is-invalid'" v-on:change="isBaroValid = true">
                <option v-for="city in barolar" :key="city.name">{{ city.name }}</option>
            </select>
            <span v-bind:class="isBaroValid ? 'form-text text-danger d-none' : 'form-text text-danger'">{{ L("Register1.Error.Baro") }}</span>
        </div>
        <!--end::Baro-->
        <!--begin::Sicil No-->
        <div class="form-group">
            <label>{{ L("Register1.Label.Sicil") }}</label>
            <input type="text" v-bind:class="isSicilValid ? 'form-control form-control-solid form-control-lg text-center' : 'form-control form-control-solid form-control-lg is-invalid text-center'" v-model="model.SicilNo" v-mask="'#####'" v-on:keypress="isSicilValid = true" />
            <span v-bind:class="isSicilValid ? 'form-text text-danger d-none' : 'form-text text-danger'">{{ registeredSicil ? L("Register1.Error.RegisteredSicil") : L("Register1.Error.Sicil") }}</span>
        </div>
        <!--end::Sicil No-->
    </div>
    <!--end: Wizard Step 1-->
    <!--begin: Wizard Actions-->
    <div class="d-flex justify-content-between">
        <div class="mr-2"></div>
        <div>
            <button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" v-on:click="nextPage">
                {{ L("Register1.Button.Continue") }}
            </button>
        </div>
    </div>
    <!--end: Wizard Actions-->
</template>

<script>
import { localize } from "@/common/localize-service";
import service from "@/common/service";
import { RegisterModel } from "@/models/RegisterModel";
import { isNameValid, isNullOrEmpty, isNumber } from "@/common/validations";

export default {
    props: {
        model: RegisterModel,
        cities: Array,
    },
    data() {
        return {
            isNameValid: Boolean,
            isLastNameValid: Boolean,
            isCityValid: Boolean,
            isBaroValid: Boolean,
            isSicilValid: Boolean,
            registeredSicil: Boolean,
        };
    },
    mounted() {
        this.registeredSicil = false;
    },
    computed:{
        barolar(){
            var y = this.cities.map((x) => x);
            y.push({name : "İstanbul 2"});
            return y;
        }
    },
    methods: {
        nextPage: function () {
            let isValid = true;
            if (!isNameValid(this.model.Name)) {
                this.isNameValid = false;
                isValid = false;
            }
            if (!isNameValid(this.model.Surname)) {
                this.isLastNameValid = false;
                isValid = false;
            }
            if (isNullOrEmpty(this.model.City)) {
                this.isCityValid = false;
                isValid = false;
            }
            if (isNullOrEmpty(this.model.Baro)) {
                this.isBaroValid = false;
                isValid = false;
            }
            if (isNullOrEmpty(this.model.SicilNo) || this.model.SicilNo.length > 5 || !isNumber(this.model.SicilNo)) {
                this.isSicilValid = false;
                this.registeredSicil = false;
                isValid = false;
            }
            service
                .getInstance()
                .CheckSicilNo(`?sicilNo=${this.model.SicilNo}&baro=${encodeURI(this.model.Baro)}`)
                .then((response) => {
                    if (response) {
                        this.registeredSicil = true;
                        this.isSicilValid = false;
                        this.isValid = false;
                    } else {
                        if (isValid) {
                            this.$parent.page++;
                        }
                    }
                });
        },
        cityChange: function () {
            this.isCityValid = true;
            this.$parent.GetAreas();
        },
        L: localize,
    },
};
</script>
