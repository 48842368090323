import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Main from "../views/Main.vue";
import AdList from "../views/AdList.vue";
import MyAdverts from "../views/MyAdverts.vue";
import CreateAd from "../views/CreateAd.vue";
import Settings from "../views/Settings.vue";
import Contact from "../views/Contact.vue";
import Register from "../views/Register.vue";
import Answer from "../views/Answer.vue";
import UserList from "../views/UserList.vue";
import Advert from "../views/Advert.vue";
import ProfileSettings from "../views/Settings/ProfileSettings.vue";
import AccountSettings from "../views/Settings/AccountSettings.vue";
import AreaSettings from "../views/Settings/AreaSettings.vue";
import CancelMembership from "../views/Settings/CancelMembership.vue";
import ChangeBaro from "../views/Settings/ChangeBaro.vue";
import ChangeCity from "../views/Settings/ChangeCity.vue";
import ChangeEmail from "../views/Settings/ChangeEmail.vue";
import ChangePassword from "../views/Settings/ChangePassword.vue";
import ChangePhone from "../views/Settings/ChangePhone.vue";
import Premium from "../views/Settings/Premium.vue";
import Payment from "../views/Payment.vue";
import Messages from "../views/Messages.vue";
import Payment2 from "@/views/Payment2.vue";
import Expired from "../views/Expired.vue";
import SSS from "../views/SSS.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/index.html",
    redirect: "/ilanlar"
  },
  {
    path: "/",
    redirect: "/ilanlar"
  },
  {
    name: "Expired",
    path: "/expired",
    component: Expired
  },
  {
    path: "/login",
    component: Home
  },
  {
    path: "/register",
    component: Register
  },
  {
    path: "/",
    name: "Main",
    component: Main,
    children: [
      {
        path: "ayarlar",
        name: "Settings",
        component: Settings,
        children: [
          {
            path: "",
            name: "ProfileSettings",
            component: ProfileSettings
          },
          {
            path: "hesap-ayarlari",
            name: "AccountSettings",
            component: AccountSettings
          },
          {
            path: "bolge-ayarlari",
            name: "AreaSettings",
            component: AreaSettings
          },
          {
            path: "uyelik-iptal",
            name: "CancelMembership",
            component: CancelMembership
          },
          {
            path: "baro-ayarlari",
            name: "ChangeBaro",
            component: ChangeBaro
          },
          {
            path: "sehir-ayarlari",
            name: "ChangeCity",
            component: ChangeCity
          },
          {
            path: "eposta-ayarlari",
            name: "ChangeEmail",
            component: ChangeEmail
          },
          {
            path: "sifre-ayarlari",
            name: "ChangePassword",
            component: ChangePassword
          },
          {
            path: "telefon-ayarlari",
            name: "ChangePhone",
            component: ChangePhone
          },
          {
            path: "premium",
            name: "Premium",
            component: Premium
          },
        ]
      },
      {
        path: "avukatlar",
        name: "UserList",
        component: UserList,
      },
      {
        path: "sss",
        name: "SSS",
        component: SSS,
      },
      {
        path: "ilanlar",
        name: "AdList",
        component: AdList,
      },
      {
        path: "ilanlarim",
        name: "MyAdverts",
        component: MyAdverts,
      },
      {
        path: "yeni-ilan",
        name: "CreateAd",
        component: CreateAd,
      },
      {
        path: "iletisim",
        name: "Contact",
        component: Contact,
      },
      {
        path: "ilanlar/:id",
        name: "Answer",
        component: Answer,
        props: true,
      },
      {
        path: "ilan-detay/:id",
        name: "Detail",
        component: Advert,
        props: true,
      },
      {
        path: "odeme",
        name: "Payment",
        component: Payment
      },
      {
        path: "mesajlar",
        name: "Messages",
        component: Messages,
      },
      // {
      //   path: "odeme2",
      //   name: "Payment2",
      //   component: Payment2,
      // },
    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});


export default router;
