<template>
    <div>
        <div class="pb-5">
            <div class="py-2 text-center">
                <img src="../../assets/images/hand-shake.png" />
                <p class="text-center pt-3">
                    {{ L("Register2.Text.Text1") }}
                </p>
            </div>

            <div class="py-2 text-center">
                <img src="../../assets/images/high-quality.png" />
                <p class="text-center pt-3">
                    {{ L("Register2.Text.Text2") }}
                </p>
            </div>

            <div class="py-2">
                <div class="form-check text-left d-flex align-items-center">
                    <input class="form-check-input" type="checkbox" v-model="model.Okudum" v-on:change="isAgreed = true" />
                    <span class="form-check-label" style="cursor: pointer" @click="showModal">
                        {{ L("Register2.Text.Agreement", model.SicilNo, model.Baro) }}
                    </span>
                </div>
                <span v-bind:class="isAgreed ? 'form-text text-danger d-none' : 'form-text text-danger'">{{ L("Register2.Error.Agreement") }}</span>
            </div>
        </div>
        <!--begin: Wizard Actions-->
        <div class="d-flex justify-content-between border-top mt-5 pt-10">
            <div class="mr-2">
                <button type="button" class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4" v-on:click="previousPage">
                    {{ L("Register2.Button.Back") }}
                </button>
            </div>
            <div>
                <button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" v-on:click="nextPage">
                    {{ L("Register2.Button.Continue") }}
                </button>
            </div>
        </div>
        <!--end: Wizard Actions-->
        <Clarification v-if="clarification"></Clarification>
    </div>
</template>

<script>
import Clarification from "./Clarification.vue";
import { RegisterModel } from "@/models/RegisterModel";
import { localize } from "@/common/localize-service";

export default {
    props: {
        model: RegisterModel,
    },
    components: {
        Clarification,
    },
    data() {
        return {
            isAgreed: Boolean,
            clarification: Boolean,
        };
    },
    mounted() {
        this.isAgreed = true;
        this.clarification = false;
    },
    methods: {
        nextPage: function () {
            let isValid = true;
            if (!this.model.Okudum) {
                this.isAgreed = false;
                isValid = false;
            }
            if (isValid) {
                this.$parent.page++;
            }
        },
        previousPage: function () {
            this.$parent.page--;
        },
        showModal: function () {
            this.clarification = true;
        },
        closeModal: function () {
            this.clarification = false;
        },
        L: localize,
    },
};
</script>
