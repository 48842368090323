
import { localize } from "@/common/localize-service";
import { isNullOrEmpty } from "@/common/validations";
import service from '@/common/service';
import { ApiResponse } from '@/models/ApiResponse';
import { CfAlertError, CfAlertSuccess } from '@/common/alerts';
class PaymentModel {
    Name: string;
    PaymentName: string;
    Type: string;
    Price: number;
    Description: string;
    Bank: string;
    Date: Date;
    Message: string;
    CreditCardLink :string;
}
export default {
    props: {
        user: Object,
    },
    data() {
        return {
            busy: false,
            L: localize,
            model: new PaymentModel(),
            userCheck : Number,
        };
    },
    mounted() {
        this.model.Price = this.$route.query.price;
        this.model.Message = this.$route.query.cities;
        this.model.Type = this.$route.query.type;
        debugger;
        switch (this.$route.query.priceGroup?.toUpperCase()) {
            case "A":
            this.model.CreditCardLink = "https://www.paytr.com/link/qAu2coi"
                break;
            case "B":
            this.model.CreditCardLink = "https://www.paytr.com/link/nTkg9Pb"
                break;            
            case "C":
            this.model.CreditCardLink = "https://www.paytr.com/link/oWVDh8w"
                break; 
            case "D":
            this.model.CreditCardLink = "https://www.paytr.com/link/WVfjGuu"
                break; 
            case "E":
            this.model.CreditCardLink = "https://www.paytr.com/link/kozntGi"
                break; 
            default:
                break;
        }
        this.userCheck = service.getInstance().TypeControl(this.user);
        if(this.userCheck == 1 || this.userCheck == 2){
            this.$router.push("/ilanlar")
        }if(this.userCheck == 3 || this.userCheck == 5){
            this.$router.push({name : "Expired"});
        }
        this.model.Name = this.user.firstName + " " + this.user.lastName;
        this.model.PaymentName = this.user.firstName + " " + this.user.lastName;
        this.model.Description = this.user.userNo + "(Açıklama kısmına SADECE üye numaranızı yazınız)";
    },
    methods: {
        send() {
            if (isNullOrEmpty(this.model.Name) || isNullOrEmpty(this.model.PaymentName) || isNullOrEmpty(this.model.Type) || isNullOrEmpty(this.model.Description) || isNullOrEmpty(this.model.Bank) || isNullOrEmpty(this.model.Date) || this.model.Price == null) return;
            service.getInstance().Payment(this.model)
            .then((response : ApiResponse)=>{
                console.log(response);
                if(response.isSucceed){
                    CfAlertSuccess(response.message);
                    this.model = new PaymentModel();
                }else{
                    CfAlertError(response.message);
                }
            })
        },
    },
};
