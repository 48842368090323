<template>
    <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">{{L('BaroChange.Text.Title')}}</h3>
            </div>
            <div class="card-toolbar">
                <button type="button" class="btn btn-success mr-2" @click="changeBaro">{{L('BaroChange.Button.Save')}}</button>
            </div>
        </div>
        <!--end::Header-->
        
        <!--begin::Form-->
        <form class="form">
            <div class="card-body">
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('BaroChange.Label.OldBaro')}}</label>
                    <div class="col-lg-9 col-xl-9">
                        <span class="form-control form-control-lg text-left">{{user.baro}}</span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('BaroChange.Label.OldSicil')}}</label>
                    <div class="col-lg-9 col-xl-9">
                        <span class="form-control form-control-lg text-left">{{user.sicilNo}}</span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('BaroChange.Label.NewBaro')}}</label>
                    <div class="col-lg-9 col-xl-9">
                        <select class="form-control form-select form-control-lg form-control-solid" v-model="model.Baro">
                            <option v-for="city in cities" :key="city.name" :value="city.name">{{city.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('BaroChange.Label.NewSicil')}}</label>
                    <div class="col-lg-9 col-xl-9">
                        <input v-mask="'######'" class="form-control form-control-lg form-control-solid text-left" v-model="model.SicilNo"/>
                    </div>
                </div>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>

<script lang="ts">
import { User } from '@/entities/User';
import service from '@/common/service';
import { localize } from '@/common/localize-service';
import { ChangeBaroModel } from '@/models/ChangeBaroModel';
import { isNullOrEmpty, isNumber } from '@/common/validations';
import { CfAlertError, CfAlertSuccess } from '@/common/alerts';
import { ApiResponse } from '@/models/ApiResponse';
import { MembershipType } from '@/common/enums';
import { localeSort } from "@/common/locale-sort";

export default{
    props:{
        user : Object,
    },
    emits : ["updateUser"],
    data(){
        return{
            cities : [],
            L : localize,
            model : new ChangeBaroModel(),
            busy : false,
        }
    },
    mounted(){
        service.getInstance().GetCities()
        .then((response) =>{
            var x = response.result;
            x.push({name : "İstanbul 2"});
            this.cities = x.sort(localeSort);
        })
    },
    methods : {
        changeBaro(){
            if(this.busy) return;
            this.busy = true;
            if(isNullOrEmpty(this.model.Baro) || isNullOrEmpty(this.model.SicilNo)){
                this.busy = false;
                return;
            };

            if(!isNumber(this.model.SicilNo)){
                this.busy = false;
                return;
            };

            if(this.model.Baro == this.user.baro && this.model.SicilNo == this.user.sicilNo){
                this.busy = false;
                CfAlertError(localize("BaroChange.Error.NoChange"));
                return;
            };

            service.getInstance().ChangeBaro(this.model)
            .then((response : ApiResponse) => {
                if(response.isSucceed){
                    this.$emit("updateUser",response.result);
                    this.model = new ChangeBaroModel();
                     if(navigator.cookieEnabled){
                            localStorage.setItem("user",JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                    CfAlertSuccess(response.message);
                }else{
                    CfAlertError(response.message);
                }
            })
            .catch(error => {
                service.getInstance().ErrorMessage(error);
            })
            .finally(() => this.busy = false);
        }
    }
}
</script>