<template>
    <!--begin: Wizard Step 1-->
    <div class="pb-5">
        <div class="text-center">
            <img src="../../assets/images/hand-shake.png" />
            <h1>{{ L("Register5.Text.Lawyer", model.Name, model.Surname) }}</h1>
            <p class="text-center py-5">
                {{ L("Register5.Text.RegisterSuccess", model.Phone) }}
            </p>

            <p class="p-0 m-0">{{ L("Register5.Text.Bottom1") }}</p>
            <p class="p-0 m-0">{{ L("Register5.Text.Bottom2") }}</p>
            <p class="p-0 m-0">{{ L("Register5.Text.Bottom3") }}</p>
        </div>
    </div>
    <!--end: Wizard Step 1-->
    <!--begin: Wizard Actions-->
    <div class="d-flex justify-content-center border-top mt-5 pt-10">
        <div>
            <button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" v-on:click="nextPage">
                {{ L("Register5.Button.Continue") }}
            </button>
        </div>
    </div>
    <!--end: Wizard Actions-->
</template>

<script>
import service from "@/common/service";
import { localize } from "@/common/localize-service";
import { RegisterModel } from "@/models/RegisterModel";

export default {
    props: {
        model: RegisterModel,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        nextPage: function () {
            this.$router.push({ path: "/login" });
        },
        L: localize,
    },
};
</script>
