<template>
    <div class="content d-flex flex-column flex-column-fluid">
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
                <div class="card card-custom gutter-b">
                    <div class="card card-custom">
                        <!--begin::Header-->
                        <div class="card-header py-3">
                            <div class="card-title align-items-start flex-column">
                                <h3 class="card-label font-weight-bolder text-dark">Ödeme Bilgi ve Bildirimi Sayfası</h3>
                            </div>
                            <a v-if="model.CreditCardLink" :href="model.CreditCardLink" target="_blank" class="btn btn-primary">Kredi Kartı İle Öde</a>
                        </div>

                        <div class="card-body">
                            <p>İşleminizin tamamlanması için <b class="text-danger">[a]</b> ödeme tutarını aşağıdaki banka hesaplarına havale/eft yaptıktan <b style="text-decoration: underline !important">sonra</b>, <b class="text-danger">[b]</b> ödeme bildirim formunu doldurup gönder tuşuna basınız.<b class="text-danger">[c]</b> Ödemeniz hesabımıza ulaştığı andan itibaren dakikalar içerisinde işleminiz gerçekleşecektir.</p>
                            <p>
                                <i class="fas fa-star-of-life text-danger mr-2"></i>Ödeme işleminizin daha hızlı onaylanması için Havale / EFT yaparken AÇIKLAMA kısmına ÜYE NUMARANIZI (<b>{{ user.userNo }}</b
                                >) yazmanız yeterlidir.
                            </p>
                        </div>

                        <!--end::Header-->
                        <div class="card-body">
                            <div class="p-3 text-center" style="background: #f0f0f0">
                                <h4 class="text-primary">BANKA HESAP NUMARAMIZ</h4>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th><b>BANKA</b></th>
                                        <th><b>IBAN</b></th>
                                        <th><b>HESAP ADI</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><b>VAKIF BANK</b></td>
                                        <td>TR36 0001 5001 5800 7318 7657 91</td>
                                        <td>MURAT DURSUN</td>
                                    </tr>
                                    <tr>
                                        <td><b>İŞ BANK</b></td>
                                        <td>TR34 0006 4000 0013 4940 6500 67</td>
                                        <td>MURAT DURSUN</td>
                                    </tr>
                                    <tr>
                                        <td><b>GARANTİ BANK</b></td>
                                        <td>TR02 0006 2001 0400 0006 6271 81 </td>
                                        <td>MURAT DURSUN</td>
                                    </tr>
                                    <tr>
                                        <td><b>YAPI KREDİ</b></td>
                                        <td>TR30 0006 7010 0000 0031 1434 45</td>
                                        <td>MURAT DURSUN</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="card-body">
                            <div class="p-3 text-center" style="background: #f0f0f0">
                                <h4 class="text-primary">HAVALE / EFT ve KREDİ KARTI ÖDEME BİLDİRİM FORMU</h4>
                                <h6 class="text-danger">Ödemenizi havale/eft veya yukarıda yer alan kredi kartı ile öde linki ile yaptıktan sonra bu formu doldurup Gönder tuşuna basınız</h6>
                            </div>
                            <div class="mt-3">
                                <div class="form-group row align-items-center">
                                    <label class="col-md-3 col-12 dot m-0">Üye Adı Soyadı</label>
                                    <div class="col-md-9 col-12">
                                        <input class="form-control" v-model="model.Name" />
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-md-3 col-12 dot m-0">Ödeme yapan hesap Adı Soyadı</label>
                                    <div class="col-md-9 col-12">
                                        <input class="form-control" v-model="model.PaymentName" />
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-md-3 col-12 dot m-0">Ödeme Türü</label>
                                    <div class="col-md-9 col-12">
                                        <select class="form-control form-select" v-model="model.Type">
                                            <option>Premium Üyeliğe geçiş</option>
                                            <option>Şehir ekleme</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-md-3 col-12 dot m-0">Ödeme Tutarı</label>
                                    <div class="col-md-9 col-12">
                                        <input type="number" v-model="model.Price" class="form-control col-6" />
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-md-3 col-12 dot m-0">Ödeme Açıklaması</label>
                                    <div class="col-md-9 col-12">
                                        <input v-model="model.Description" class="form-control col-6" />
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-md-3 col-12 dot m-0">Hangi banka hesabımıza ödeme yaptınız?</label>
                                    <div class="col-md-9 col-12 d-flex">
                                        <div class="form-check form-check-inline d-flex">
                                            <input class="form-check-input" type="radio" v-model="model.Bank" value="Vakıfbank" />
                                            <label class="form-check-label">Vakıfbank</label>
                                        </div>
                                        <div class="form-check form-check-inline d-flex">
                                            <input class="form-check-input" type="radio" v-model="model.Bank" value="İşbank" />
                                            <label class="form-check-label">İşbank</label>
                                        </div>
                                        <div class="form-check form-check-inline d-flex">
                                            <input class="form-check-input" type="radio" v-model="model.Bank" value="Garanti" />
                                            <label class="form-check-label">Garanti</label>
                                        </div>
                                        <div class="form-check form-check-inline d-flex">
                                            <input class="form-check-input" type="radio" v-model="model.Bank" value="Yapı Kredi" />
                                            <label class="form-check-label">Yapı Kredi</label>
                                        </div>
                                        <div class="form-check form-check-inline d-flex">
                                            <input class="form-check-input" type="radio" v-model="model.Bank" value="PAYTR Kredi Kartı" />
                                            <label class="form-check-label">Kredi Kartı ile Ödeme</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-md-3 col-12 dot m-0">Ödeme Tarihi</label>
                                    <div class="col-md-9 col-12">
                                        <input v-model="model.Date" type="date" class="form-control" />
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-md-3 col-12 dot m-0">Bize iletmek istedikleriniz</label>
                                    <div class="col-md-9 col-12">
                                        <textarea v-model="model.Message" type="date" class="form-control" ></textarea>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="col-12 btn btn-success text-center mt-3" @click="send">Gonder</button>
                        </div>

                        <div class="card-body">
                            <label class="text-primary">ÜCRET İADESİ KOŞULLARI :</label>
                            <p class="text-muted">Üyelik ücreti; bölgenize ait ilanların tarafınıza SMS ile bildirilmesi, gelen ilanlara cevap verebilme, cevaplarınızın ilan sahiplerine iletilmesi hizmetlerini kapsamaktadır.</p>
                            <p class="text-muted">Yukarıda belirtilen hizmetlerin hiç kullanılmaması halinde ücret iadesi talebiniz yerine getirilir. Bu hizmetlerin bir kez dahi kullanılması halinde ücret iadesi yapılamaz.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { localize } from "@/common/localize-service";
import { isNullOrEmpty } from "@/common/validations";
import service from '@/common/service';
import { ApiResponse } from '@/models/ApiResponse';
import { CfAlertError, CfAlertSuccess } from '@/common/alerts';
class PaymentModel {
    Name: string;
    PaymentName: string;
    Type: string;
    Price: number;
    Description: string;
    Bank: string;
    Date: Date;
    Message: string;
    CreditCardLink :string;
}
export default {
    props: {
        user: Object,
    },
    data() {
        return {
            busy: false,
            L: localize,
            model: new PaymentModel(),
            userCheck : Number,
        };
    },
    mounted() {
        this.model.Price = this.$route.query.price;
        this.model.Message = this.$route.query.cities;
        this.model.Type = this.$route.query.type;
        debugger;
        switch (this.$route.query.priceGroup?.toUpperCase()) {
            case "A":
            this.model.CreditCardLink = "https://www.paytr.com/link/qAu2coi"
                break;
            case "B":
            this.model.CreditCardLink = "https://www.paytr.com/link/nTkg9Pb"
                break;            
            case "C":
            this.model.CreditCardLink = "https://www.paytr.com/link/oWVDh8w"
                break; 
            case "D":
            this.model.CreditCardLink = "https://www.paytr.com/link/WVfjGuu"
                break; 
            case "E":
            this.model.CreditCardLink = "https://www.paytr.com/link/kozntGi"
                break; 
            default:
                break;
        }
        this.userCheck = service.getInstance().TypeControl(this.user);
        if(this.userCheck == 1 || this.userCheck == 2){
            this.$router.push("/ilanlar")
        }if(this.userCheck == 3 || this.userCheck == 5){
            this.$router.push({name : "Expired"});
        }
        this.model.Name = this.user.firstName + " " + this.user.lastName;
        this.model.PaymentName = this.user.firstName + " " + this.user.lastName;
        this.model.Description = this.user.userNo + "(Açıklama kısmına SADECE üye numaranızı yazınız)";
    },
    methods: {
        send() {
            if (isNullOrEmpty(this.model.Name) || isNullOrEmpty(this.model.PaymentName) || isNullOrEmpty(this.model.Type) || isNullOrEmpty(this.model.Description) || isNullOrEmpty(this.model.Bank) || isNullOrEmpty(this.model.Date) || this.model.Price == null) return;
            service.getInstance().Payment(this.model)
            .then((response : ApiResponse)=>{
                console.log(response);
                if(response.isSucceed){
                    CfAlertSuccess(response.message);
                    this.model = new PaymentModel();
                }else{
                    CfAlertError(response.message);
                }
            })
        },
    },
};
</script>
