
import { User } from "@/entities/User";
import { MembershipType, Purpose, WorkingType } from "@/common/enums";
import { ProfileEditModel } from "@/models/ProfileEditModel";
import service from "@/common/service";
import { ApiResponse } from "@/models/ApiResponse";
import { CfAlertError, CfAlertSuccess, CfConfirm } from "@/common/alerts";
import { localize } from "@/common/localize-service";
import { Variables } from '@/common/variables';

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            workingTypes: [],
            purposes: [],
            model: new ProfileEditModel(),
            imageChanged: false,
            changedPhoto: "",
            L: localize,
            imagePath : Variables.ImagePath,
            userCheck : Number,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
        
        for (var i in WorkingType) {
            if (typeof WorkingType[i] === "number") {
                this.workingTypes.push({ value: WorkingType[i], display: i });
            }
        }

        for (var i in Purpose) {
            if (typeof Purpose[i] === "number") {
                this.purposes.push({ value: Purpose[i], display: i });
            }
        }
    },
    methods: {
        editProfile() {
            if (this.busy) return;
            this.busy = true;

            this.model.Purpose = this.user.purpose;
            this.model.WorkingType = this.user.workingType;
            this.model.Adliyeler = this.user.adliyeler;

            service
                .getInstance()
                .ProfileEdit(this.model)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.$emit("updateUser", response.result);
                        CfAlertSuccess(response.message);
                         if(navigator.cookieEnabled){
                            localStorage.setItem("user",JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        imageUpload(event) {
            this.busy = true;

            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                this.changedPhoto = reader.result;
                this.model.photo = reader.result.toString().split(",")[1];
                this.imageChanged = true;
            };
            this.busy = false;
        },
        imageDelete() {
            CfConfirm("Fotoğrafı sil", "Fotoğrafı silmek istediğinize emin misiniz?", () => {
                this.busy = true;
                service
                    .getInstance()
                    .RemovePhoto()
                    .then((response: ApiResponse) => {
                        if (response.isSucceed) {
                            switch (this.user.gender) {
                                case "Erkek":
                                    this.user.photo = "erkek.jpg";
                                    break;
                                case "Kadın":
                                    this.user.photo = "kadin.jpg";
                                    break;
                                default:
                                    this.user.photo = "default.jpg";
                                    break;
                            }
                            this.imageChanged = false;
                            this.$parent.user = response.result;
                            globalThis.user = response.result;
                            CfAlertSuccess(response.message);
                        } else {
                            CfAlertError(response.message);
                        }
                    })
                    .catch((error) => {})
                    .finally(() => (this.busy = false));
            });
        },
    },
};
