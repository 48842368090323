
import service from "../common/service";
import { AdvertStatus, HelpSubject, MembershipType } from "../common/enums";
import { dateString } from "../common/date-format";
import { isNullOrEmpty } from "../common/validations";
import { User } from "../entities/User";
import { localize } from "../common/localize-service";
import { Variables } from "@/common/variables";
// <img class="mb-2 mx-auto" style="max-width: 50px;max-height: 67px;border-radius: 5px;" alt="photo" v-bind:src="isNullOrEmpty(ad.photo) ? (ad.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + ad.photo" />

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            adList: [],
            helpSubject: HelpSubject,
            dateString: dateString,
            isNullOrEmpty: isNullOrEmpty,
            type: 1,
            L: localize,
            busy: false,
            imagePath: Variables.ImagePath,
            userCheck: Number,
            night : Date,
            areasText : "",
        };
    },
    mounted() {
        this.night = new Date(Date.now() - 1000 * 60 * 60 * 24);
        this.night.setHours(0);
        this.night.setMinutes(0);
        this.night.setSeconds(0);
        this.night.setMilliseconds(0);
        this.userCheck = service.getInstance().TypeControl(this.user);
        if (this.userCheck != 3 && this.userCheck != 5 && this.userCheck != 7) {
            this.GetList(this.type);
        }

        this.user.areas.filter(c => c.isActive).forEach(area =>{
            this.areasText += area.area + ", "
        });
        this.user.extraAreas.filter(c => c.isActive).forEach(area =>{
            this.areasText += area.area + ", "
        });
    },
    methods: {
        GetList(type) {
            this.busy = true;
            var serviceUrl = "Tevkil/MyAreasTevkilList";
            switch (type) {
                case 1: //bölgemdeki ilanlar
                    serviceUrl = "Tevkil/MyAreasTevkilList";
                    break;
                case 2: //diğer bölgedeki ilanlar
                    serviceUrl = "Tevkil/OtherAreasTevkilList";
                    break;
                case 3: //çözümlenmiş ilanlar
                    serviceUrl = "Tevkil/ArchiveTevkilList";
                    break;
                case 4: //cevap verdiğim ilanlar
                    serviceUrl = "Tevkil/MyAnswerTevkilList";
                    break;
            }

            service
                .getInstance()
                .GetAdvertList(serviceUrl)
                .then((response) => {
                    this.adList = response.result;
                })
                .catch((error) => {})
                .finally(() => (this.busy = false));
        },
        changeType(type) {
            this.type = type;
            this.GetList(type);
        },
        advertDetail(item) {
            if (this.busy) return;
            this.busy = true;
            this.$router.push({ path: `/ilan-detay/${item.id}` });
        },
        answerAd(item) {
            if (this.busy) return;
            this.busy = true;
            this.$router.push({ path: `/ilanlar/${item.id}` });
        },
    },
};
