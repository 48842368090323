
import { User } from "@/entities/User";
import { localize } from "@/common/localize-service";
import { isNullOrEmpty } from "@/common/validations";
import service from "@/common/service";
import { CfAlertError, CfAlertSuccess, CfConfirm } from "@/common/alerts";
import { CancelMembershipModel } from "@/models/CancelMembershipModel";
import { ApiResponse } from "@/models/ApiResponse";
import { MembershipType } from '@/common/enums';
export default {
    props:{
        user : Object,
    },
    emits : ["updateUser"],
    data() {
        return {
            L: localize,
            busy: false,
            model: new CancelMembershipModel(),
        };
    },
    mounted(){
        if(this.user.membershipType == MembershipType['Premium Üyelik']){
            this.$router.push("/ayarlar");
        }
    },
    methods: {
        cancelMembership() {
            if (this.busy) return;
            this.busy = true;

            CfConfirm("Emin misiniz?", "Uyeliginizi sonlandirmak istediginize emin misiniz?", () => {
                service
                    .getInstance()
                    .CancelMembership(this.model)
                    .then((response: ApiResponse) => {
                        if (response.isSucceed) {
                            service.getInstance().Logout();
                        } else {
                            CfAlertError(response.message);
                        }
                    })
                    .catch((error) => {})
                    .finally(() => (this.busy = false));
            });
        },
    },
};
