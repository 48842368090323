
import service from "@/common/service";
import { localize } from "@/common/localize-service";
import { dateString } from "@/common/date-format";
import { isNullOrEmpty } from "@/common/validations";
import { HelpSubject, MembershipType } from "@/common/enums";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";
import { ApiResponse } from "@/models/ApiResponse";
import { Variables } from "@/common/variables";

class WriteAnswerModel {
    advertId: string;
    price: number;
}

export default {
    props: {
        id: String,
        user : Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            ad: Object,
            L: localize,
            dateString: dateString,
            isNullOrEmpty: isNullOrEmpty,
            helpSubject: HelpSubject,
            isMinPriceValid: true,
            busy: false,
            minPrice: Number,
            answer: new WriteAnswerModel(),
            imagePath: Variables.ImagePath,
            userCheck : Number,
            isBudgetValid : true,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
        
        service
            .getInstance()
            .GetAdvertDetail("Tevkil/detail", "?id=" + this.$route.params.id)
            .then((response) => {
                this.ad = response.result;
                service
                    .getInstance()
                    .GetAdvertTypes()
                    .then((response) => {
                        this.minPrice = response.result.find((c) => c.typeId == this.ad.advertType).minimumPrice;
                    });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => (this.busy = false));
    },
    methods: {
        answerAd() {
            if (this.busy) return;
            this.busy = true;

            if(this.answer.price < this.ad.budget){
                this.isBudgetValid = false;
                this.busy = false;
                return;
            }

            if (this.minPrice == null || this.answer.price == null || this.answer.price < this.minPrice) {
                this.isMinPriceValid = false;
                this.busy = false;
                return;
            }

            if (this.user.membershipType == MembershipType.Hatalı) {
                CfAlertError(localize("AnswerAd.Error.Hatali"));
                this.busy = false;
                return;
            } else if (this.user.membershipType == MembershipType["Temel Üyelik"] || this.user.membershipType == MembershipType["Süre Devam"]) {
                CfAlertError(localize("AnswerAd.Error.Temel"));
                this.busy = false;
                return;
            } else if (this.user.membershipType == MembershipType.Misafir) {
                CfAlertError(localize("AnswerAd.Error.Misafir"));
                this.busy = false;
                return;
            } else if (this.user.membershipType == MembershipType["User İptal"] || this.user.membershipType == MembershipType["Admin İptal"]) {
                CfAlertError(localize("AnswerAd.Error.Iptal"));
                this.busy = false;
                return;
            }

            this.answer.advertId = this.ad.id;
            service
                .getInstance()
                .AnswerWrite(this.answer)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.$router.push({ path: "/ilanlar" });
                        CfAlertSuccess(response.message);
                    } else {
                        CfAlertError(response.message);
                        return;
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));

            this.busy = false;
        },
    },
};
