<template>
    <!--begin::Content-->
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <!--begin::Subheader-->
        <div class="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <!--begin::Details-->
                <div class="d-flex align-items-center flex-wrap mr-2">
                    <!--begin::Title-->
                    <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">İlanlarım</h5>
                    <!--end::Title-->
                    <!--begin::Separator-->
                    <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                    <!--end::Separator-->
                </div>
                <!--end::Details-->
            </div>
        </div>
        <!--end::Subheader-->
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
                <div v-if="adList.length == 0" class="card card-custom gutter-b">
                    <div class="card-body">
                        <div class="row">
                            <label class="col-12 text-center">{{ L("MyAdvertsIlanBulunamadi") }}</label>
                            <router-link class="btn btn-success col-12 col-md-6 mx-auto" to="yeni-ilan">{{ L("MyAdvertCreateAdBtn") }}</router-link>
                        </div>
                    </div>
                </div>
                <div v-else class="card gutter-b border-0 card-shadow" v-for="ad in adList" :key="ad.id">
                    <div class="card-body p-4">
                        <!--begin::Top-->
                        <div class="d-flex">
                             <!--begin::Pic-->
                            <div class="mr-lg-5 mr-3 text-center">
                                <div class="text-center">
                                    <div class="card-photo mb-2" :style="{ backgroundImage: 'url(' + (isNullOrEmpty(ad.photo) ? (ad.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + ad.photo) + ')' }"></div>
                                    <span class="font-weight-bold text-dark-50">{{ ad.userCity }}</span>
                                    <span class="font-weight-bold text-dark-50 d-block">{{ ad.userNo }}</span>
                                </div>
                            </div>
                            <!--end::Pic-->
                            <!--begin::User-->
                            <div class="w-100">
                                <!--begin::Name-->
                                <label class="d-flex align-items-center text-dark font-size-h6 font-weight-bold flex-wrap">
                                    <span class="mr-lg-3 mr-0 mobile-w-100">{{ "# " + ad.advertNo }}</span>
                                    <span class="mobile-hide">-</span>
                                    <span class="ml-lg-3 ml-0 mobile-w-100"><i class="far fa-clock mr-1 text-dark"></i>{{ dateString(ad.createDate) }}</span>
                                </label>
                                <!--end::Name-->

                                <!--begin::Contacts-->
                                <div class="d-flex flex-wrap my-2">
                                    <label class="text-muted font-weight-bold mr-lg-5 mr-3 mb-lg-0 mb-2 mobile-w-100"><i class="fas fa-briefcase mr-1"></i>{{ helpSubject[ad.advertType] }}</label>
                                    <label class="text-muted font-weight-bold">
                                        <i class="fas fa-map-marker-alt mr-1"></i>
                                        <span class="mr-1 text-dark" style="font-weight: bold">{{ ad.userCity }}</span>
                                        <i class="fas fa-arrow-right"></i>
                                        <span class="ml-1" style="color: red; font-weight: bold">{{ ad.city }}</span>
                                        <span>{{ " / " + ad.district }}</span>
                                    </label>
                                </div>
                                <!--end::Contacts-->

                                <!--begin::Content-->
                                <div class="mobile-hide">
                                    <div class="font-weight-bold text-dark-50 py-2 py-lg-2 mr-lg-5 text-justify">{{ ad.detail }}</div>
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--end::User-->
                             <!--begin::Pic-->
                            <div class="ml-lg-5 ml-3 text-center" v-if="ad.status == 3">
                                <div class="text-center">
                                    <div class="card-photo mb-2" :style="{ backgroundImage: 'url(' + (isNullOrEmpty(ad.jobUser.photo) ? (ad.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + ad.jobUser.photo) + ')' }"></div>
                                    <span class="font-weight-bold text-dark-50">{{ ad.jobUser.city }}</span>
                                    <span class="font-weight-bold text-dark-50 d-block">{{ ad.jobUser.userNo }}</span>
                                </div>
                            </div>
                            <!--end::Pic-->
                        </div>
                         <!--begin::Content-->
                        <div class="mobile-show">
                            <div class="font-weight-bold text-dark-50 mt-3 text-justify">{{ ad.detail }}</div>
                        </div>
                        <!--end::Content-->
                        <!--end::Top-->
                        <!--begin::Separator-->
                        <div class="separator separator-solid my-3"></div>
                        <!--end::Separator-->
                         <!--begin::Bottom-->
                        <div class="ad-card-bottom">
                            <!--begin: Item-->
                            <div class="d-flex align-items-center justify-content-start my-1 col-6 col-lg-4">
                                <span class="mr-4">
                                    <i class="fas fa-wallet icon-2x text-muted"></i>
                                </span>
                                <div class="d-flex flex-column text-dark-75 text-center">
                                    <span class="font-weight-bolder font-size-sm">{{ L("AdList.Web.Label.Budget") }}</span>
                                    <span class="font-weight-bolder font-size-h5"> <span class="text-dark-50 font-weight-bold">₺ </span>{{ ad.budget }}</span>
                                </div>
                            </div>
                            <!--end: Item-->
                            <!--begin: Item-->
                            <div class="d-flex align-items-center my-1 justify-content-lg-center justify-content-end col-6 col-lg-4">
                                <span class="mr-4">
                                    <i class="far fa-calendar icon-2x text-muted"></i>
                                </span>
                                <div class="d-flex flex-column text-dark-75 text-center">
                                    <span class="font-weight-bolder font-size-sm">{{ L("AdList.Web.Label.LastDate") }}</span>
                                    <span class="font-weight-bolder font-size-h5"> <span class="text-dark-50 font-weight-bold"></span>{{ dateString(ad.endDate) }}</span>
                                </div>
                            </div>
                            <!--end: Item-->
                            <!--begin: Item-->
                            <div class="d-flex align-items-center col-12 col-lg-4">
                                <div class="d-flex flex-column text-dark-75 w-100">
                                    <div v-if="ad.status == 1" class="col-lg-6 col-12 ml-auto p-0">
                                        <div v-if="ad.answers.find((c) => c.userNo == user.userNo)" class="text-resp mt-lg-0 mt-3">
                                            <button v-if="new Date(ad.endDate) >= night" class="w-100 btn btn-primary btn-large" @click="advertDetail(ad)">Cevaplandı</button>
                                            <span v-if="new Date(ad.endDate) < night" class="font-weight-bold text-dark">{{ L("AdList.Text.Expired") }}</span>
                                        </div>
                                        <div v-else class="text-resp btn-large mt-lg-0 mt-3">
                                            <div v-if="ad.userNo == user.userNo">
                                                <button v-if="new Date(ad.endDate) >= night" class="w-100 btn btn-danger" @click="advertDetail(ad)">{{ ad.answers.length + " cevap geldi" }}</button>
                                                <span v-if="new Date(ad.endDate) < night" class="font-weight-bold text-dark">{{ L("AdList.Text.Expired") }}</span>
                                            </div>
                                            <div v-else class="text-resp mt-lg-0 mt-3">
                                                <button v-if="type == 1 && new Date(ad.endDate) >= night" class="w-100 btn btn-danger btn-large" @click="answerAd(ad)">{{ L("AdList.Button.Answer") }}</button>
                                                <span v-if="new Date(ad.endDate) < night" class="font-weight-bold text-dark">{{ L("AdList.Text.Expired") }}</span>
                                                <span v-if="ad.userNo != user.userNo && type == 2" class="font-weight-bold text-dark">{{ L("AdList.Web.Text.OtherArea") }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="ad.status == 2"></div>
                                    <div v-else-if="ad.status == 3" class="col-lg-6 col-12 ml-auto p-0 text-resp mt-lg-0 mt-3">
                                        <span class="font-weight-bold text-primary">{{ L("AdList.Text.Completed") }}</span>
                                    </div>
                                </div>
                            </div>
                            <!--end: Item-->
                        </div>
                        <!--end::Bottom-->
                    </div>
                </div>
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
    <!--end::Content-->
    <div class="loading" v-if="busy"></div>
</template>

<script lang="ts">
import service from "../common/service";
import { HelpSubject, MembershipType } from "../common/enums";
import { dateString } from "../common/date-format";
import { isNullOrEmpty } from "../common/validations";
import { User } from "../entities/User";
import { localize } from "../common/localize-service";
import { Variables } from "@/common/variables";

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            adList: [],
            helpSubject: HelpSubject,
            dateString: dateString,
            isNullOrEmpty: isNullOrEmpty,
            L: localize,
            busy: true,
            imagePath: Variables.ImagePath,
            night : Date
        };
    },
    mounted() {
        this.night = new Date(Date.now() - 1000 * 60 * 60 * 24);
        this.night.setHours(0);
        this.night.setMinutes(0);
        this.night.setSeconds(0);
        this.night.setMilliseconds(0);
        this.GetList();
    },
    methods: {
        GetList() {
            var serviceUrl = "Tevkil/MyTevkils";
            service
                .getInstance()
                .GetAdvertList(serviceUrl)
                .then((response) => {
                    this.adList = response.result;
                })
                .catch((error) => {})
                .finally(() => (this.busy = false));
        },
        advertDetail(item) {
            if (this.busy) return;
            this.busy = true;
            this.$router.push({ path: `/ilan-detay/${item.id}` });
        },
    },
};
</script>
