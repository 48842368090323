<template>
    <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">{{ L("AccountSettings.Text.Title") }}</h3>
            </div>
            <div class="card-toolbar">
                <button type="button" class="btn btn-success" @click="postForm">{{ L("AccountSettings.Button.Save") }}</button>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
            <div class="card-body">
                <!--begin::Heading-->
                <div class="form-group row">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                        <h5 class="font-weight-bold mb-6 text-left">{{ L("AccountSettings.Label.UserInfo") }}</h5>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("AccountSettings.Label.UserNo") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <span class="form-control form-control-lg text-left">{{ user.userNo }}</span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("AccountSettings.Label.Membership") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <span class="form-control form-control-lg text-left">{{ membershipType[user.membershipType] }}</span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("AccountSettings.Label.CreateDate") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <span class="form-control form-control-lg text-left">{{ dateString(user.creationDate) }}</span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("AccountSettings.Label.ValidUntil") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <span class="form-control form-control-lg text-left">{{ dateString(user.validUntil) }}</span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("AccountSettings.Label.LastLogin") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <span class="form-control form-control-lg text-left">{{ hourString(user.lastLogin) }}</span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("AccountSettings.Label.LoginCount") }}</label>
                    <div class="col-lg-9 col-xl-6">
                        <span class="form-control form-control-lg text-left">{{ user.loginCount }}</span>
                    </div>
                </div>

                <div class="separator separator-dashed my-5"></div>

                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                        <h5 class="font-weight-bold mb-6 text-left">{{ L("AccountSettings.Label.Privacy") }}</h5>
                    </div>
                </div>
                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("AccountSettings.Label.PublicAccount") }}</label>
                    <div class="col-lg-9 col-xl-6 d-flex align-items-center">
                        <div class="checkbox-inline">
                            <label class="checkbox"><input type="radio" v-model="user.publicAccount" :value="true" /><span></span>Açık Üye</label>
                            <label class="checkbox"><input type="radio" v-model="user.publicAccount" :value="false" /><span></span>Gizli Üye (Avukat listesinde görünmez)</label>
                        </div>
                    </div>
                </div>

                <div class="separator separator-dashed my-5"></div>

                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                        <h5 class="font-weight-bold mb-6 text-left">{{ L("AccountSettings.Label.WorkingHours") }}</h5>
                    </div>
                </div>
                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("AccountSettings.Label.Days") }}</label>
                    <div class="col-lg-9 col-xl-6 d-flex align-items-center">
                        <select v-model="user.workingHour" class="form-select form-control form-control-solid form-control-lg">
                            <option v-for="workingHour in workingHours" :key="workingHour.value">{{ workingHour.display }}</option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("AccountSettings.Label.Hours") }}</label>
                    <div class="col-lg-9 col-xl-6 d-flex align-items-center">
                        <div class="d-flex align-items-center w-100">
                            <input type="time" v-model="user.startHour" class="form-control form-control-solid w-100" />
                            <span class="mx-2 text-muted">-</span>
                            <input type="time" v-model="user.endHour" class="form-control form-control-solid w-100" />
                        </div>
                    </div>
                </div>

                <div class="separator separator-dashed my-5"></div>

                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                        <h5 class="font-weight-bold mb-6 text-left">Bildirim Ayarları</h5>
                    </div>
                    <label class="text-left d-block">Gelen yardim isteklerinin size SMS veya E-mail ile bildirilmesi.</label>
                </div>
                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("AccountSettings.Label.Sms") }}</label>
                    <div class="col-lg-9 col-xl-6 d-flex align-items-center">
                        <div class="checkbox-inline">
                            <label class="checkbox"><input type="radio" v-model="user.receiveSms" :value="true" /><span></span>İstiyorum</label>
                            <label class="checkbox"><input type="radio" v-model="user.receiveSms" :value="false" /><span></span>İstemiyorum</label>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{ L("AccountSettings.Label.Email") }}</label>
                    <div class="col-lg-9 col-xl-6 d-flex align-items-center">
                        <div class="checkbox-inline">
                            <label class="checkbox"><input type="radio" v-model="user.receiveEmail" :value="true" /><span></span>İstiyorum</label>
                            <label class="checkbox"><input type="radio" v-model="user.receiveEmail" :value="false" /><span></span>İstemiyorum</label>
                        </div>
                    </div>
                </div>

                <label class="text-left"
                    ><i class="fas fa-star-of-life text-danger"></i>
                    İPTAL (istemiyorum) seçimi sadece yardım isteklerinin bildirimini engeller. Sistem veya üyeliğiniz ile ilgili önemli duyurular size sms-email olarak gelmeye devam eder.
                </label>

                <div class="separator separator-dashed my-5"></div>

                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                        <h5 class="font-weight-bold mb-6 text-left">{{ L("AccountSettings.Label.MessageList") }}</h5>
                    </div>
                </div>
                <!--begin::Form Group-->
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left">{{ L("AccountSettings.Label.Message") }}</label>
                    <div class="col-lg-9 col-xl-6 d-flex align-items-center">
                        <div class="checkbox-inline">
                            <label class="checkbox"><input type="radio" v-model="user.receiveMessage" :value="true" /><span></span>İstiyorum</label>
                            <label class="checkbox"><input type="radio" v-model="user.receiveMessage" :value="false" /><span></span>İstemiyorum</label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>

<script lang="ts">
import { User } from "@/entities/User";
import { dateString, hourString } from "@/common/date-format";
import { MembershipType } from "@/common/enums";
import { AccountEditModel } from "@/models/AccountEditModel";
import service from "@/common/service";
import { ApiResponse } from "@/models/ApiResponse";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";
import { localize } from "@/common/localize-service";

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            dateString: dateString,
            membershipType: MembershipType,
            hourString: hourString,
            L: localize,
            busy: false,
            workingHours: [],
        };
    },
    mounted() {
        if (this.user.membershipType != MembershipType["Premium Üyelik"]) {
            this.user.receiveMessage = false;
        }
       
        this.workingHours.push({ value: "Hafta içi", display: "Hafta içi" });
        this.workingHours.push({ value: "Hafta sonu", display: "Hafta sonu" });
        this.workingHours.push({ value: "Pazar hariç", display: "Pazar hariç" });
        this.workingHours.push({ value: "Tüm günler", display: "Tüm günler" });
    },
    methods: {
        postForm() {
            if (this.busy) return;
            this.busy = true;
            var editModel = new AccountEditModel();

            // this.model.startHour = typeof this.model.startHour === "object"? onlyHours(this.model.startHour) : this.model.startHour;
            // this.model.endHour = typeof this.model.endHour === "object"? onlyHours(this.model.endHour) : this.model.endHour;

            editModel.EndHour = this.user.endHour;
            editModel.WorkingHour = this.user.workingHour;
            editModel.StartHour = this.user.startHour;
            editModel.PublicAccount = this.user.publicAccount;
            editModel.ReceiveMessage = this.user.receiveMessage;
            editModel.ReceiveSMS = this.user.receiveSms;
            editModel.ReceiveEmail = this.user.receiveEmail;

            service
                .getInstance()
                .AccountEdit(editModel)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.$emit("updateUser", response.result);
                        if(navigator.cookieEnabled){
                            localStorage.setItem("user",JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                        CfAlertSuccess(response.message);
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => {
                    this.busy = false;
                });
        },
    },
};
</script>
