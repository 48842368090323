
import { User } from '@/entities/User';
import service from '@/common/service';
import { localize } from '@/common/localize-service';
import { ChangeBaroModel } from '@/models/ChangeBaroModel';
import { isNullOrEmpty, isNumber } from '@/common/validations';
import { CfAlertError, CfAlertSuccess } from '@/common/alerts';
import { ApiResponse } from '@/models/ApiResponse';
import { MembershipType } from '@/common/enums';
import { localeSort } from "@/common/locale-sort";

export default{
    props:{
        user : Object,
    },
    emits : ["updateUser"],
    data(){
        return{
            cities : [],
            L : localize,
            model : new ChangeBaroModel(),
            busy : false,
        }
    },
    mounted(){
        service.getInstance().GetCities()
        .then((response) =>{
            var x = response.result;
            x.push({name : "İstanbul 2"});
            this.cities = x.sort(localeSort);
        })
    },
    methods : {
        changeBaro(){
            if(this.busy) return;
            this.busy = true;
            if(isNullOrEmpty(this.model.Baro) || isNullOrEmpty(this.model.SicilNo)){
                this.busy = false;
                return;
            };

            if(!isNumber(this.model.SicilNo)){
                this.busy = false;
                return;
            };

            if(this.model.Baro == this.user.baro && this.model.SicilNo == this.user.sicilNo){
                this.busy = false;
                CfAlertError(localize("BaroChange.Error.NoChange"));
                return;
            };

            service.getInstance().ChangeBaro(this.model)
            .then((response : ApiResponse) => {
                if(response.isSucceed){
                    this.$emit("updateUser",response.result);
                    this.model = new ChangeBaroModel();
                     if(navigator.cookieEnabled){
                            localStorage.setItem("user",JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                    CfAlertSuccess(response.message);
                }else{
                    CfAlertError(response.message);
                }
            })
            .catch(error => {
                service.getInstance().ErrorMessage(error);
            })
            .finally(() => this.busy = false);
        }
    }
}
