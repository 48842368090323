<template>
    <!--begin::Content-->
    <div v-if="state == 1" class="content d-flex flex-column flex-column-fluid">
        <!--begin::Subheader-->
        <div class="subheader py-3 py-lg-8 subheader-transparent">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <!--begin::Info-->
                <div class="d-flex align-items-center mr-1">
                    <!--begin::Page Heading-->
                    <div class="d-flex align-items-baseline flex-wrap mr-5">
                        <!--begin::Page Title-->
                        <h2 class="d-flex align-items-center text-dark font-weight-bold my-1 mr-3">
                            {{ L("CreateAd1.Text.Title") }}
                        </h2>
                        <!--end::Page Title-->
                    </div>
                    <!--end::Page Heading-->
                </div>
                <!--end::Info-->
            </div>
        </div>
        <!--end::Subheader-->
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
                <div class="card card-custom gutter-b">
                    <div class="card-header">
                        <div class="card-title w-100">
                            <h5 class="card-label w-100">Talep Oluştur</h5>
                        </div>
                    </div>
                    <!--begin::Form-->
                    <form class="form">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-3"></div>
                                <div class="col-xl-6">
                                    <label class="mb-5">{{ L("CreateAd1.Text.Top") }}</label>
                                    <div class="row">
                                        <!--begin::Input-->
                                        <div class="form-group col-6">
                                            <label>{{ L("CreateAd1.Label.City") }}</label>
                                            <select class="form-control form-control-solid form-control-lg form-select" :class="!isCityValid ? 'form-error' : ''" @change="cityChange" v-model="model.City">
                                                <option v-for="city in cities" :key="city.name">{{ city.name }}</option>
                                            </select>
                                        </div>
                                        <!--end::Input-->
                                        <!--begin::Input-->
                                        <div class="form-group col-6">
                                            <label>{{ L("CreateAd1.Label.District") }}</label>
                                            <select class="form-control form-control-solid form-control-lg form-select" :class="!isDistrictValid ? 'form-error' : ''" @change="isDistrictValid = true" v-model="model.District">
                                                <option v-for="area in areas" :key="area.name">{{ area.name }}</option>
                                            </select>
                                        </div>
                                        <!--end::Input-->
                                    </div>
                                    <!--begin::Input-->
                                    <div class="form-group">
                                        <label>{{ L("CreateAd1.Label.HelpSubject") }}</label>
                                        <select class="form-control form-control-solid form-control-lg form-select" v-model="model.HelpSubject" :class="!isSubjectValid ? 'form-error' : ''" @change="isSubjectValid = true">
                                            <option v-for="helpsubject in helpSubjects" :key="helpsubject.name" :value="helpsubject.typeId">{{ helpsubject.name }}</option>
                                        </select>
                                    </div>
                                    <!--end::Input-->
                                    <!--begin::Input-->
                                    <div class="form-group">
                                        <label for="exampleTextarea">{{ L("CreateAd1.Label.Detail") }}</label>
                                        <textarea class="form-control form-control-solid form-control-lg" v-model="model.Detail" :class="!isDetailValid ? 'form-error' : ''" @keypress="isDetailValid = true"></textarea>
                                    </div>
                                    <!--end::Input-->
                                    <div class="row">
                                        <!--begin::Input-->
                                        <div class="form-group col-6">
                                            <label>{{ L("CreateAd1.Label.Budget") }}</label>
                                            <select class="form-control form-control-solid form-control-lg form-select" :class="!isBudgetValid ? 'form-error' : ''" @change="isBudgetValid = true" v-model="model.Budget">
                                                <option v-for="price in prices" :key="price.value" :value="price.value">{{ price.display }}</option>
                                            </select>
                                        </div>
                                        <!--end::Input-->
                                        <!--begin::Input-->
                                        <div class="form-group col-6 date">
                                            <label>{{ L("CreateAd1.Label.LastDate") }}</label>
                                            <input type="date" v-model="model.LastDate" :min="minDate" :max="maxDate" :class="!isDateValid ? 'form-error' : ''" @change="isDateValid = true" class="form-control form-control-solid form-control-lg" />
                                        </div>
                                        <!--end::Input-->
                                    </div>

                                    <div v-if="userCheck == 4 || userCheck == 6">
                                        <label>{{ L("CreateAd1.Text.Bottom") }}</label>
                                        <button class="btn btn-primary font-weight-bold mr-2 d-block w-100 mt-5" type="button" @click="createAd">{{ L("CreateAd1.Button.Continue") }}</button>
                                    </div>

                                    <div class="text-center mt-3" v-if="userCheck == 1">
                                        <label>{{ L("CreateAd.Error.Misafir") }}</label>
                                    </div>

                                    <div class="mt-3" v-if="userCheck == 2">
                                        <label>{{ L("CreateAd.Error.Hatali") }}</label>
                                        <router-link to="/ayarlar" class="btn btn-primary font-weight-bold mr-2 d-block w-100 mt-3">{{ L("CreateAd.Hatali.AyarlarBtn") }}</router-link>
                                    </div>

                                    <div v-if="user.membershipType == 4 || user.membershipType == 5">
                                        <label>{{ L("CreateAd.Error.Iptal") }}</label>
                                        <router-link class="btn btn-primary font-weight-bold mr-2 d-block w-100 mt-5" to="/ayarlar/premium">{{ L("CreateAd.Iptal.PreBtn") }}</router-link>
                                    </div>
                                </div>
                                <div class="col-xl-3"></div>
                            </div>
                        </div>
                    </form>
                    <!--end::Form-->
                </div>
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
    <!--end::Content-->

    <!--begin::Content-->
    <div v-if="state == 2" class="content d-flex flex-column flex-column-fluid">
        <div class="subheader py-3 py-lg-8 subheader-transparent">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <!--begin::Info-->
                <div class="d-flex align-items-center mr-1">
                    <!--begin::Page Heading-->
                    <div class="d-flex align-items-baseline flex-wrap mr-5">
                        <!--begin::Page Title-->
                        <h2 class="d-flex align-items-center text-dark font-weight-bold my-1 mr-3">{{ L("CreateAd2.Text.Title") }}</h2>
                        <!--end::Page Title-->
                    </div>
                    <!--end::Page Heading-->
                </div>
                <!--end::Info-->
            </div>
        </div>
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
                <!--begin::Card-->
                <div class="card card-custom gutter-b border-0 card-shadow">
                    <div class="card-header">
                        <div class="card-title w-100">
                            <h5 class="card-label w-100">{{ L("CreateAd2.Text.Preview") }}</h5>
                        </div>
                    </div>
                    <div class="card-body p-4">
                        <!--begin::Top-->
                        <div class="d-flex">
                            <!--begin::Pic-->
                            <div class="mr-lg-5 mr-3 text-center">
                                <div class="text-center">
                                    <div class="card-photo mb-2" :style="{ backgroundImage: 'url(' + (isNullOrEmpty(user.photo) ? (user.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + user.photo) + ')' }"></div>
                                    <span class="font-weight-bold text-dark-50">{{ user.city }}</span>
                                    <span class="font-weight-bold text-dark-50 d-block">{{ user.userNo }}</span>
                                </div>
                            </div>
                            <!--end::Pic-->
                             <!--begin::User-->
                            <div class="w-100">
                                <!--begin::Name-->
                                <label class="d-flex align-items-center text-dark font-size-h6 font-weight-bold flex-wrap">
                                    <span class="ml-lg-3 ml-0 mobile-w-100"><i class="fas fa-briefcase mr-2 text-dark"></i>{{ helpSubject[model.HelpSubject] }}</span>
                                </label>
                                <!--end::Name-->
                                <!--begin::Contacts-->
                                <div class="d-flex flex-wrap my-2">
                                    <div class="text-muted font-weight-bold">
                                        <i class="fas fa-map-marker-alt mr-1"></i>
                                        <span class="ml-1 font-weight-bold">{{ model.City }}</span>
                                        <span>{{ " / " + model.District }}</span>
                                    </div>
                                </div>
                                <!--end::Contacts-->
                                <!--begin::Content-->
                                <div>
                                    <div class="font-weight-bold text-dark-50 py-2 py-lg-2 mr-lg-5 text-justify">{{ model.Detail }}</div>
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--begin::User-->
                        </div>
                        <!--end::Top-->
                        <!--begin::Separator-->
                        <div class="separator separator-solid my-3"></div>
                        <!--end::Separator-->
                      <!--begin::Bottom-->
                        <div class="ad-card-bottom">
                            <!--begin: Item-->
                            <div class="d-flex align-items-center justify-content-start my-1 col-6 col-lg-4">
                                <span class="mr-4">
                                    <i class="fas fa-wallet icon-2x text-muted"></i>
                                </span>
                                <div class="d-flex flex-column text-dark-75 text-center">
                                    <span class="font-weight-bolder font-size-sm">{{ L("AdList.Web.Label.Budget") }}</span>
                                    <span class="font-weight-bolder font-size-h5"> <span class="text-dark-50 font-weight-bold">₺ </span>{{ model.Budget }}</span>
                                </div>
                            </div>
                            <!--end: Item-->
                            <!--begin: Item-->
                            <div class="d-flex align-items-center my-1 justify-content-lg-center justify-content-end col-6 col-lg-4">
                                <span class="mr-4">
                                    <i class="far fa-calendar icon-2x text-muted"></i>
                                </span>
                                <div class="d-flex flex-column text-dark-75 text-center">
                                    <span class="font-weight-bolder font-size-sm">{{ L("AdList.Web.Label.LastDate") }}</span>
                                    <span class="font-weight-bolder font-size-h5"> <span class="text-dark-50 font-weight-bold"></span>{{ dateString(model.LastDate) }}</span>
                                </div>
                            </div>
                            <!--end: Item-->
                        </div>
                        <!--end::Bottom-->
                        <div class="separator separator-solid my-3"></div>

                        <div class="d-flex align-items-center mt-10">
                            <label class="text-left">{{ L("CreateAd2.Text.Warning") }}</label>
                        </div>

                        <div class="d-flex justify-content-between pt-5">
                            <div class="mr-2">
                                <button type="button" class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4" v-on:click="previousPage">
                                    {{ L("CreateAd2.Button.Back") }}
                                </button>
                            </div>
                            <div>
                                <button type="button" id="complateBtn" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" v-on:click="sendForm">
                                    {{ L("CreateAd2.Button.Continue") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Card-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
    <!--end::Content-->
    <div class="loading" v-if="busy"></div>
</template>

<script lang="ts">
import { localize } from "@/common/localize-service";
import service from "@/common/service";
import { ApiResponse } from "@/models/ApiResponse";
import { localeSort } from "@/common/locale-sort";
import { CreateAdModel } from "@/models/CreateAdModel";
import { HelpSubject, MembershipType, Prices } from "@/common/enums";
import { isNullOrEmpty, isNullOrUndefined } from "@/common/validations";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";
import { User } from "@/entities/User";
import { dateString, inputDate } from "@/common/date-format";
import { toDisplayString } from "@vue/shared";
import { Variables } from '@/common/variables';

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            L: localize,
            cities: [],
            areas: [],
            helpSubjects: [],
            prices: [],
            model: new CreateAdModel(),
            isCityValid: true,
            isDistrictValid: true,
            isSubjectValid: true,
            isDetailValid: true,
            isBudgetValid: true,
            isDateValid: true,
            busy: false,
            state: 1,
            helpSubject: HelpSubject,
            minDate: "",
            maxDate: "",
            isNullOrEmpty: isNullOrEmpty,
            dateString: dateString,
            imagePath : Variables.ImagePath,
            userCheck : Number,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
        
        var today = new Date(Date.now());
        var max = new Date(Date.now() + 1000 * 60 * 60 * 24 * 15);
        this.minDate = inputDate(today);
        this.maxDate = inputDate(max);
        service
            .getInstance()
            .GetCities()
            .then((response: ApiResponse) => {
                this.cities = response.result.sort(localeSort);
            });
        service
            .getInstance()
            .GetAdvertTypes()
            .then((response) => {
                this.helpSubjects = response.result;
            });
        for (var i in Prices) {
            if (typeof Prices[i] === "number") {
                this.prices.push({ value: Prices[i], display: i });
            }
        }
    },
    methods: {
        cityChange() {
            this.isCityValid = true;
            service
                .getInstance()
                .GetAreas("?cityName=" + this.model.City)
                .then((response: ApiResponse) => {
                    var responseAreas = response.result.sort(localeSort);
                    this.areas = responseAreas;
                    this.model.District = responseAreas[0].name;
                });
        },
        createAd() {
            if (this.busy) return;
            this.busy = true;

            var isValid = true;
            if (isNullOrEmpty(this.model.City)) {
                this.isCityValid = false;
                isValid = false;
            }
            if (isNullOrEmpty(this.model.District)) {
                this.isDistrictValid = false;
                isValid = false;
            }
            if (this.model.HelpSubject == null) {
                this.isSubjectValid = false;
                isValid = false;
            }
            if (isNullOrEmpty(this.model.Detail) || this.model.Detail.indexOf("@") != -1) {
                this.isDetailValid = false;
                isValid = false;
            }
            if (this.model.Budget == null) {
                this.isBudgetValid = false;
                isValid = false;
            }
            if (this.model.LastDate == null) {
                console.log("invalid");
                this.isDateValid = false;
                isValid = false;
            }

            if (!isValid) {
                this.busy = false;
                return;
            }
            if (this.model.HelpSubject != null) {
                var typeData = this.helpSubjects.find((c) => c.typeId == this.model.HelpSubject);
                if (this.model.Budget < typeData.minimumPrice) {
                    CfAlertError(localize("CreateAd.Error.MinBudget", typeData.name, typeData.minimumPrice));
                    this.busy = false;
                    return;
                }
            }
            this.state = 2;
            this.busy = false;
        },
        previousPage() {
            this.state = 1;
        },
        sendForm() {
            if (this.busy) return;
            this.busy = false;

            this.busy = true;

            service
                .getInstance()
                .CreateAd(this.model)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.$router.push({ path: "ilanlarim" });
                        CfAlertSuccess(response.message);
                        this.model = new CreateAdModel();
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
    },
};
</script>

<style scoped>
.form-error {
    border: 1px solid red !important;
}
</style>
