
import { localize } from "@/common/localize-service";
import service from "@/common/service";
import { ApiResponse } from "@/models/ApiResponse";
import { localeSort } from "@/common/locale-sort";
import { CreateAdModel } from "@/models/CreateAdModel";
import { HelpSubject, MembershipType, Prices } from "@/common/enums";
import { isNullOrEmpty, isNullOrUndefined } from "@/common/validations";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";
import { User } from "@/entities/User";
import { dateString, inputDate } from "@/common/date-format";
import { toDisplayString } from "@vue/shared";
import { Variables } from '@/common/variables';

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            L: localize,
            cities: [],
            areas: [],
            helpSubjects: [],
            prices: [],
            model: new CreateAdModel(),
            isCityValid: true,
            isDistrictValid: true,
            isSubjectValid: true,
            isDetailValid: true,
            isBudgetValid: true,
            isDateValid: true,
            busy: false,
            state: 1,
            helpSubject: HelpSubject,
            minDate: "",
            maxDate: "",
            isNullOrEmpty: isNullOrEmpty,
            dateString: dateString,
            imagePath : Variables.ImagePath,
            userCheck : Number,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
        
        var today = new Date(Date.now());
        var max = new Date(Date.now() + 1000 * 60 * 60 * 24 * 15);
        this.minDate = inputDate(today);
        this.maxDate = inputDate(max);
        service
            .getInstance()
            .GetCities()
            .then((response: ApiResponse) => {
                this.cities = response.result.sort(localeSort);
            });
        service
            .getInstance()
            .GetAdvertTypes()
            .then((response) => {
                this.helpSubjects = response.result;
            });
        for (var i in Prices) {
            if (typeof Prices[i] === "number") {
                this.prices.push({ value: Prices[i], display: i });
            }
        }
    },
    methods: {
        cityChange() {
            this.isCityValid = true;
            service
                .getInstance()
                .GetAreas("?cityName=" + this.model.City)
                .then((response: ApiResponse) => {
                    var responseAreas = response.result.sort(localeSort);
                    this.areas = responseAreas;
                    this.model.District = responseAreas[0].name;
                });
        },
        createAd() {
            if (this.busy) return;
            this.busy = true;

            var isValid = true;
            if (isNullOrEmpty(this.model.City)) {
                this.isCityValid = false;
                isValid = false;
            }
            if (isNullOrEmpty(this.model.District)) {
                this.isDistrictValid = false;
                isValid = false;
            }
            if (this.model.HelpSubject == null) {
                this.isSubjectValid = false;
                isValid = false;
            }
            if (isNullOrEmpty(this.model.Detail) || this.model.Detail.indexOf("@") != -1) {
                this.isDetailValid = false;
                isValid = false;
            }
            if (this.model.Budget == null) {
                this.isBudgetValid = false;
                isValid = false;
            }
            if (this.model.LastDate == null) {
                console.log("invalid");
                this.isDateValid = false;
                isValid = false;
            }

            if (!isValid) {
                this.busy = false;
                return;
            }
            if (this.model.HelpSubject != null) {
                var typeData = this.helpSubjects.find((c) => c.typeId == this.model.HelpSubject);
                if (this.model.Budget < typeData.minimumPrice) {
                    CfAlertError(localize("CreateAd.Error.MinBudget", typeData.name, typeData.minimumPrice));
                    this.busy = false;
                    return;
                }
            }
            this.state = 2;
            this.busy = false;
        },
        previousPage() {
            this.state = 1;
        },
        sendForm() {
            if (this.busy) return;
            this.busy = false;

            this.busy = true;

            service
                .getInstance()
                .CreateAd(this.model)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.$router.push({ path: "ilanlarim" });
                        CfAlertSuccess(response.message);
                        this.model = new CreateAdModel();
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
    },
};
