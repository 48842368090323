<template>
    <!--begin::Content-->
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <!--begin::Subheader-->
        <div class="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
            <div class="container d-flex align-items-center justify-content-between flex-wrap">
                <!--begin::Details-->
                <div class="d-flex align-items-center flex-wrap mr-2">
                    <!--begin::Title-->
                    <div>
                        <h5 v-if="type == 1" class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ L("AdListMainCityBtn", user.city) }}</h5>
                        <h6 v-if="type == 1" class="font-weight-normal mt-2 mb-2 mr-5 d-block" style="font-size : 12px">{{ areasText }}</h6>
                    </div>
                    <h5 v-if="type == 2" class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ L("AdListOthersBtn") }}</h5>
                    <h5 v-if="type == 3" class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ L("AdListArchiveBtn") }}</h5>
                    <h5 v-if="type == 4" class="text-dark font-weight-bold mt-2 mb-2 mr-5">Cevapladığım İlanlar</h5>
                    <!--end::Title-->
                    <!--begin::Separator-->
                    <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                    <!--end::Separator-->
                </div>
                <!--end::Details-->
                <!--begin::Toolbar-->
                <div class="d-flex align-items-center flex-wrap mobile-w-100">
                    <!--begin::Button-->
                    <div class="col-lg-6 col-12 d-flex p-0">
                        <button type="button" class="btn btn-light-primary font-weight-bold ml-2 mb-2 w-100" :class="type == 1 ? ' active' : ''" @click="changeType(1)">{{ L("AdListMainCityBtn", user.city) }}</button>
                        <button type="button" class="btn btn-light-primary font-weight-bold ml-2 mb-2 w-100" :class="type == 2 ? ' active' : ''" @click="changeType(2)">{{ L("AdListOthersBtn") }}</button>
                    </div>
                    <div class="col-lg-6 col-12 d-flex p-0">
                        <button type="button" class="btn btn-light-primary font-weight-bold ml-2 mb-2 w-100" :class="type == 3 ? ' active' : ''" @click="changeType(3)">{{ L("AdListArchiveBtn") }}</button>
                        <button type="button" class="btn btn-light-primary font-weight-bold ml-2 mb-2 w-100" :class="type == 4 ? ' active' : ''" @click="changeType(4)">Cevapladığım İlanlar</button>
                    </div>

                    <!--end::Button-->
                </div>
                <!--end::Toolbar-->
            </div>
        </div>
        <!--end::Subheader-->
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
                 <div v-if="adList.length == 0 && type == 4" class="card card-custom gutter-b">
                    <div class="card-body">
                        <div class="row">
                            <label class="col-12 text-center">{{ L("Answered.Text.Empty") }}</label>
                        </div>
                    </div>
                </div>
                <!--begin::Card-->
                <div class="card gutter-b border-0 card-shadow" v-for="ad in adList" :key="ad.id">
                    <div class="card-body p-4">
                        <!--begin::Top-->
                        <div class="d-flex">
                            <!--begin::Pic-->
                            <div class="mr-lg-5 mr-3 text-center">
                                <div class="text-center">
                                    <div v-if="user.membershipType == 2 || user.membershipType == 3" class="card-photo mb-2" :style="{ backgroundImage: 'url(' + (isNullOrEmpty(ad.photo) ? (ad.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + ad.photo) + ')' }"></div>
                                    <div v-else class="card-photo mb-2" :style="{ backgroundImage: 'url(' +  imagePath + 'default.jpg'+ ')' }"></div>
                                    <span class="font-weight-bold text-dark-50">{{ ad.userCity }}</span>
                                    <span class="font-weight-bold text-dark-50 d-block">{{ ad.userNo }}</span>
                                </div>
                            </div>
                            <!--end::Pic-->
                            <!--begin::User-->
                            <div class="w-100">
                                <!--begin::Name-->
                                <label class="d-flex align-items-center text-dark font-size-h6 font-weight-bold flex-wrap">
                                    <span class="mr-lg-3 mr-0 mobile-w-100">{{ "# " + ad.advertNo }}</span>
                                    <span class="mobile-hide">-</span>
                                    <span class="ml-lg-3 ml-0 mobile-w-100"><i class="far fa-clock mr-1 text-dark"></i>{{ dateString(ad.createDate) }}</span>
                                </label>
                                <!--end::Name-->

                                <!--begin::Contacts-->
                                <div class="d-flex flex-wrap my-2">
                                    <label class="text-muted font-weight-bold mr-lg-5 mr-3 mb-lg-0 mb-2 mobile-w-100"><i class="fas fa-briefcase mr-1"></i>{{ helpSubject[ad.advertType] }}</label>
                                    <label class="text-muted font-weight-bold">
                                        <i class="fas fa-map-marker-alt mr-1"></i>
                                        <span class="mr-1 text-dark" style="font-weight: bold">{{ ad.userCity }}</span>
                                        <i class="fas fa-arrow-right"></i>
                                        <span class="ml-1" style="color: red; font-weight: bold">{{ ad.city }}</span>
                                        <span>{{ " / " + ad.district }}</span>
                                    </label>
                                </div>
                                <!--end::Contacts-->

                                <!--begin::Content-->
                                <div class="mobile-hide">
                                    <div class="font-weight-bold text-dark-50 py-2 py-lg-2 mr-lg-5 text-justify">{{ ad.detail }}</div>
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--begin::User-->
                            <!--begin::Pic-->
                            <div class="ml-lg-5 ml-3 text-center" v-if="ad.status == 3">
                                <div class="text-center">
                                    <div v-if="user.membershipType == 2 || user.membershipType == 3" class="card-photo mb-2" :style="{ backgroundImage: 'url(' + (isNullOrEmpty(ad.jobUser.photo) ? (ad.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + ad.jobUser.photo) + ')' }"></div>
                                    <div v-else class="card-photo mb-2" :style="{ backgroundImage: 'url(' +  imagePath + 'default.jpg'+ ')' }"></div>
                                    <span class="font-weight-bold text-dark-50">{{ ad.jobUser.city }}</span>
                                    <span class="font-weight-bold text-dark-50 d-block">{{ ad.jobUser.userNo }}</span>
                                </div>
                            </div>
                            <!--end::Pic-->
                        </div>
                        <!--begin::Content-->
                        <div class="mobile-show">
                            <div class="font-weight-bold text-dark-50 mt-3 text-justify">{{ ad.detail }}</div>
                        </div>
                        <!--end::Content-->
                        <!--end::Top-->
                        <!--begin::Separator-->
                        <div class="separator separator-solid my-3"></div>
                        <!--end::Separator-->
                        <!--begin::Bottom-->
                        <div class="ad-card-bottom">
                            <!--begin: Item-->
                            <div class="d-flex align-items-center justify-content-start my-1 col-6 col-lg-4">
                                <span class="mr-4">
                                    <i class="fas fa-wallet icon-2x text-muted"></i>
                                </span>
                                <div class="d-flex flex-column text-dark-75">
                                    <span class="font-weight-bolder font-size-sm">{{ L("AdList.Web.Label.Budget") }}</span>
                                    <span class="font-weight-bolder font-size-h5"> <span class="text-dark-50 font-weight-bold">₺ </span>{{ ad.budget }}</span>
                                </div>
                            </div>
                            <!--end: Item-->
                            <!--begin: Item-->
                            <div class="d-flex align-items-center my-1 justify-content-lg-center justify-content-end col-6 col-lg-4">
                                <span class="mr-4">
                                    <i class="far fa-calendar icon-2x text-muted"></i>
                                </span>
                                <div class="d-flex flex-column text-dark-75 text-center">
                                    <span class="font-weight-bolder font-size-sm">{{ L("AdList.Web.Label.LastDate") }}</span>
                                    <span class="font-weight-bolder font-size-h5"> <span class="text-dark-50 font-weight-bold"></span>{{ dateString(ad.endDate) }}</span>
                                </div>
                            </div>
                            <!--end: Item-->
                            <!--begin: Item-->
                            <div class="d-flex align-items-center col-12 col-lg-4">
                                <div class="d-flex flex-column text-dark-75 w-100">
                                    <div v-if="ad.status == 1" class="col-lg-6 col-12 ml-auto p-0">
                                        <div v-if="ad.answers.find((c) => c.userNo == user.userNo)" class="text-resp mt-lg-0 mt-3">
                                            <button v-if="new Date(ad.endDate) >= night" class="w-100 btn btn-primary btn-large" @click="advertDetail(ad)">Cevaplandı</button>
                                            <span v-if="new Date(ad.endDate) < night" class="font-weight-bold text-dark">{{ L("AdList.Text.Expired") }}</span>
                                        </div>
                                        <div v-else class="text-resp btn-large mt-lg-0 mt-3">
                                            <div v-if="ad.userNo == user.userNo">
                                                <button v-if="new Date(ad.endDate) >= night" class="w-100 btn btn-danger" @click="advertDetail(ad)">{{ ad.answers.length + " cevap geldi" }}</button>
                                                <span v-if="new Date(ad.endDate) < night" class="font-weight-bold text-dark">{{ L("AdList.Text.Expired") }}</span>
                                            </div>
                                            <div v-else class="text-resp mt-lg-0 mt-3">
                                                <button v-if="type == 1 && new Date(ad.endDate) >= night" class="w-100 btn btn-danger btn-large" @click="answerAd(ad)">{{ L("AdList.Button.Answer") }}</button>
                                                <span v-if="new Date(ad.endDate) < night" class="font-weight-bold text-dark">{{ L("AdList.Text.Expired") }}</span>
                                                <span v-if="ad.userNo != user.userNo && type == 2" class="font-weight-bold text-dark">{{ L("AdList.Web.Text.OtherArea") }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="ad.status == 2"></div>
                                    <div v-else-if="ad.status == 3" class="col-lg-6 col-12 ml-auto p-0 text-resp mt-lg-0 mt-3">
                                        <span class="font-weight-bold text-primary">{{ L("AdList.Text.Completed") }}</span>
                                    </div>
                                </div>
                            </div>
                            <!--end: Item-->
                        </div>
                        <!--end::Bottom-->
                    </div>
                </div>
                <!--end::Card-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
    <!--end::Content-->
    <div class="loading" v-if="busy"></div>
</template>

<script lang="ts">
import service from "../common/service";
import { AdvertStatus, HelpSubject, MembershipType } from "../common/enums";
import { dateString } from "../common/date-format";
import { isNullOrEmpty } from "../common/validations";
import { User } from "../entities/User";
import { localize } from "../common/localize-service";
import { Variables } from "@/common/variables";
// <img class="mb-2 mx-auto" style="max-width: 50px;max-height: 67px;border-radius: 5px;" alt="photo" v-bind:src="isNullOrEmpty(ad.photo) ? (ad.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + ad.photo" />

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            adList: [],
            helpSubject: HelpSubject,
            dateString: dateString,
            isNullOrEmpty: isNullOrEmpty,
            type: 1,
            L: localize,
            busy: false,
            imagePath: Variables.ImagePath,
            userCheck: Number,
            night : Date,
            areasText : "",
        };
    },
    mounted() {
        this.night = new Date(Date.now() - 1000 * 60 * 60 * 24);
        this.night.setHours(0);
        this.night.setMinutes(0);
        this.night.setSeconds(0);
        this.night.setMilliseconds(0);
        this.userCheck = service.getInstance().TypeControl(this.user);
        if (this.userCheck != 3 && this.userCheck != 5 && this.userCheck != 7) {
            this.GetList(this.type);
        }

        this.user.areas.filter(c => c.isActive).forEach(area =>{
            this.areasText += area.area + ", "
        });
        this.user.extraAreas.filter(c => c.isActive).forEach(area =>{
            this.areasText += area.area + ", "
        });
    },
    methods: {
        GetList(type) {
            this.busy = true;
            var serviceUrl = "Tevkil/MyAreasTevkilList";
            switch (type) {
                case 1: //bölgemdeki ilanlar
                    serviceUrl = "Tevkil/MyAreasTevkilList";
                    break;
                case 2: //diğer bölgedeki ilanlar
                    serviceUrl = "Tevkil/OtherAreasTevkilList";
                    break;
                case 3: //çözümlenmiş ilanlar
                    serviceUrl = "Tevkil/ArchiveTevkilList";
                    break;
                case 4: //cevap verdiğim ilanlar
                    serviceUrl = "Tevkil/MyAnswerTevkilList";
                    break;
            }

            service
                .getInstance()
                .GetAdvertList(serviceUrl)
                .then((response) => {
                    this.adList = response.result;
                })
                .catch((error) => {})
                .finally(() => (this.busy = false));
        },
        changeType(type) {
            this.type = type;
            this.GetList(type);
        },
        advertDetail(item) {
            if (this.busy) return;
            this.busy = true;
            this.$router.push({ path: `/ilan-detay/${item.id}` });
        },
        answerAd(item) {
            if (this.busy) return;
            this.busy = true;
            this.$router.push({ path: `/ilanlar/${item.id}` });
        },
    },
};
</script>
