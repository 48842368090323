
import { User } from "@/entities/User";
import { dateString, hourString } from "@/common/date-format";
import { MembershipType } from "@/common/enums";
import { AccountEditModel } from "@/models/AccountEditModel";
import service from "@/common/service";
import { ApiResponse } from "@/models/ApiResponse";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";
import { localize } from "@/common/localize-service";

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            dateString: dateString,
            membershipType: MembershipType,
            hourString: hourString,
            L: localize,
            busy: false,
            workingHours: [],
        };
    },
    mounted() {
        if (this.user.membershipType != MembershipType["Premium Üyelik"]) {
            this.user.receiveMessage = false;
        }
       
        this.workingHours.push({ value: "Hafta içi", display: "Hafta içi" });
        this.workingHours.push({ value: "Hafta sonu", display: "Hafta sonu" });
        this.workingHours.push({ value: "Pazar hariç", display: "Pazar hariç" });
        this.workingHours.push({ value: "Tüm günler", display: "Tüm günler" });
    },
    methods: {
        postForm() {
            if (this.busy) return;
            this.busy = true;
            var editModel = new AccountEditModel();

            // this.model.startHour = typeof this.model.startHour === "object"? onlyHours(this.model.startHour) : this.model.startHour;
            // this.model.endHour = typeof this.model.endHour === "object"? onlyHours(this.model.endHour) : this.model.endHour;

            editModel.EndHour = this.user.endHour;
            editModel.WorkingHour = this.user.workingHour;
            editModel.StartHour = this.user.startHour;
            editModel.PublicAccount = this.user.publicAccount;
            editModel.ReceiveMessage = this.user.receiveMessage;
            editModel.ReceiveSMS = this.user.receiveSms;
            editModel.ReceiveEmail = this.user.receiveEmail;

            service
                .getInstance()
                .AccountEdit(editModel)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.$emit("updateUser", response.result);
                        if(navigator.cookieEnabled){
                            localStorage.setItem("user",JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                        CfAlertSuccess(response.message);
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => {
                    this.busy = false;
                });
        },
    },
};
