<template>
    <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">{{L('ChangePhone.Text.Title')}}</h3>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
            <div class="card-body">
                <label class="text-left mb-5">{{L('ChangePhone.Text.Warning')}}</label>
                <div v-if="state == 1">
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('ChangePhone.Label.OldPhone')}}</label>
                        <div class="col-lg-9 col-xl-6">
                            <span class="form-control form-control-lg text-left">{{user.phoneNumber}}</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('ChangePhone.Label.NewPhone')}}</label>
                        <div class="col-lg-9 col-xl-6">
                            <input type="text" v-mask="'(5##) ### ####'" class="form-control form-control-lg form-control-solid" v-model="model.Phone" @keypress="isPhoneValid = true"/>
                            <label v-if="!isPhoneValid" class="text-danger float-left mt-3">{{L('ChangePhone.Error.Phone')}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"></label>
                        <div class="col-lg-9 col-xl-6">
                            <button type="button" class="btn btn-success float-left" @click="sendCode">{{L('ChangePhone.Button.SendCode')}}</button>
                        </div>
                    </div>
                </div>

                 <div v-if="state == 2">
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('ChangePhone.Label.NewPhone')}}</label>
                        <div class="col-lg-9 col-xl-6">
                            <span class="form-control form-control-lg text-left">{{model.Phone}}</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('ChangePhone.Label.Code')}}</label>
                        <div class="col-lg-9 col-xl-6">
                            <input type="text" class="form-control form-control-lg form-control-solid" v-model="model.Code" @keypress="isCodeValid = true"/>
                            <label v-if="!isCodeValid" class="text-danger float-left mt-3">{{L('ChangePhone.Error.Code')}}</label>
                        </div>
                        
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"></label>
                        <div class="col-lg-9 col-xl-6">
                            <button type="button" class="btn btn-success float-left" @click="changePhone">{{L('ChangePhone.Button.Save')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>

<script lang="ts">
import { User } from '@/entities/User';
import { localize } from '@/common/localize-service';
import { ChangePhoneModel } from "@/models/ChangePhoneModel";
import { isEmailValid, isNullOrEmpty, isPhoneValid } from '@/common/validations';
import { CfAlertError, CfAlertSuccess } from '@/common/alerts';
import service from '@/common/service';
import { ApiResponse } from '@/models/ApiResponse';
import { MembershipType } from '@/common/enums';

export default{
    props:{
        user : Object,
    },
    emits : ["updateUser"],
    data(){
        return{
            L : localize,
            model : new ChangePhoneModel(),
            isPhoneValid : true,
            isCodeValid : true,
            busy : false,
            state : 1
        }
    },
    mounted(){
        
    },
    methods: {
        sendCode(){
            if(this.busy) return;
            this.busy = true;

            let phoneCheck = this.model.Phone.replaceAll("(", "");
            phoneCheck = phoneCheck.replaceAll(")", "");
            phoneCheck = phoneCheck.replaceAll(" ", "");
            if (!isPhoneValid(phoneCheck) || isNullOrEmpty(this.model.Phone)) {
                this.isPhoneValid = false;
                return;
            }
            
            if(phoneCheck == this.user.phoneNumber){
                CfAlertError(localize("ChangePhone.Error.NoChange"));
                this.busy = false;
                return;
            }
            this.model.Phone = phoneCheck;

            service.getInstance().EditPhone(this.model)
            .then((response : ApiResponse) => {
                if(response.isSucceed){
                    CfAlertSuccess(response.message);
                    this.state = 2;
                }else{
                    CfAlertError(response.message);
                }
            })
            .catch(error => {
                service.getInstance().ErrorMessage(error)
            })
            .finally(() => this.busy = false);
        },
        changePhone(){
            if(this.busy) return;
            this.busy = true;

            if(isNullOrEmpty(this.model.Code) || this.model.Code.length != 5){
                this.isCodeValid = false;
                this.busy = false;
                return;
            }

            this.model.Code = this.model.Code.toLowerCase();

            service.getInstance().ChangePhone(this.model)
            .then((response : ApiResponse) => {
                if(response.isSucceed){
                    CfAlertSuccess(localize("ChangePhone.Success.Message"));
                    this.$emit("updateUser",response.result);
                    this.model = new ChangePhoneModel();
                    this.state = 1;
                     if(navigator.cookieEnabled){
                            localStorage.setItem("user",JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                }else{
                    CfAlertError(response.message);
                }
            })
            .catch(error => {
                service.getInstance().ErrorMessage(error)
            })
            .finally(() => this.busy = false);
        }
    }
}
</script>