<template>
    <div style="background: #fff">
        <div class="bg-tevkil">
            <nav class="navbar navbar-expand-lg navbar-dark pt-3 container">
                <div class="d-flex align-items-center justify-content-between nav-container w-100">
                    <router-link class="nav-link text-light p-0" to="login">
                        <img src="../assets/images/logoweb.png" style="height: 55px" />
                    </router-link>
                    <a @click="logout" class="text-left border-0 text-light f-600 mr-5 cursor-pointer" style="font-size: 16px">Çıkış yap</a>
                </div>
            </nav>
        </div>

        <div v-if="state == 1" class="padding-y container">
            <div class="contact-padding text-center d-flex justify-content-center flex-column">
                <label class="d-block" style="font-size: 16px">{{ $route.params.message }}</label>

                <button v-if="$route.params.type == 2" @click="extend" class="btn btn-light btn-lg mt-4 d-block col-12 col-md-6 mx-auto">{{ L("General.Button.Extend") }}</button>
                <button @click="state = 2" class="btn btn-primary btn-lg mt-4 d-block col-12 col-md-6 mx-auto">{{ L("AreaAdd.Premium.Btn") }}</button>

                <div class="card-body my-5">
                    <div class="row justify-content-center text-center my-0 my-md-25">
                        <div class="col-12 col-md-4 bg-white rounded shadow">
                            <div class="py-20 px-4">
                                <h4 class="text-dark mb-15">{{ L("PremiumPageTemelLbl") }}</h4>
                                <br />
                                <div class="text-dark mb-10 d-flex flex-column">
                                    <span class="d-block">{{ L("PremiumPageIlanVermeLbl") }}</span>
                                    <span class="d-block">{{ L("PremiumPageIlanVermeTemel") }}</span>
                                    <div class="separator separator-solid my-3"></div>
                                    <span class="d-block">{{ L("PremiumPageIlanCevaplamaLbl") }}</span>
                                    <span class="d-block">{{ L("PremiumPageIlanCevapTemel") }}</span>
                                    <div class="separator separator-solid my-3"></div>
                                    <span class="d-block">{{ L("PremiumPageOzelMesajLbl") }}</span>
                                    <span class="d-block">{{ L("PremiumPageOzelMesajTemel") }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 bg-primary my-md-n3 rounded shadow-lg">
                            <div class="py-20 px-4">
                                <h4 class="text-white mb-15">{{ L("PremiumPagePremiumLbl") }}</h4>
                                <br />
                                <div class="text-dark mb-10 d-flex flex-column">
                                    <span class="d-block text-white">{{ L("PremiumPageIlanVermeLbl") }}</span>
                                    <span class="d-block text-white">{{ L("PremiumPageIlanVermePre") }}</span>
                                    <div class="separator separator-solid my-3"></div>
                                    <span class="d-block text-white">{{ L("PremiumPageIlanCevaplamaLbl") }}</span>
                                    <span class="d-block text-white">{{ L("PremiumPageIlanCevapPre") }}</span>
                                    <div class="separator separator-solid my-3"></div>
                                    <span class="d-block text-white">{{ L("PremiumPageOzelMesajLbl") }}</span>
                                    <span class="d-block text-white">{{ L("PremiumPageOzelMesajPre") }}</span>
                                </div>
                                <a v-if="price" class="px-7 py-3 bg-white d-inline-flex flex-center rounded-lg bg-white">
                                    <span class="pr-2 text-primary opacity-70">₺</span>
                                    <span class="pr-2 font-size-h1 font-weight-bold text-primary">{{ price }}</span>
                                    <span class="text-primary opacity-70">/&nbsp;&nbsp;yıllık</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="container mt-5">
            <div class="card card-custom gutter-b">
                <div class="card card-custom">
                    <!--begin::Header-->
                    <div class="card-header py-3">
                        <div class="card-title align-items-start flex-column">
                            <h3 class="card-label font-weight-bolder text-dark">Ödeme Bilgi ve Bildirimi Sayfası</h3>
                        </div>
                    </div>

                    <div class="card-body">
                        <p>İşleminizin tamamlanması için <b class="text-danger">[a]</b> ödeme tutarını aşağıdaki banka hesaplarına havale/eft yaptıktan <b style="text-decoration: underline !important">sonra</b>, <b class="text-danger">[b]</b> ödeme bildirim formunu doldurup gönder tuşuna basınız.<b class="text-danger">[c]</b> Ödemeniz hesabımıza ulaştığı andan itibaren dakikalar içerisinde işleminiz gerçekleşecektir.</p>
                        <p>
                            <i class="fas fa-star-of-life text-danger mr-2"></i>Ödeme işleminizin daha hızlı onaylanması için Havale / EFT yaparken AÇIKLAMA kısmına ÜYE NUMARANIZI (<b>{{ user.userNo }}</b
                            >) yazmanız yeterlidir.
                        </p>
                    </div>

                    <!--end::Header-->
                    <div class="card-body">
                        <div class="p-3 text-center" style="background: #f0f0f0">
                            <h4 class="text-primary">BANKA HESAP NUMARAMIZ</h4>
                        </div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th><b>BANKA</b></th>
                                    <th><b>IBAN</b></th>
                                    <th><b>HESAP ADI</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                        <td><b>VAKIF BANK</b></td>
                                        <td>TR36 0001 5001 5800 7318 7657 91</td>
                                        <td>MURAT DURSUN</td>
                                    </tr>
                                    <tr>
                                        <td><b>İŞ BANK</b></td>
                                        <td>TR34 0006 4000 0013 4940 6500 67</td>
                                        <td>MURAT DURSUN</td>
                                    </tr>
                                    <tr>
                                        <td><b>GARANTİ BANK</b></td>
                                        <td>TR02 0006 2001 0400 0006 6271 81 </td>
                                        <td>MURAT DURSUN</td>
                                    </tr>
                                    <tr>
                                        <td><b>YAPI KREDİ</b></td>
                                        <td>TR30 0006 7010 0000 0031 1434 45</td>
                                        <td>MURAT DURSUN</td>
                                    </tr>
                                </tbody>
                        </table>
                    </div>

                    <div class="card-body">
                        <div class="p-3 text-center" style="background: #f0f0f0">
                            <h4 class="text-primary">HAVALE / EFT ÖDEME BİLDİRİM FORMU</h4>
                            <h6 class="text-danger">Ödemenizi havale/eft yaptıktan sonra bu formu doldurup Gönder tuşuna basınız</h6>
                        </div>
                        <div class="mt-3">
                            <div class="form-group row align-items-center">
                                <label class="col-md-3 col-12 dot m-0">Üye Adı Soyadı</label>
                                <div class="col-md-9 col-12">
                                    <input class="form-control" v-model="model.Name" />
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="col-md-3 col-12 dot m-0">Ödeme yapan hesap Adı Soyadı</label>
                                <div class="col-md-9 col-12">
                                    <input class="form-control" v-model="model.PaymentName" />
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="col-md-3 col-12 dot m-0">Ödeme Türü</label>
                                <div class="col-md-9 col-12">
                                    <select class="form-control form-select" v-model="model.Type">
                                        <option>Premium Üyeliğe geçiş</option>
                                        <option>Şehir ekleme</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="col-md-3 col-12 dot m-0">Ödeme Tutarı</label>
                                <div class="col-md-9 col-12">
                                    <input type="number" v-model="model.Price" class="form-control col-6" />
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="col-md-3 col-12 dot m-0">Ödeme Açıklaması</label>
                                <div class="col-md-9 col-12">
                                    <input v-model="model.Description" class="form-control col-6" />
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="col-md-3 col-12 dot m-0">Hangi banka hesabımıza ödeme yaptınız?</label>
                                <div class="col-md-9 col-12 d-flex">
                                    <div class="form-check form-check-inline d-flex">
                                        <input class="form-check-input" type="radio" v-model="model.Bank" value="Vakıfbank" />
                                        <label class="form-check-label">Vakıfbank</label>
                                    </div>
                                    <div class="form-check form-check-inline d-flex">
                                        <input class="form-check-input" type="radio" v-model="model.Bank" value="İşbank" />
                                        <label class="form-check-label">İşbank</label>
                                    </div>
                                    <div class="form-check form-check-inline d-flex">
                                        <input class="form-check-input" type="radio" v-model="model.Bank" value="Garanti" />
                                        <label class="form-check-label">Garanti</label>
                                    </div>
                                    <div class="form-check form-check-inline d-flex">
                                        <input class="form-check-input" type="radio" v-model="model.Bank" value="Yapı Kredi" />
                                        <label class="form-check-label">Yapı Kredi</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="col-md-3 col-12 dot m-0">Ödeme Tarihi</label>
                                <div class="col-md-9 col-12">
                                    <input v-model="model.Date" type="date" class="form-control" />
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="col-md-3 col-12 dot m-0">Bize iletmek istedikleriniz</label>
                                <div class="col-md-9 col-12">
                                    <textarea v-model="model.Message" type="date" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="col-12 btn btn-success text-center mt-3" @click="send">Gonder</button>
                    </div>

                    <div class="card-body">
                        <label class="text-primary">ÜCRET İADESİ KOŞULLARI :</label>
                        <p class="text-muted">Üyelik ücreti; bölgenize ait ilanların tarafınıza SMS ile bildirilmesi, gelen ilanlara cevap verebilme, cevaplarınızın ilan sahiplerine iletilmesi hizmetlerini kapsamaktadır.</p>
                        <p class="text-muted">Yukarıda belirtilen hizmetlerin hiç kullanılmaması halinde ücret iadesi talebiniz yerine getirilir. Bu hizmetlerin bir kez dahi kullanılması halinde ücret iadesi yapılamaz.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import service from "@/common/service";
import { localize } from "@/common/localize-service";
import { isNullOrEmpty } from "@/common/validations";
import { ApiResponse } from "@/models/ApiResponse";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";

class PaymentModel {
    Name: string;
    PaymentName: string;
    Type: string;
    Price: number;
    Description: string;
    Bank: string;
    Date: Date;
    Message: string;
}

export default {
    data() {
        return {
            L: localize,
            price: "",
            user: navigator.cookieEnabled? JSON.parse(localStorage.getItem("user")) : globalThis.user,
            state: 1,
            model: new PaymentModel(),
        };
    },
    mounted() {
        if (isNullOrEmpty(this.$route.params.message)) {
            service.getInstance().Logout();
        }
        this.userCheck = service.getInstance().TypeControl(this.user);
        if (this.userCheck == 1 || this.userCheck == 2) {
            this.$router.push("/ilanlar");
        }
        this.model.Name = this.user.firstName + " " + this.user.lastName;
        this.model.PaymentName = this.user.firstName + " " + this.user.lastName;
        this.model.Description = this.user.userNo+ "(Açıklama kısmına SADECE üye numaranızı yazınız)";
        service
            .getInstance()
            .GetCityGroup(this.user.city)
            .then((response) => {
                this.price = response.result.price;
            });
    },
    methods: {
        logout() {
            service.getInstance().Logout();
        },
        send() {
            if (isNullOrEmpty(this.model.Name) || isNullOrEmpty(this.model.PaymentName) || isNullOrEmpty(this.model.Type) || isNullOrEmpty(this.model.Description) || isNullOrEmpty(this.model.Bank) || isNullOrEmpty(this.model.Date) || this.model.Price == null) return;
            service
                .getInstance()
                .Payment(this.model)
                .then((response: ApiResponse) => {
                    console.log(response);
                    if (response.isSucceed) {
                        CfAlertSuccess(response.message);
                        service.getInstance().Logout();
                    } else {
                        CfAlertError(response.message);
                    }
                });
        },
        extend() {
            service
                .getInstance()
                .ExtendMemb()
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        if(navigator.cookieEnabled){
                            localStorage.setItem("user", JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                        this.$router.push("/ilanlar");
                    } else {
                        CfAlertError(response.message);
                    }
                });
        },
    },
};
</script>

<style></style>
