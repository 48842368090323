
import service from "@/common/service";
import { localize } from "@/common/localize-service";
import { isNullOrEmpty } from "@/common/validations";
import { ApiResponse } from "@/models/ApiResponse";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";

class PaymentModel {
    Name: string;
    PaymentName: string;
    Type: string;
    Price: number;
    Description: string;
    Bank: string;
    Date: Date;
    Message: string;
}

export default {
    data() {
        return {
            L: localize,
            price: "",
            user: navigator.cookieEnabled? JSON.parse(localStorage.getItem("user")) : globalThis.user,
            state: 1,
            model: new PaymentModel(),
        };
    },
    mounted() {
        if (isNullOrEmpty(this.$route.params.message)) {
            service.getInstance().Logout();
        }
        this.userCheck = service.getInstance().TypeControl(this.user);
        if (this.userCheck == 1 || this.userCheck == 2) {
            this.$router.push("/ilanlar");
        }
        this.model.Name = this.user.firstName + " " + this.user.lastName;
        this.model.PaymentName = this.user.firstName + " " + this.user.lastName;
        this.model.Description = this.user.userNo+ "(Açıklama kısmına SADECE üye numaranızı yazınız)";
        service
            .getInstance()
            .GetCityGroup(this.user.city)
            .then((response) => {
                this.price = response.result.price;
            });
    },
    methods: {
        logout() {
            service.getInstance().Logout();
        },
        send() {
            if (isNullOrEmpty(this.model.Name) || isNullOrEmpty(this.model.PaymentName) || isNullOrEmpty(this.model.Type) || isNullOrEmpty(this.model.Description) || isNullOrEmpty(this.model.Bank) || isNullOrEmpty(this.model.Date) || this.model.Price == null) return;
            service
                .getInstance()
                .Payment(this.model)
                .then((response: ApiResponse) => {
                    console.log(response);
                    if (response.isSucceed) {
                        CfAlertSuccess(response.message);
                        service.getInstance().Logout();
                    } else {
                        CfAlertError(response.message);
                    }
                });
        },
        extend() {
            service
                .getInstance()
                .ExtendMemb()
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        if(navigator.cookieEnabled){
                            localStorage.setItem("user", JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                        this.$router.push("/ilanlar");
                    } else {
                        CfAlertError(response.message);
                    }
                });
        },
    },
};
