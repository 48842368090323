
import { localize } from "@/common/localize-service";

export default {
    methods: {
        closeModal: function () {
            this.$parent.closeModal();
        },
        L: localize,
    },
};
