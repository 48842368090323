<template>
    <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">{{ L("AreaSettings.Text.Title") }}</h3>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
            <div class="card-body">
                <div class="form-group row">
                    <div class="col-6" style="border-right: 1px solid rgba(0, 0, 0, 0.05)">
                        <label class="col-12 text-left font-weight-bold text-dark">{{ L("AreaSettings.Text.ActiveHead") }}</label>
                        <label class="col-12 text-left text-muted">{{ L("AreaSettings.Text.ActiveSub") }}</label>
                        <div class="col-12 mt-5 mb-3" v-for="area in user.areas" :key="area.area">
                            <label class="checkbox w-100"><input type="checkbox" v-model="area.isActive" /><span class="mr-3"></span>{{ area.area }}</label>
                        </div>
                        <div class="col-12 mt-5 mb-3" v-for="extraArea in user.extraAreas" :key="extraArea.area">
                            <label class="checkbox w-100"><input type="checkbox" v-model="extraArea.isActive" /><span class="mr-3"></span>{{ extraArea.area }}</label>
                        </div>
                    </div>
                    <div class="col-6">
                        <label class="col-12 text-left font-weight-bold text-dark">{{ L("AreaSettings.Text.SmsHead") }}</label>
                        <label class="col-12 text-left text-muted">{{ L("AreaSettings.Text.SmsSub") }}</label>
                        <div class="col-12 mt-5 mb-3">
                            <label class="checkbox w-100"><input type="radio" name="Sms" v-model="selectedArea" :value="false" /><span class="mr-3"></span>SMS almak istemiyorum</label>
                        </div>
                        <div class="col-12 mt-5 mb-3" v-for="area in areaList" :key="area.area">
                            <label class="checkbox w-100"><input type="radio" name="Sms" v-model="selectedArea" :value="area.areaId" /><span class="mr-3"></span>{{ area.area }}</label>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-success col-12" @click="postForm">{{ L("AreaSettings.Button.Save") }}</button>

                <div class="separator separator-dashed my-5"></div>

                <div v-if="user.membershipType == 3" class="form-group row mt-5 col-12">
                    <label class="col-12 text-left font-weight-bold text-dark">{{ L("AreaSettingsExtraAreaHead") }}</label>
                    <label class="col-12 text-left text-muted">{{ L("AreaSettingsExtraAreaSub") }}</label>
                    <div class="d-flex align-items-center my-2">
                        <span class="form-control d-inline-block mr-3 text-center" style="width: 75px; line-height: 27px">{{ arrayLength }}</span> <span> x50 TL</span>
                    </div>
                    <div class="form-group">
                        <label class="text-left text-muted dot">Eklemek istediğiniz şehir(ler)</label>
                        <input v-model="areas" class="form-control form-control-solid mt-3" id="cities" type="text" />
                    </div>

                    <div v-if="user.membershipType == 3" class="text-center">
                        <button type="button" @click="addArea" class="btn btn-success col-12 col-md-6 mt-3">{{ L("AreaSettingsContinueBtn") }}</button>
                    </div>

                    <label class="font-weight-bold text-muted mt-3" v-else-if="userCheck == 1">{{ L("AreaAdd.Error.Misafir") }}</label>
                    <label class="font-weight-bold text-muted mt-3" v-else-if="userCheck == 2">{{ L("AreaAdd.Error.Hatali") }}</label>

                    <div v-else-if="userCheck == 4" class="mt-3 text-center">
                        <label class="font-weight-bold text-muted">{{ L("AreaAdd.Error.Iptal") }}</label>
                        <router-link type="button" class="btn btn-success col-12 col-md-6" to="/ayarlar/premium">{{ L("AreaAdd.Iptal.PreBtn") }}</router-link>
                    </div>
                </div>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>

<script lang="ts">
import { localize } from "@/common/localize-service";
import service from "@/common/service";
import { ApiResponse } from "@/models/ApiResponse";
import { CfAlertError, CfAlertSuccess } from "@/common/alerts";
import Tagify from "@yaireo/tagify";
import { MembershipType } from "@/common/enums";
import { isNullOrEmpty } from "@/common/validations";

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            L: localize,
            busy: false,
            areaList: [],
            selectedArea: false,
            areas: [],
            arrayLength: 0,
            userCheck: Number,
            price: Number,
            cities: String,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);

        this.areaList = this.areaList.concat(this.user.areas);
        this.areaList = this.areaList.concat(this.user.extraAreas);
        if (this.areaList.find((c) => c.sms) != null) {
            this.selectedArea = this.areaList.find((c) => c.sms).areaId;
        }
        service
            .getInstance()
            .GetCities()
            .then((response) => {
                var input = document.getElementById("cities");
                var user1;
                 if(navigator.cookieEnabled){
                    user1 =  JSON.parse(localStorage.getItem("user"));
                }else{
                    user1 = globalThis.user;
                }
                var areas1 = response.result.filter((c) => user1.areas.find((d) => d.area == c.name) == null && user1.extraAreas.find((e) => e.area == c.name) == null);
                var cities = areas1.map((item) => {
                    return item.name;
                });

                var tagify = new Tagify(input, {
                    whitelist: cities,
                    enforceWhitelist: true,
                });
                tagify.on("change", (e) => {
                    this.areas = e.detail.value;
                    if (this.areas == "") {
                        this.arrayLength = 0;
                        this.price = 0;
                    } else {
                        this.cities = "";
                        this.arrayLength = JSON.parse(e.detail.value).length;
                        JSON.parse(this.areas).forEach((item) =>{
                            this.cities += item.value + ", "
                        });
                        this.price = 50 * this.arrayLength;
                    }
                });
            });
    },
    methods: {
        postForm() {
            if (this.busy) return;
            this.busy = true;

            this.areaList.forEach((item) => {
                item.sms = item.areaId == this.selectedArea ? true : false;
            });

            service
                .getInstance()
                .EditAreas(this.user)
                .then((response: ApiResponse) => {
                    if (response.isSucceed) {
                        this.$emit("updateUser", response.result);
                        if(navigator.cookieEnabled){
                            localStorage.setItem("user", JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                        CfAlertSuccess(response.message);
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        addArea() {
            var selectedAreas = "";
            JSON.parse(this.areas).forEach((item) => {
                selectedAreas += item.value + ",";
            });
            if (!isNullOrEmpty(selectedAreas)) {
                this.$router.push({ path: "/odeme",query : {type : "Şehir ekleme", price : this.price, cities : this.cities} });
            }
        },
    },
};
</script>
