<template>
    <div class="content d-flex flex-column flex-column-fluid">
        <div class="subheader py-2 py-lg-4 subheader-transparent">
            <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <!--begin::Details-->
                <div class="d-flex align-items-center flex-wrap mr-2">
                    <!--begin::Title-->
                    <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ L("OfferTitle") }}</h5>
                    <!--end::Title-->
                </div>
                <!--end::Details-->
            </div>
        </div>

        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
                <div class="card gutter-b border-0 card-shadow">
                    <div class="card-body p-4">
                        <!--begin::Top-->
                        <div class="d-flex">
                            <!--begin::Pic-->
                            <div class="mr-lg-5 mr-3 text-center">
                                <div class="text-center">
                                    <div class="card-photo mb-2" :style="{ backgroundImage: 'url(' + (isNullOrEmpty(ad.photo) ? (ad.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + ad.photo) + ')' }"></div>
                                    <span class="font-weight-bold text-dark-50">{{ ad.userCity }}</span>
                                    <span class="font-weight-bold text-dark-50 d-block">{{ ad.userNo }}</span>
                                </div>
                            </div>
                            <!--end::Pic-->
                            <!--begin::User-->
                            <div class="w-100">
                                <!--begin::Name-->
                                <label class="d-flex align-items-center text-dark font-size-h6 font-weight-bold flex-wrap">
                                    <span class="mr-lg-3 mr-0 mobile-w-100">{{ "# " + ad.advertNo }}</span>
                                    <span class="mobile-hide">-</span>
                                    <span class="ml-lg-3 ml-0 mobile-w-100"><i class="far fa-clock mr-1 text-dark"></i>{{ dateString(ad.createDate) }}</span>
                                </label>
                                <!--end::Name-->
                                <!--begin::Contacts-->
                                <div class="d-flex flex-wrap my-2">
                                    <label class="text-muted font-weight-bold mr-lg-5 mr-3 mb-lg-0 mb-2 mobile-w-100"><i class="fas fa-briefcase mr-1"></i>{{ helpSubject[ad.advertType] }}</label>
                                    <label class="text-muted font-weight-bold">
                                        <i class="fas fa-map-marker-alt mr-1"></i>
                                        <span class="mr-1 text-dark" style="font-weight: bold">{{ ad.userCity }}</span>
                                        <i class="fas fa-arrow-right"></i>
                                        <span class="ml-1" style="color: red; font-weight: bold">{{ ad.city }}</span>
                                        <span>{{ " / " + ad.district }}</span>
                                    </label>
                                </div>
                                <!--end::Contacts-->
                                <!--begin::Content-->
                                <div class="mobile-hide">
                                    <div class="font-weight-bold text-dark-50 py-2 py-lg-2 mr-lg-5 text-justify">{{ ad.detail }}</div>
                                </div>
                                <!--end::Content-->
                            </div>
                            <!--begin::User-->
                            <!--begin::Pic-->
                            <div class="ml-lg-5 ml-3 text-center" v-if="ad.status == 3">
                                <div class="text-center">
                                    <div class="card-photo mb-2" :style="{ backgroundImage: 'url(' + (isNullOrEmpty(ad.jobUser.photo) ? (ad.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + ad.jobUser.photo) + ')' }"></div>
                                    <span class="font-weight-bold text-dark-50">{{ ad.jobUser.city }}</span>
                                    <span class="font-weight-bold text-dark-50 d-block">{{ ad.jobUser.userNo }}</span>
                                </div>
                            </div>
                            <!--end::Pic-->
                        </div>
                        <!--begin::Content-->
                        <div class="mobile-show">
                            <div class="font-weight-bold text-dark-50 mt-3 text-justify">{{ ad.detail }}</div>
                        </div>
                        <!--end::Content-->
                        <!--end::Top-->
                        <!--begin::Separator-->
                        <div class="separator separator-solid my-3"></div>
                        <!--end::Separator-->
                        <!--begin::Bottom-->
                        <div class="ad-card-bottom">
                            <!--begin: Item-->
                            <div class="d-flex align-items-center justify-content-start my-1 col-6 col-lg-4">
                                <span class="mr-4">
                                    <i class="fas fa-wallet icon-2x text-muted"></i>
                                </span>
                                <div class="d-flex flex-column text-dark-75">
                                    <span class="font-weight-bolder font-size-sm">{{ L("AdList.Web.Label.Budget") }}</span>
                                    <span class="font-weight-bolder font-size-h5"> <span class="text-dark-50 font-weight-bold">₺ </span>{{ ad.budget }}</span>
                                </div>
                            </div>
                            <!--end: Item-->
                            <!--begin: Item-->
                            <div class="d-flex align-items-center my-1 justify-content-lg-center justify-content-end col-6 col-lg-4">
                                <span class="mr-4">
                                    <i class="far fa-calendar icon-2x text-muted"></i>
                                </span>
                                <div class="d-flex flex-column text-dark-75 text-center">
                                    <span class="font-weight-bolder font-size-sm">{{ L("AdList.Web.Label.LastDate") }}</span>
                                    <span class="font-weight-bolder font-size-h5"> <span class="text-dark-50 font-weight-bold"></span>{{ dateString(ad.endDate) }}</span>
                                </div>
                            </div>
                            <!--end: Item-->
                        </div>
                        <!--end::Bottom-->
                    </div>
                </div>

                <div v-if="ad.userNo">
                    <div class="card card-custom gutter-b" v-if="ad.userNo == user.userNo">
                        <div v-if="ad.status == 3">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="mr-3 flex-grow-1 w-100">
                                        <div>
                                            <label class="h6 d-block text-success mb-5">{{ L("Advert.Text.Completed1") }}</label>
                                            <label class="h6 d-block text-dark">{{ L("Advert.Text.Completed2") }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else-if="night > new Date(ad.endDate)">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="mr-3 flex-grow-1 w-100">
                                        <div>
                                            <label class="h6 d-block text-success mb-5">{{ L("Advert.Text.Expired1") }}</label>
                                            <label class="h6 d-block text-danger">{{ L("Advert.Text.Expired2") }}</label>
                                            <label class="h6 d-block text-dark">{{ L("Advert.Text.Expired3") }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else>
                            <div v-if="ad.answers.length == 0">
                                <button type="button" @click="deleteAdvert(ad)" class="btn btn-danger w-100">{{ L("advert.delete") }}</button>
                            </div>
                            <div v-else>
                                <div class="d-flex align-items-center justify-content-center py-4">
                                    <label class="text-center font-weight-bold">{{ "Gelen Cevaplar (" + ad.answers.length + ")" }}</label>
                                </div>
                                <div class="separator separator-dashed"></div>

                                <div v-for="answer in ad.answers" :key="answer.userNo">
                                    <div class="card-body">
                                        <div class="d-flex">
                                            <div class="flex-shrink-0 mr-5">
                                                <div class="text-center">
                                                    <div class="card-photo mb-2" :style="{ backgroundImage: 'url(' + imagePath + 'default.jpg' + ')' }"></div>
                                                </div>
                                            </div>
                                            <div class="mr-3 flex-grow-1 w-100">
                                                <div>
                                                    <div class="d-flex justify-content-between mb-2">
                                                        <span class="text-left m-0 p-0 d-block h3" style="border: 0">{{ answer.userNo }}</span>
                                                        <span class="text-left m-0 p-0 d-block h3" style="border: 0">{{ answer.price + " TL" }}</span>
                                                    </div>

                                                    <span class="text-left m-0 p-0 d-block" style="border: 0">{{ answer.city + " / " + answer.district }}</span>
                                                    <span class="text-left m-0 p-0 d-block" style="border: 0">{{ "Mesleki Tecrübe: " + answer.exp + " yıl" }}</span>
                                                    <span class="text-left m-0 p-0 d-block" style="border: 0">{{ workingType[answer.workingType] }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center flex-wrap mt-3">
                                            <div class="col-6">
                                                <button v-if="answer.isOpen" type="button" class="btn btn-secondary w-100" @click="ontapUserOk(answer.userNo)">Üye İle Çözüldü</button>
                                            </div>
                                            <div class="col-6">
                                                <button type="button" class="btn btn-danger w-100" @click="callUser(answer.userNo, answer.isOpen)">Ara</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="separator separator-dashed"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="card card-custom gutter-b" v-if="ad.answers.find((c) => c.userNo == user.userNo)">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 mr-5">
                                        <div class="text-center">
                                            <div class="card-photo mb-2" :style="{ backgroundImage: 'url(' + (isNullOrEmpty(user.photo) ? (user.gender == 'Kadın' ? imagePath + 'kadin.jpg' : imagePath + 'erkek.jpg') : imagePath + user.photo) + ')' }"></div>
                                        </div>
                                    </div>
                                    <div class="mr-3 flex-grow-1 w-100">
                                        <div>
                                            <div class="font-weight-bold text-dark-50 py-2 py-lg-2 text-left" style="text-align: justify !important">{{ L("Answer.Text.Description", user.startHour, user.endHour, user.phoneNumber) }}</div>
                                            <div class="font-weight-bold text-dark-50 py-2 py-lg-2 mr-5 text-left">{{ "Av. " + user.firstName + " " + user.lastName }}</div>

                                            <div class="row m-0">
                                                <div class="col-lg-3 col-12 m-0 p-0">
                                                    <span class="text-left h6 dot m-0 p-0">Teklif Tarihi</span>
                                                </div>
                                                <div class="col-lg-9 col-12 m-0 pl-0 pl-lg-3">
                                                    <span v-if="ad.answers" class="text-left h6 m-0 p-0">{{ hourString(ad.answers.find((c) => c.userNo == user.userNo).createDate) }}</span>
                                                </div>
                                            </div>

                                            <div class="row m-0 mt-2">
                                                <div class="col-lg-3 col-12 m-0 p-0">
                                                    <span class="text-left dot h6 m-0 p-0">Teklifiniz</span>
                                                </div>
                                                <div class="col-lg-9 col-12 m-0 pl-0 pl-lg-3">
                                                    <span v-if="ad.answers" class="text-left h6 m-0 p-0">{{ ad.answers.find((c) => c.userNo == user.userNo).price + " TL" }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="busy" class="loading"></div>
</template>

<script lang="ts">
import service from "@/common/service";
import { Advert } from "@/entities/Advert";
import { ApiResponse } from "@/models/ApiResponse";
import { localize } from "@/common/localize-service";
import { isNullOrEmpty } from "@/common/validations";
import { dateString, hourString } from "@/common/date-format";
import { AdvertStatus, HelpSubject, MembershipType, WorkingType } from "@/common/enums";
import { CfAlertError, CfAlertSuccess, CfAlertWarning, CfConfirm } from "@/common/alerts";
import Swal from "sweetalert2";
import { Variables } from "@/common/variables";

class AnswerModel {
    advertId: string;
    userNo: string;
}

export default {
    props: {
        id: String,
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            ad: Object,
            L: localize,
            isNullOrEmpty: isNullOrEmpty,
            dateString: dateString,
            helpSubject: HelpSubject,
            hourString: hourString,
            workingType: WorkingType,
            busy: false,
            advertStatus: AdvertStatus,
            imagePath: Variables.ImagePath,
            night: Date,
        };
    },
    mounted() {
        this.night = new Date(Date.now() - 1000 * 60 * 60 * 24);
        this.night.setHours(0);
        this.night.setMinutes(0);
        this.night.setSeconds(0);
        this.night.setMilliseconds(0);
        this.busy = true;
        service
            .getInstance()
            .GetAdvertDetail("Tevkil/detail", "?id=" + this.$route.params.id)
            .then((response: ApiResponse) => {
                if (response.isSucceed) {
                    this.ad = response.result;
                }
            })
            .catch((error) => {})
            .finally(() => (this.busy = false));
    },
    methods: {
        callUser(userNo, type) {
            if (this.busy) return;
            this.busy = true;
            if (!type) {
                var openAnswerCount = this.ad.answers.filter((c) => c.isOpen).length;
                if (openAnswerCount >= 5) {
                    CfAlertError("En fazla 5 kişinin telefon numarasını görebilirsiniz!");
                    this.busy = false;
                    return false;
                }
            }

            var model = new AnswerModel();
            model.advertId = this.ad.id;
            model.userNo = userNo;

            service
                .getInstance()
                .CallAnswered(model)
                .then((response) => {
                    this.ad = response.result;
                    Swal.fire({
                        icon: "warning",
                        html: localize("Answer.Text.Warning", response.message),
                        confirmButtonText: "Ara",
                        showCancelButton: true,
                        cancelButtonText: "Kapat",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            var tel2 = response.message.replaceAll(" ", "");
                            window.open(`tel:${tel2}`);
                        }
                    });
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));

            // service
            //     .getInstance()
            //     .CallAnswered(model)
            //     .then((response) => {
            //         this.ad = response.result;
            //         service
            //             .getInstance()
            //             .GetApiSettings()
            //             .then((response: ApiResponse) => {
            //                 var tel = response.result.find((x) => x.name === "sms.tel").value;

            //                 Swal.fire({
            //                     icon: "warning",
            //                     html: localize("Answer.Text.Warning.Web", this.user.phoneNumber,tel,tel),
            //                     confirmButtonText: "Ara",
            //                     showCancelButton: true,
            //                     cancelButtonText: "Kapat",
            //                     reverseButtons: true,
            //                 }).then((result) => {
            //                     if (result.isConfirmed) {
            //                         var tel2 = tel.replaceAll(" ", "");
            //                         window.open(`tel:${tel2}`);
            //                     }
            //                 });
            //             });
            //     })
            //     .catch((error) => {
            //         service.getInstance().ErrorMessage(error);
            //     })
            //     .finally(() => (this.busy = false));
        },
        ontapUserOk(userno) {
            if (this.busy) return;
            this.busy = true;

            var data = new AnswerModel();
            data.advertId = this.ad.id;
            data.userNo = userno;
            service
                .getInstance()
                .JobOk(data)
                .then((response) => {
                    this.ad = response.result;
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        deleteAdvert(ad) {
            if (ad.answers == undefined || ad.answers.length == 0) {
                CfConfirm("", localize("advert.doYouDeleteConfirm"), () => {
                    service
                        .getInstance()
                        .AdvertDelete(ad)
                        .then((response) => {
                            CfAlertSuccess(localize("advert.deleteOkMessage"));
                            this.$router.push("/ilanlarim");
                        })
                        .catch((error) => {
                            service.getInstance().ErrorMessage(error);
                        })
                        .finally(() => (this.busy = false));
                });
            }
        },
    },
};
</script>
