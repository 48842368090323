
import service from "@/common/service";
import { Advert } from "@/entities/Advert";
import { ApiResponse } from "@/models/ApiResponse";
import { localize } from "@/common/localize-service";
import { isNullOrEmpty } from "@/common/validations";
import { dateString, hourString } from "@/common/date-format";
import { AdvertStatus, HelpSubject, MembershipType, WorkingType } from "@/common/enums";
import { CfAlertError, CfAlertSuccess, CfAlertWarning, CfConfirm } from "@/common/alerts";
import Swal from "sweetalert2";
import { Variables } from "@/common/variables";

class AnswerModel {
    advertId: string;
    userNo: string;
}

export default {
    props: {
        id: String,
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            ad: Object,
            L: localize,
            isNullOrEmpty: isNullOrEmpty,
            dateString: dateString,
            helpSubject: HelpSubject,
            hourString: hourString,
            workingType: WorkingType,
            busy: false,
            advertStatus: AdvertStatus,
            imagePath: Variables.ImagePath,
            night: Date,
        };
    },
    mounted() {
        this.night = new Date(Date.now() - 1000 * 60 * 60 * 24);
        this.night.setHours(0);
        this.night.setMinutes(0);
        this.night.setSeconds(0);
        this.night.setMilliseconds(0);
        this.busy = true;
        service
            .getInstance()
            .GetAdvertDetail("Tevkil/detail", "?id=" + this.$route.params.id)
            .then((response: ApiResponse) => {
                if (response.isSucceed) {
                    this.ad = response.result;
                }
            })
            .catch((error) => {})
            .finally(() => (this.busy = false));
    },
    methods: {
        callUser(userNo, type) {
            if (this.busy) return;
            this.busy = true;
            if (!type) {
                var openAnswerCount = this.ad.answers.filter((c) => c.isOpen).length;
                if (openAnswerCount >= 5) {
                    CfAlertError("En fazla 5 kişinin telefon numarasını görebilirsiniz!");
                    this.busy = false;
                    return false;
                }
            }

            var model = new AnswerModel();
            model.advertId = this.ad.id;
            model.userNo = userNo;

            service
                .getInstance()
                .CallAnswered(model)
                .then((response) => {
                    this.ad = response.result;
                    Swal.fire({
                        icon: "warning",
                        html: localize("Answer.Text.Warning", response.message),
                        confirmButtonText: "Ara",
                        showCancelButton: true,
                        cancelButtonText: "Kapat",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            var tel2 = response.message.replaceAll(" ", "");
                            window.open(`tel:${tel2}`);
                        }
                    });
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));

            // service
            //     .getInstance()
            //     .CallAnswered(model)
            //     .then((response) => {
            //         this.ad = response.result;
            //         service
            //             .getInstance()
            //             .GetApiSettings()
            //             .then((response: ApiResponse) => {
            //                 var tel = response.result.find((x) => x.name === "sms.tel").value;

            //                 Swal.fire({
            //                     icon: "warning",
            //                     html: localize("Answer.Text.Warning.Web", this.user.phoneNumber,tel,tel),
            //                     confirmButtonText: "Ara",
            //                     showCancelButton: true,
            //                     cancelButtonText: "Kapat",
            //                     reverseButtons: true,
            //                 }).then((result) => {
            //                     if (result.isConfirmed) {
            //                         var tel2 = tel.replaceAll(" ", "");
            //                         window.open(`tel:${tel2}`);
            //                     }
            //                 });
            //             });
            //     })
            //     .catch((error) => {
            //         service.getInstance().ErrorMessage(error);
            //     })
            //     .finally(() => (this.busy = false));
        },
        ontapUserOk(userno) {
            if (this.busy) return;
            this.busy = true;

            var data = new AnswerModel();
            data.advertId = this.ad.id;
            data.userNo = userno;
            service
                .getInstance()
                .JobOk(data)
                .then((response) => {
                    this.ad = response.result;
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        deleteAdvert(ad) {
            if (ad.answers == undefined || ad.answers.length == 0) {
                CfConfirm("", localize("advert.doYouDeleteConfirm"), () => {
                    service
                        .getInstance()
                        .AdvertDelete(ad)
                        .then((response) => {
                            CfAlertSuccess(localize("advert.deleteOkMessage"));
                            this.$router.push("/ilanlarim");
                        })
                        .catch((error) => {
                            service.getInstance().ErrorMessage(error);
                        })
                        .finally(() => (this.busy = false));
                });
            }
        },
    },
};
