export class RegisterModel{
    Name : string = "";
    Surname : string = "";
    City : string = "";
    Baro : string = "";
    SicilNo : string = "";
    District : string = "";
    Phone : string = "";
    Email : string = "";
    Website : string = "";
    JobStartedYear : number = 0;
    Adliyeler : string = "";
    Okudum : boolean = false;
    WorkingType : number = 0;
    Purpose : number = 0;
    Ip : string = "";
}