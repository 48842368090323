<template>
    <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">{{ L("CancelMembership.Text.Title") }}</h3>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
            <div class="card-body">
                <div class="form-group row">
                    <label class="col-12 col-form-label">{{ L("CancelMembership.Label.Reason") }}</label>
                    <div class="col-12">
                        <textarea class="form-control form-control-lg form-control-solid" style="height: 150px; resize: none" v-model="model.Reason"></textarea>
                    </div>
                </div>

                <div class="form-group row justify-content-center">
                    <div class="col-6">
                        <button type="button" class="btn btn-success w-100" @click="cancelMembership">{{ L("CancelMembership.Button.Send") }}</button>
                    </div>
                </div>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>

<script lang="ts">
import { User } from "@/entities/User";
import { localize } from "@/common/localize-service";
import { isNullOrEmpty } from "@/common/validations";
import service from "@/common/service";
import { CfAlertError, CfAlertSuccess, CfConfirm } from "@/common/alerts";
import { CancelMembershipModel } from "@/models/CancelMembershipModel";
import { ApiResponse } from "@/models/ApiResponse";
import { MembershipType } from '@/common/enums';
export default {
    props:{
        user : Object,
    },
    emits : ["updateUser"],
    data() {
        return {
            L: localize,
            busy: false,
            model: new CancelMembershipModel(),
        };
    },
    mounted(){
        if(this.user.membershipType == MembershipType['Premium Üyelik']){
            this.$router.push("/ayarlar");
        }
    },
    methods: {
        cancelMembership() {
            if (this.busy) return;
            this.busy = true;

            CfConfirm("Emin misiniz?", "Uyeliginizi sonlandirmak istediginize emin misiniz?", () => {
                service
                    .getInstance()
                    .CancelMembership(this.model)
                    .then((response: ApiResponse) => {
                        if (response.isSucceed) {
                            service.getInstance().Logout();
                        } else {
                            CfAlertError(response.message);
                        }
                    })
                    .catch((error) => {})
                    .finally(() => (this.busy = false));
            });
        },
    },
};
</script>
