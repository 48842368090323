<template>
<transition name="fade-in-up">
    <!--begin::Signin-->
    <div v-if="show" class="login-form py-11">
        <!--begin::Form-->
        <form class="form">
            <!--begin::Title-->
            <div class="text-center pb-8">
                <h2 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">{{ L("ResetPassword.Text.Head") }}</h2>
                <p>{{ L("ResetPassword.Text.Sub") }}</p>
            </div>
            <!--end::Title-->
            <!--begin::Form group-->
            <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">{{ L("ResetPassword.Label.Code") }}</label>
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" v-model="model.Code" @keypress="isCodeValid = true" />
                <div v-if="!isCodeValid" class="fv-help-block text-danger">{{ L("ResetPassword.Error.Code") }}</div>
            </div>
            <!--end::Form group-->

            <!--begin::Form group-->
            <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">{{ L("ResetPassword.Label.Password") }}</label>
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="password" v-model="model.NewPassword" @keypress="isPasswordValid = true" />
                <div v-if="!isPasswordValid" class="fv-help-block text-danger">{{ L("ResetPassword.Error.Password") }}</div>
            </div>
            <!--end::Form group-->

            <!--begin::Form group-->
            <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">{{ L("ResetPassword.Label.Password2") }}</label>
                <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="password" v-model="model.Password2" @keypress="isPassword2Valid = true" />
                <div v-if="!isPassword2Valid" class="fv-help-block text-danger">{{ L("ResetPassword.Error.Password2") }}</div>
            </div>
            <!--end::Form group-->

            <!--begin::Action-->
            <div class="text-center pt-2">
                <button @click="submit" type="button" class="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3">{{ L("ResetPassword.Button.Send") }}</button>
            </div>
            <!--end::Action-->
        </form>
        <!--end::Form-->
    </div>
</transition>
    <!--end::Signin-->
    <div class="loading" v-if="busy"></div>
</template>

<script>
import service from "../common/service";
import { isNullOrEmpty } from "../common/validations";
import { CfAlertError, CfAlertSuccess } from "../common/alerts";
import { localize } from "../common/localize-service";
import { ForgotPasswordModel } from "../models/ForgotPasswordModel";

export default {
    props: {
        model: ForgotPasswordModel,
    },
    data() {
        return {
            isCodeValid: true,
            isPasswordValid: true,
            isPassword2Valid: true,
            busy: false,
            show : false,
        };
    },
    mounted() {
        this.show = true;
    },
    methods: {
        submit() {
            if (this.busy) return;
            this.busy = true;

            if (isNullOrEmpty(this.model.Code)) {
                this.isCodeValid = false;
                this.busy = false;
                return;
            }
            if (isNullOrEmpty(this.model.NewPassword)) {
                this.isPasswordValid = false;
                this.busy = false;
                return;
            }
            if (this.model.NewPassword != this.model.Password2) {
                this.isPassword2Valid = false;
                this.busy = false;
                return;
            }

            service
                .getInstance()
                .NewPassword(this.model)
                .then((response) => {
                    if (response.isSucceed) {
                        CfAlertSuccess("Sifreniz degistirildi");
                        this.$parent.page = "login";
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        L: localize,
    },
};
</script>
