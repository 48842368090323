
import service from "@/common/service";
import { dateString, hourString, onlyHour } from "@/common/date-format";
import { ApiResponse } from "@/models/ApiResponse";
import { SendMessageModel } from "@/models/SendMessageModel";
import { MembershipType } from "@/common/enums";
import { isNullOrEmpty } from "@/common/validations";
import { CfAlertError } from "@/common/alerts";
import { localize } from "@/common/localize-service";
import { Variables } from "@/common/variables";

export default {
    props: {
        user: Object,
    },
    emits: ["updateUser"],
    data() {
        return {
            list: [],
            model: Object,
            today: new Date(Date.now()),
            messages: [],
            yesterday: new Date(Date.now() - 1000 * 60 * 60 * 24),
            dateString: dateString,
            busy: true,
            message: "",
            onlyHour: onlyHour,
            hourString: hourString,
            chatbox: true,
            searchText: "",
            filteredList: [],
            imagePath: Variables.ImagePath,
            L: localize,
            userCheck: Number,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
        this.user.unreadMessage = false;
        this.$emit("updateUser", this.user);

        service
            .getInstance()
            .GetMessagesList("")
            .then((response) => {
                this.list = response.result.sort((a, b) => {
                    return a.createDate - b.createDate;
                });
                this.filteredList = [].concat(this.list);
            })
            .catch((error) => {})
            .finally(() => (this.busy = false));
    },
    methods: {
        messenger(item) {
            if (this.busy) return;
            this.busy = true;
            this.message = "";

            service
                .getInstance()
                .GetMessagesInList("?id=" + item.id)
                .then((response: ApiResponse) => {
                    this.messages = response.result;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.chatbox = false;
                    this.busy = false;
                    setTimeout(() => {
                        this.$refs.myid.scrollTop = this.$refs.myid.scrollHeight;
                    }, 200);
                });
        },
        send() {
            if (this.busy) return;
            this.busy = true;

            if (this.userCheck == 1) {
                CfAlertError(localize("Message.Error.Misafir"));
                this.busy = false;
                return;
            } else if (this.userCheck == 4 || this.userCheck == 5) {
                CfAlertError(localize("Message.Error.Temel"));
                this.busy = false;
                return;
            } else if (this.userCheck == 2) {
                CfAlertError(localize("Message.Error.Hatali"));
                this.busy = false;
                return;
            } else if (this.userCheck == 3) {
                CfAlertError(localize("Message.Error.Iptal"));
                this.busy = false;
                return;
            }
            var messageModel = new SendMessageModel();
            messageModel.chatId = this.messages.id;
            messageModel.message = this.message;

            if (isNullOrEmpty(messageModel.message)) {
                this.busy = false;
                return;
            }

            service
                .getInstance()
                .SendMessage(messageModel)
                .then((response) => {
                    if (response.isSucceed == true) {
                        this.message = "";
                        this.busy = false;
                        this.messenger(this.messages);
                    } else {
                        CfAlertError(response.message);
                    }
                })
                .catch((error) => {
                    service.getInstance().ErrorMessage(error);
                })
                .finally(() => (this.busy = false));
        },
        search() {
            this.filteredList = this.list.filter((c) => c.messages.find((d) => d.message.indexOf(this.searchText) != -1));
        },
    },
};
