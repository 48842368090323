
import { localize } from "@/common/localize-service";
import { Variables } from '@/common/variables';
import service from '@/common/service';

export default {
    props:{
        user : Object,
    },
    emits : ["updateUser"],
    data() {
        return {
            asideOn: false,
            L: localize,
            imagePath : Variables.ImagePath,
            userCheck : Number,
        };
    },
    mounted() {
        this.userCheck = service.getInstance().TypeControl(this.user);
    },
    methods:{
        updateUser(user){
            this.$emit("updateUser",user)
        }
    }
};
