<template>
    <div class="d-flex flex-column flex-root" style="min-height: 100% !important">
        <!--begin::Login-->
        <div class="login login-2 d-flex flex-column flex-lg-row flex-column-fluid bg-white justify-content-center">
            <!--begin::Aside-->
            <div class="login-aside order-2 order-lg-1 d-flex flex-row-auto position-relative overflow-hidden">
                <!--begin: Aside Container-->
                <div class="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-13 px-lg-35 w-100">
                    <!--begin::Logo-->
                    <a href="#" class="text-center pt-2">
                        <img src="../assets/images/login.png" class="max-h-75px" alt="tevkil" />
                    </a>
                    <!--end::Logo-->
                    <!--begin::Aside body-->
                    <div class="d-flex flex-column-fluid flex-column flex-center">
                        <Login v-if="page == 'login'" />
                        <ForgotPassword v-if="page == 'forgotPassword'" :model="forgotPasswordModel" />
                        <ResetPassword v-if="page == 'resetPassword'" :model="forgotPasswordModel" />
                    </div>
                    <!--end::Aside body-->
                </div>
                <!--end: Aside Container-->
            </div>
            <!--begin::Aside-->

            <!--begin::Content-->
            <div class="content order-1 order-lg-2 d-none d-lg-flex flex-column w-100 pb-0 bg-tevkil">
                <!--begin::Title-->
                <div class="d-flex flex-column justify-content-center text-center pt-md-5 pt-sm-5 px-lg-0 pt-5 px-7">
                    <h3 class="my-7 text-light header mx-5 text-center">{{ L("Login.Text.Head") }}</h3>
                    <p class="font-weight-bolder font-size-lg text-light opacity-70 login-sub" style="font-size: 2vh" v-html="L('Login.Text.WebSub')"></p>
                </div>
                <!--end::Title-->
                <!--begin::Image-->
                <div class="content-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" style="background-size: contain" v-bind:style="{ 'background-image': 'url(' + require('@/assets/images/homepagebg.png') + ')' }"></div>
                <!--end::Image-->
            </div>
        </div>
        <!--end::Login-->
    </div>
</template>

<script>
import { localize } from "../common/localize-service";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import { ForgotPasswordModel } from "../models/ForgotPasswordModel";

export default {
    components: {
        Login,
        ForgotPassword,
        ResetPassword,
    },
    data() {
        return {
            page: "login",
            forgotPasswordModel: new ForgotPasswordModel(),
            L: localize,
        };
    },
    mounted() {
        this.forgotPasswordModel = new ForgotPasswordModel();
    },
    methods: {},
};
</script>

<style>
@import "../assets/css/pages/login/login-2.css";

.hide {
    display: none;
}
</style>
