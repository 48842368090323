<template>
    <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">{{L('CityChange.Text.Title')}}</h3>
            </div>
            <div class="card-toolbar">
                <button type="button" class="btn btn-success mr-2" @click="changeCity">{{L('CityChange.Button.Save')}}</button>
            </div>
        </div>
        <!--end::Header-->
        
        <!--begin::Form-->
        <form class="form">
            <div class="card-body">
                <label class="text-left d-block mb-5 text-danger">{{L('CityChange.Text.Warning')}}</label>

                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('CityChange.Label.OldCity')}}</label>
                    <div class="col-lg-9 col-xl-9">
                        <span class="form-control form-control-lg text-left">{{user.city}}</span>
                    </div>
                </div>

                 <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('CityChange.Label.OldArea')}}</label>
                    <div class="col-lg-9 col-xl-9">
                        <span class="form-control form-control-lg text-left">{{user.district}}</span>
                    </div>
                </div>
              
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('CityChange.Label.NewCity')}}</label>
                    <div class="col-lg-9 col-xl-9">
                        <select class="form-control form-control-lg form-control-solid text-left" @change="getAreas" v-model="model.City">
                            <option v-for="city in cities" :key="city.name" :value="city.name">{{city.name}}</option>
                        </select>
                    </div>
                </div>

                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-left dot">{{L('CityChange.Label.NewArea')}}</label>
                    <div class="col-lg-9 col-xl-9">
                        <select class="form-control form-control-lg form-control-solid text-left" v-model="model.District">
                            <option v-for="area in areas" :key="area.name" :value="area.name">{{area.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>

<script lang="ts">
import { User } from '@/entities/User';
import service from '@/common/service';
import { localize } from '@/common/localize-service';
import { ChangeCityModel } from '@/models/ChangeCityModel';
import { ApiResponse } from '@/models/ApiResponse';
import { CfAlertError, CfAlertSuccess } from '@/common/alerts';
import { isNullOrEmpty } from '@/common/validations';
import { MembershipType } from '@/common/enums';
import { localeSort} from "@/common/locale-sort";

export default{
    props:{
        user : Object,
    },
    emits : ["updateUser"],
    data(){
        return{
            cities : [],
            areas : [],
            test : String,
            L : localize,
            busy : false,
            model : new ChangeCityModel(),
        }
    },
    mounted(){
        
        service.getInstance().GetCities()
        .then((response) =>{
            this.cities = response.result.sort(localeSort);
        })
    },
    methods:{
        getAreas(){
            this.model.District = "";
            service.getInstance().GetAreas("?cityName=" + this.model.City)
            .then((response)=>{
                this.areas = response.result;
            })
        },
        changeCity(){
            if(this.busy) return;
            this.busy = true;

            if(isNullOrEmpty(this.model.City) || isNullOrEmpty(this.model.District)){
                this.busy = false;
                return;
            }

            if(!isNullOrEmpty(this.user.changedCity) && this.model.City != this.user.city){
                CfAlertError(localize("CityChange.Error.ChangedBefore"),localize("CityChange.Error.ChangedBefore.Title"),localize("CityChange.Error.ChangedBefore.Button"));
                this.busy = false;
                return;
            }

            if(this.model.City == this.user.city && this.model.District == this.user.district){
                CfAlertError(localize("CityChange.Error.NoChange"),localize("CityChange.Error.NoChange.Title"),localize("CityChange.Error.NoChange.Button"))
                this.busy = false;
                return;
            }

            service.getInstance().ChangeCity(this.model)
            .then((response : ApiResponse) =>{
                if(response.isSucceed){
                    this.$emit("updateUser",response.result);
                    this.model = new ChangeCityModel();
                     if(navigator.cookieEnabled){
                            localStorage.setItem("user",JSON.stringify(response.result));
                        }else{
                            globalThis.user = response.result;
                        }
                    CfAlertSuccess(response.message);
                }else{
                    CfAlertError(response.message);
                }
            })
            .catch(error => {
                service.getInstance().ErrorMessage(error);
            })
            .finally(()=> this.busy = false);
        }
    }
}
</script>