export class LoginModel{
    Email : string;
    Password : string;
    Token : string;
    Ip : string;
    Platform : string;

    constructor(Email:string, Password:string, Token:string) { 
        this.Email = Email;
        this.Password = Password
        this.Token = Token
     }
}